import React, { memo } from 'react';

const ChevronIcon = () => {
  return (
    <svg width="10" height="28" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0.5L5 4.5L9 0.5" stroke="currentColor" />
    </svg>
  );
};

export default memo(ChevronIcon);
