import { Unit } from "./eventBus";
import { fromFetch } from 'rxjs/fetch';
import { map, switchMap } from "rxjs/operators";

export function FetchTransformation(unit: Unit, params) {

    return fromFetch(unit.data).pipe(
        switchMap(i => i.json()),
        map(data => ({ data }))
    )
}