import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import ControlGroupSwitch from './shared/controls/ControlGroupSwitch';
import ControlButton from './shared/controls/ControlButton';
import { When } from 'react-if';
import CogIcon from './shared/icons/CogIcon';
import NoteblLogoIcon from './shared/icons/NoteblLogoIcon';
import { Link, useHistory } from 'react-router-dom';
// import _ from 'lodash';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    boxSizing: 'border-box',
    padding: '9px 10px',
    height: '40px',
    width: '100%',
    background: 'white',
    position: 'relative',
    transition: 'all 0.3s ease-out',

    '&:before': {
      content: '""',
      width: '100%',
      position: 'absolute',
      borderTop: '1px solid rgba(0, 0, 0, 0.05)',
      bottom: 0,
      right: 0,
    },
  },
  actionsGroup: {
    display: 'flex',
    flexFlow: 'row nowrap',
    minHeight: 22,
    '&>*': {
      marginRight: 10,
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  zoomHistory: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '& + div': {
      marginLeft: 10,
    },
  },
  modeSwitch: {
    marginTop: -9,
    marginBottom: -9,
    '& > label > span': {
      height: 18,
    },
  },
});

export interface NotebookActionsProps {
  defaultMode: string;
  onModeChange: (event: any) => void;
  editable: boolean;
  embedded: boolean;
  authenticated: boolean;
  className?: string;
  saveChange?: () => void;
  showSidePanel?: boolean;
  setShowSidePanel?: (isShown: boolean) => void;
}

const NotebookActions = (props: NotebookActionsProps) => {
  const {
    defaultMode,
    onModeChange,
    editable,
    embedded,
    authenticated,
    className,
    saveChange,
    showSidePanel,
    setShowSidePanel,
  } = props;

  const modeOptions = editable
    ? [
        { label: 'Edit', value: 'outline' },
        { label: 'Read', value: 'read' },
        { label: 'Present', value: 'slides' },
      ]
    : embedded
    ? []
    : [
        { label: 'Outline', value: 'outline' },
        { label: 'Read', value: 'read' },
        { label: 'Present', value: 'slides' },
      ];

  const modeMoreOptions = editable
    ? [
        { label: 'Edit with Read Preview', value: 'outline + read' },
        { label: 'Edit with Slides Preview', value: 'outline + slides' },
      ]
    : embedded
    ? [
        { label: 'Outline', value: 'outline' },
        { label: 'Read', value: 'read' },
        { label: 'Present', value: 'slides' },
      ]
    : [];

  const history = useHistory();
  const redirectToLogin = () => {
    history.push('/login');
  };

  const styles = useStyles();
  return (
    <div className={classNames(styles.root, className, 'notebook-actions')}>
      <div className={styles.actionsGroup}>
        <Link to="/notebooks">
          <ControlButton
            icon={<NoteblLogoIcon colorScheme="brand" size="navSmall" />}
            onClick={setShowSidePanel && setShowSidePanel.bind(null, true)}
          />
        </Link>
        <ControlGroupSwitch
          name="mode"
          options={modeOptions}
          moreOptions={modeMoreOptions}
          defaultValue={defaultMode}
          onChange={onModeChange}
          displayStyle="blocked"
          moreOptionsWidth={180}
        />
      </div>
      {editable && saveChange && (
        <div className={styles.actionsGroup}>
          <When condition={!showSidePanel}>
            <ControlButton icon={<CogIcon />} onClick={setShowSidePanel.bind(null, true)} />
          </When>
          <ControlButton label="Save" onClick={saveChange} importance="primary" />
        </div>
      )}
      {!authenticated && <ControlButton label="Login" onClick={redirectToLogin} importance="primary" />}
    </div>
  );
};

export default memo(NotebookActions);
