import Note from "../../core/Note";
import { ActionType } from "./actionType";
import { ShareableLink } from "../../services/ShareableLink";
import { NotebookState } from "./state";
import { NotebookAction } from "./actions";
import * as firebase from "firebase";
import _ from "lodash";
import { Invitation } from "../../services/Invitation";

export const removeInvitationAction = (note: Note, shareableLink: ShareableLink, invitation: Invitation) => {
    return async (dispatch, getState) => {

        const { notebook } = getState() as { notebook: NotebookState };

        const invitations = _(shareableLink.invitations)
          .without(invitation)
          .value();

        shareableLink.invitations = invitations;

        const firestore = firebase.firestore();
        const notebookList = firestore.collection('notebookList');

        const sharedNotebooks = await notebookList
            .where("notebookId", "==", notebook.notebookId)
            .where("sharedTo.email", "==", invitation.email)
            .get()

        sharedNotebooks.docs.forEach(async i =>
            await notebookList.doc(i.id).delete()
        )

        const copy: any = {...notebook.notebook};
        const readyAction: Partial<NotebookAction> = {
            type: ActionType.UPDATE_NOTEBOOK,
            notebook: copy,
        };

        dispatch(readyAction);
    };
  };