import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import ChevronIcon from './shared/icons/ChevronIcon';
import { theme } from '../shared/theme';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
  arrow: {
    color: '#444444',
    minWidth: 20,
    minHeight: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.15s linear',
    transform: 'rotate(0deg)',
    '&:hover': {
      color: '#000000',
    },
  },
  closed: {
    color: '#000000',
    transform: 'rotate(-90deg)',
  },
  spacer: {
    ...theme.spacer,
  },
});

export interface CollapserProps {
  onClick: (ev: React.MouseEvent) => void;
  hidden?: boolean;
  closed?: boolean;
  className?: string;
}

const Collapser = (props: CollapserProps) => {
  const { className, onClick, closed, hidden } = props;

  const styles = useStyles();

  return (
    <div className={classNames(styles.root, className)}>
      {!hidden && (
        <div className={classNames(styles.arrow, closed && styles.closed)} onClick={onClick}>
          <ChevronIcon />
        </div>
      )}
      {hidden && <div className={styles.spacer} />}
    </div>
  );
};

export default memo(Collapser);
