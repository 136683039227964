import React, { memo } from 'react';

const TriangleIcon = () => {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 6L0.535899 -1.75695e-07L7.4641 4.29987e-07L4 6Z" fill="currentColor" />
    </svg>
  );
};

export default memo(TriangleIcon);
