import Note from '../../core/Note';
import shortid from 'shortid';
import { ActionType } from './actionType';
import { ShareableLink } from '../../services/ShareableLink';
import { NotebookState } from './state';
import { NotebookAction } from './actions';
import * as firebase from 'firebase';

export const addShareableLinkAction = (note: Note) => {
  return async (dispatch, getState) => {
    const linkId = shortid.generate();
    const { notebook } = getState() as { notebook: NotebookState };

    const shareableLink: ShareableLink = {
      id: linkId,
      notebookId: notebook.notebookId,
      noteId: note.id,
      archived: false,
      private: false,
      invitations: [],
    };
    note.shareableLinks = [shareableLink, ...note.shareableLinks];

    const firestore = firebase.firestore();

    await firestore.collection('ShareableLinks').doc(linkId).set(shareableLink);

    const copy: any = { ...notebook.notebook };
    const readyAction: Partial<NotebookAction> = {
      type: ActionType.UPDATE_NOTEBOOK,
      notebook: copy,
    };

    dispatch(readyAction);
  };
};
