import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { If, Then, Else } from 'react-if';
// import _ from 'lodash';

import Bullet from './Bullet';
import Collapser from './Collapser';
import { theme } from '../shared/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
  },
  spacer: {
    ...theme.spacer,
  },
});

export interface NoteControlsProps {
  onCollapseClick?: (event: React.MouseEvent) => void;
  onMenuClick?: (event: any) => void;
  closed?: boolean;
  collapsible?: boolean;
  hideBullet?: boolean;
  hideMenu?: boolean;
  className?: string;
  onBulletClick?: (event: any) => void;
}

const NoteControls = ({
  className,
  onCollapseClick,
  closed,
  collapsible,
  hideBullet,
  hideMenu,
  onBulletClick,
}: NoteControlsProps) => {
  const styles = useStyles();
  return (
    <div className={classNames(styles.root, className, 'note-actions')}>
      <Collapser onClick={onCollapseClick} hidden={!collapsible} closed={closed} />
      <If condition={!hideBullet}>
        <Then>
          <Bullet onClick={onBulletClick} />
        </Then>
        <Else>
          <div className={classNames(styles.spacer, 'note-actions-spacer')}></div>
        </Else>
      </If>
    </div>
  );
};

export default memo(NoteControls);
