import Note from '../../core/Note';
import { ActionType } from './actionType';
import { ShareableLink } from '../../services/ShareableLink';
import { NotebookState } from './state';
import { NotebookAction } from './actions';
import * as firebase from 'firebase';
import { toast } from 'react-toastify';
import { Invitation } from '../../services/Invitation';
import { NoteSummary } from '../../services/NoteSummary';
import * as NoteActions from '../../core/NoteActions';

export const sendInvitationAction = (note: Note, shareableLink: ShareableLink, invitation: Invitation) => {
  return async (dispatch, getState) => {
    const { notebook } = getState() as { notebook: NotebookState };
    const sendInvitation = firebase.functions().httpsCallable('sendInvitation');

    const noteSummary: NoteSummary = NoteActions.summarize(note) as NoteSummary;

    const result = await sendInvitation({
      notebookId: notebook.notebookId,
      noteId: note.id,
      shareableLink,
      invitation,
      noteSummary,
    });

    if (result.data === 'ok') {
      shareableLink.invitations = [invitation, ...shareableLink.invitations];

      toast.success('Invitation sent');

      const copy: any = { ...notebook.notebook };
      const readyAction: Partial<NotebookAction> = {
        type: ActionType.UPDATE_NOTEBOOK,
        notebook: copy,
      };

      dispatch(readyAction);
    } else {
      toast.error(result.data.details);
    }
  };
};
