import firebase from 'firebase';
import shortid from 'shortid';
import { toast } from 'react-toastify';
import { history } from '../../history';
import { NotebookList } from '../../services/NotebookList';

export const createNewNotebookAction = () => {
  return async (dispatch) => {
    const notebookId = shortid.generate();
    const noteId = shortid.generate();

    const newNotebook: NotebookData = {
      notebookId: notebookId,
      rootId: noteId,
      mode: 'outline',
      notes: [
        {
          id: noteId,
          source: 'Welcome to Notebl',
        },
      ],
    };

    const newNotebookList: NotebookList = {
      notebookId: notebookId,
      noteId: noteId,
      lastUpdated: firebase.firestore.Timestamp.now(),
      name: 'Welcome to Notebl',
      summary: '',
      sharedWith: [],
      owner: firebase.auth().currentUser.email,
      sharedTo: null,
    };

    const firestore = firebase.firestore();

    await firestore.collection('notebookList').doc(notebookId).set(newNotebookList);

    await firestore.collection('notebooks').doc(notebookId).set(newNotebook);

    toast.success('New Notebook Created!');

    history.push(`/notebook/${notebookId}`);
  };
};
