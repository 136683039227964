// import React from 'react';
import Note from '../core/Note';
import React from 'react';
import { LayoutRenderFactory2 } from '../layoutRenderers/LayoutRenderFactory2';

export interface NoteViewReadProps {
  note: Note;
  topNote: Note;
  className?: string;
  collapseNote: (note: Note) => void;
  zoomNote: (note: Note) => void;
}

const NoteViewRead2 = ({ note, topNote, className, collapseNote, zoomNote }: NoteViewReadProps) => {
  return (
    <LayoutRenderFactory2
      mode="read"
      note={note}
      topNote={topNote}
      collapseNote={collapseNote}
      zoomNote={zoomNote}
      className={className}
    />
  );
};

export default NoteViewRead2;
