import { NotebookListState, NotebookListAction, ActionType } from "./state";

export function reducer(state = new NotebookListState(), action: NotebookListAction): NotebookListState {

    switch (action.type) {

        case ActionType.UPDATE_NOTEBOOK:

            return {
                ...state,
                ...action,
            };
        default:
            return state;
    }
}
