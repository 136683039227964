import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  LineChart,
  Line,
  ReferenceLine,
  LabelProps,
} from 'recharts';

import helpers from '../transformations/shared/helpers';
import { getRows, getDataFromParams, doesntExistOrNotEqual, existAndEqual, isDataValid, isParams } from '../transformations/shared/utils';
import { FormaterProps } from './FormatterFactory2';

const DEFAULT_COLOR = '#568EC7';
const DEFAULT_HORIZONTAL_BARCHART_HEIGHT = 200;

const BarchartVerticalFormatter = (props: FormaterProps) => {

  const params = props.note.formatter.params;

  // https://lodash.com/docs/4.17.15#get
  // path is "The path of the property to get"
  const path = params.path;
  const dataSource = params.data ?? props.unit.data;
  const data = path ? _.get(dataSource, path) : dataSource;    
 
  // console.log('BarchartHorizontalVertical', props, data);

  const { valid: dataValid, message: dataErrorMessage } = isDataValid(data);
  if (!dataValid) {
    return <div className="table-container error">{dataErrorMessage}</div>
  };

  const { valid: paramsValid, message: paramsErrorMessage } = isParams(params);
  if (!paramsValid) {
    return <div className="error">{paramsErrorMessage}</div>
  };

  const _series0 = Array.isArray(params.series) && params.series.length > 0 ? params.series[0] : {};
  const _category = _series0.dataFields.category;
  const _value = _series0.dataFields.value;
  const _collectedRows = getRows(data, _category, _value);

  const color = _.isHexColor(params.color) ? params.color : DEFAULT_COLOR;

  const minHeight =
    _.isNumber(params.minHeight)
      ? params.minHeight
      : DEFAULT_HORIZONTAL_BARCHART_HEIGHT;
  const barSize = _.isNumber(params.barSize) ? params.barSize : undefined;

  const _maxValue = _.maxBy(_collectedRows, (x) => x[_value])[_value];
  const axisTickFormat = _maxValue < 10 ? '0.00' : _maxValue < 10000 ? '0,0' : '0a';
 
  return (
    <div className="chart rechart bar-chart bar-chart-vertical">
      {!!params.title && <h2>{params.title}</h2>}
      <ResponsiveContainer width="100%" minHeight={minHeight} debounce={1}>
        <BarChart
          height={150}
          data={_collectedRows}
          layout="horizontal"
          margin={{ top: 15, right: 10, bottom: 5, left: 0 }}
        >
          {doesntExistOrNotEqual(params.showGrid, 'no') && <CartesianGrid vertical={false} strokeDasharray="3 3" />}
          {doesntExistOrNotEqual(params.showGrid, 'no') && (
            <YAxis
              type="number"
              axisLine={false}
              orientation="right"
              tickSize={0}
              tickFormatter={(value) => helpers.formatNumber(value, axisTickFormat)}
            />
          )}
          {doesntExistOrNotEqual(params.showGrid, 'no') && <XAxis type="category" dataKey={_category} />}
          {existAndEqual(params.showGrid, 'no') && (
            <XAxis type="category" axisLine={false} tickLine={false} dataKey={_category} />
          )}
          <Tooltip formatter={(value) => helpers.formatNumber(value, params.valueFormat)} />
          <Bar dataKey={_value} fill={color} barSize={barSize}>
            <LabelList
              dataKey={_value}
              formatter={(value) => helpers.formatNumber(value, params.valueFormat)}
              position="top"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
};

export default BarchartVerticalFormatter;
