import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams, useRouteMatch } from 'react-router-dom';

import NotebookView from '../components/NotebookView';
import notebookObj_a123 from '../api/notebook/a123.js';
import notebookObj_b234 from '../api/notebook/b234.js';
import Notebook from '../core/Notebook';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    '& >.panel': {
      flex: '1 1 auto',
    },
  },
});

interface ComparePageProps {}

const ComparePage = (props: ComparePageProps) => {
  let { id1, id2 } = useParams();
  let { url } = useRouteMatch();

  const notebookObj1 = id1 === 'a123' && (notebookObj_a123 as NotebookData);
  const notebookObj2 = id2 === 'b234' && (notebookObj_b234 as NotebookData);

  const notebook1 = new Notebook(notebookObj1);
  const notebook2 = new Notebook(notebookObj2);

  const styles = useStyles();

  return (
    <div className={styles.root}>
      {notebookObj1 && (
        <div className="notebook-container">
          <NotebookView notebook={notebook1} page={url} renderFromNote={notebook1.root} authenticated={true} />
        </div>
      )}
      {notebookObj2 && (
        <div className="notebook-container">
          <NotebookView notebook={notebook2} page={url} renderFromNote={notebook2.root} authenticated={true} />
        </div>
      )}
    </div>
  );
};

export default ComparePage;
