import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import _ from 'lodash';
import { Unit } from './eventBus';
import { ParseCSVTransformation } from './ParseCSVTransformation';
import { FetchTransformation } from './FetchTransformation';
import { CalculateTableTotalByColumn } from './CalculateTableTotalByColumn';

type TrasformationFunction = (unit: Unit, params) => Observable<Unit> | Promise<Unit> | Unit;

export const Transformations: { [key: string]: TrasformationFunction } = {
  'Increase': IncreaseTransformation,
  'IncreaseInSecond': IncreaseInSecondTransformation,
  'parseCSV': ParseCSVTransformation,
  'parseJSON': ParseJSONTransformation,
  'filterListTop': FilterListTopTransformation,
  'Identity': IdentityTransformation,
  'fetch': FetchTransformation,
  'calculateTableTotalByColumn': CalculateTableTotalByColumn,

  // temporary placeholder for a transformation to be written
  'findListRow': (unit: Unit, params) => WarnAboutTransformation('findListRow', unit, params),
  'splitColumn': (unit: Unit, params) => WarnAboutTransformation('splitColumn', unit, params),

  // temporary mapping legacy transformations that need to be converted to formatters, or layouts
  'image': (unit: Unit, params) => WarnAboutTransformation('image', unit, params),
  'listToTable': (unit: Unit, params) => WarnAboutTransformation('listToTable', unit, params),
  'setStyle': (unit: Unit, params) => WarnAboutTransformation('setStyle', unit, params),
  'title': (unit: Unit, params) => WarnAboutTransformation('title', unit, params),
  'titleSlide': (unit: Unit, params) => WarnAboutTransformation('titleSlide', unit, params),
  'finalSlide': (unit: Unit, params) => WarnAboutTransformation('finalSlide', unit, params),
  'rechartLinechart': (unit: Unit, params) => WarnAboutTransformation('rechartLinechart', unit, params),
  'rechartBarchartHorizontal': (unit: Unit, params) =>
    WarnAboutTransformation('rechartBarchartHorizontal', unit, params),
  'rechartBarchartVertical': (unit: Unit, params) => WarnAboutTransformation('rechartBarchartVertical', unit, params),
  'helloWorld': (unit: Unit, params) => WarnAboutTransformation('image', unit, params),
};

function IdentityTransformation(unit: Unit) {
  return { data: unit.data };
}

function WarnAboutTransformation(name: string, unit: Unit, params) {
  console.warn('Missing or legacy transformation: ', name);
  return IdentityTransformation(unit);
}

function IncreaseInSecondTransformation(unit: Unit, params) {
  const data = unit.data + params.value;
  return of({ data }).pipe(delay(1000));
}

function IncreaseTransformation(unit: Unit, params) {
  const data = unit.data + params.value;
  return { data };
}

function FilterListTopTransformation(unit: Unit, params) {
  const data = _(unit.data)
    .orderBy([params.column], ['desc'])
    .filter((value, index) => index < params.count)
    .value();
  return { data };
}

function ParseJSONTransformation(unit: Unit) {
  try {
    const data = JSON.parse(unit.data);
    return Promise.resolve({ data, error: null });
  } catch (e) {
    const error = 'Unable to parse JSON: ' + e;
    return { data: null, error };
  }
}
