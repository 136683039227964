import Notebook from '../../core/Notebook';
import Note from '../../core/Note';
import { NotebookList } from '../../services/NotebookList';
export class NotebookState {
  notebookId: string;
  noteId?: string;
  notebook: Notebook;
  notebookList: NotebookList;
  renderFromNote: Note;
  loading = true;
  archived: boolean;
  noteToCopy: Note;
}