import _ from 'lodash';
import Note from './Note';

export default class Notebook {
  id: NotebookId;
  root: Note;
  mode: NotebookViewMode;

  private _notes: Note[];

  constructor(data: NotebookData) {
    this.id = data.notebookId;
    this.mode = data.mode;
    this._notes = _.map(data.notes, (noteData: NoteData) => {
      return new Note(noteData);
    });

    this.root = _.find(this._notes, (note) => note.id === data.rootId);

    // link
    _.each(this._notes, (note) => {
      note.link(this._notes, this.root);
    });

    // set parents (recursively)
    this.root.setParent(null);

    // compute (recursively)
    this.root.compute();
  }
}