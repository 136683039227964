import _ from 'lodash';
import Notebook from './Notebook';
import NoteActions from './NoteActions';

export function getNoteById(notebook: Notebook, id: NoteId) {

    return notebook.root.getDescendentById(id);
}

export function serialize(notebook: Notebook): NotebookData {
   
    const notes = NoteActions.traverseDown(notebook.root, note => note.serialize());

    return {
        notebookId: notebook.id,
        mode: notebook.mode,
        rootId: notebook.root.id,
        notes
    };
}