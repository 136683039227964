import ControlButton from './shared/controls/ControlButton';
import DeleteIcon from './shared/icons/DeleteIcon';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Collapsible } from './Collapsible';
import { makeStyles } from '@material-ui/styles';
import { CheckBox } from './shared/controls/CheckBox';
import { theme } from '../shared/theme';

const useStyles = makeStyles({
  root: {
    // borderBottom: `1px solid ${theme.colors.noteSettingsDivider}`,
    // padding: '10px',
  },
  transformationTitle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    padding: '0 0px',
    flex: '1 1',
    ...theme.typography.content.body,
  },
  transformationTitleName: {
    flex: '1 1',
    padding: '10px 0',
    cursor: 'pointer',
  },
});

export interface TransformationState {
  id: string;
  name: string;
  params: any;
  recursive: boolean;
  disabled: boolean;
  open?: boolean;
  description?: () => string;
}

interface TransformationProps {
  transformation: TransformationState;
  sharedStyles;
  handleDelete: () => void;
  handleRecursiveChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDisabling?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleParamsChange?: (params: string) => void;
  collapseAll?: boolean;
  resetCollapseAll?: () => void;
}

const Transformation = (props: TransformationProps) => {
  const {
    transformation,
    sharedStyles,
    handleDelete,
    handleRecursiveChange,
    handleDisabling,
    handleParamsChange,
    collapseAll,
    resetCollapseAll,
  } = props;

  const [closed, setClosed] = useState(!transformation.open);
  const [transformationParams, setTransformationParams] = useState(JSON.stringify(transformation.params));

  const handleExpandCollapse = useCallback(() => {
    setClosed(!closed);
  }, [closed]);

  const handleTransformationParamsChange = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTransformationParams(ev.target.value);
      handleParamsChange(ev.target.value);
    },
    [handleParamsChange]
  );

  useEffect(() => {
    if (!transformation.open && collapseAll && transformation.name) {
      setClosed(true);
      resetCollapseAll();
    }
  }, [collapseAll, resetCollapseAll, transformation.name, transformation.open]);

  const styles = useStyles();

  return (
    <Collapsible
      className={styles.root}
      title={
        closed ? (
          <div className={styles.transformationTitle}>
            <span onClick={handleExpandCollapse} className={styles.transformationTitleName}>
              {transformation.name}
            </span>
          </div>
        ) : (
          transformation.name && (
            <div className={styles.transformationTitle}>
              <span onClick={handleExpandCollapse} className={styles.transformationTitleName}>
                {transformation.name}
              </span>
              <ControlButton label="Remove" onClick={handleDelete} />
            </div>
          )
        )
      }
      collapsed={closed}
      onExpandCollapse={handleExpandCollapse}
    >
      <div className={sharedStyles.settingsGroupRow}>
        {transformation.description && <div>{transformation.description()}</div>}
        {handleRecursiveChange && (
          <div className={sharedStyles.settingsGroupRowProperty}>
            <CheckBox
              handleChange={handleRecursiveChange}
              isChecked={transformation.recursive || false}
              label="Applies to all children"
            />
          </div>
        )}
        {handleDisabling && (
          <div className={sharedStyles.settingsGroupRowProperty}>
            <CheckBox handleChange={handleDisabling} isChecked={transformation.disabled || false} label="Disabled" />
          </div>
        )}
        {handleParamsChange && (
          <div className={sharedStyles.settingsGroupRowProperty}>
            <label>Params</label>
            <textarea
              className={sharedStyles.controlInputText}
              onChange={handleTransformationParamsChange}
              value={transformationParams}
              rows={8}
            />
          </div>
        )}
      </div>
    </Collapsible>
  );
};
export default memo(Transformation);
