import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  root: {},
});

export interface TagsIconProps {
  className?: string;
}

const TagsIcon = ({ className }: TagsIconProps) => {
  const styles = useStyles();
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.root, className)}
    >
      <path
        d="M9.036 4.554L8.604 8.046H11.106V9.36H8.442L8.046 12.6H6.768L7.164 9.36H3.762L3.366 12.6H2.088L2.484 9.36H0V8.046H2.646L3.096 4.554H0.576V3.24H3.258L3.654 0H4.932L4.536 3.24H7.92L8.316 0H9.594L9.198 3.24H11.682V4.554H9.036ZM7.758 4.554H4.374L3.924 8.046H7.326L7.758 4.554Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(TagsIcon);
