import React from 'react';
import Collapser from './Collapser';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { theme } from '../shared/theme';

const useStyles = makeStyles({
  root: {
    // borderLeft: '1px solid red',
  },
  collapsableTitle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    minHeight: 38,
  },
  collapsable: {
    borderLeft: `1px solid ${theme.colors.collapserBorder}`,
    paddingLeft: 7,
    marginLeft: 10,
  },
  collapser: {
    marginRight: 3,
  },
});

interface CollapsibleProps {
  onExpandCollapse: () => void;
  title: string | React.ReactElement;
  isDisabled?: boolean;
  collapsed?: boolean;
  className?: string;
}
export const Collapsible = (props: { children } & CollapsibleProps) => {
  const { title, onExpandCollapse, isDisabled, collapsed, className } = props;

  const styles = useStyles();

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.collapsableTitle}>
        <Collapser closed={collapsed} onClick={onExpandCollapse} hidden={isDisabled} className={styles.collapser} />
        {title}
      </div>
      {!collapsed && !isDisabled && <div className={styles.collapsable}>{props.children}</div>}
    </div>
  );
};
