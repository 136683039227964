import { ActionType } from "./actionType";
import { Actions } from "./actions";
import { ApplicationState } from "./state";

export function reducer(state = new ApplicationState(), action: Actions): ApplicationState {

    switch (action.type) {

        case ActionType.UPDATE_AUTH:

            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
}