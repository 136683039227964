import sanitize from 'sanitize-html';

export default class Utils {
	private static namedHtmlEntities = [
		{ char: '&', entity: '&amp;' },
		{ char: '>', entity: '&gt;' },
		{ char: '<', entity: '&lt;' },
	];

	static encodeHtmlEntity(input: string) {
		for (let v in Utils.namedHtmlEntities) {
			input = input.replace(new RegExp(Utils.namedHtmlEntities[v].char, 'g'), Utils.namedHtmlEntities[v].entity);
		}

		// additionaly handle line breaks
		return input.replace(new RegExp('\n', 'g'), '<br />');
	};

	static decodeHtmlEntity(input: string) {
		for (let v in Utils.namedHtmlEntities) {
			input = input.replace(new RegExp(Utils.namedHtmlEntities[v].entity, 'g'), Utils.namedHtmlEntities[v].char);
		}

		// additionally handle line breaks
		return input.replace(new RegExp('</div><div>|<br />', 'g'), '\n');
	};

	//see https://www.npmjs.com/package/sanitize-html for sanitize options
	static sanitize(html: string, sanitizeOptions?) {
		return sanitize(Utils.decodeHtmlEntity(html), sanitizeOptions);
	}
}
