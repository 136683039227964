import React, { memo, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import NoteblLogoIcon from './shared/icons/NoteblLogoIcon';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
  },
});

export interface NoteblLogoProps {
  onClick?: (event: any) => void;
  className?: string;
  size?: 'navSmall' | 'navBig' | 'applicationDefault';
  colorScheme?: 'brand' | 'brandDarkBg' | 'uniformLight' | 'uniformDark';
}

const NoteblLogo = ({ className, onClick, size, colorScheme }: NoteblLogoProps) => {
  const styles = useStyles();
  const [animated, setAnimated] = useState(false);
  const handleMouseOver = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnimated(true);
    },
    [setAnimated]
  );
  const handleMouseOut = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnimated(false);
    },
    [setAnimated]
  );

  return (
    <div
      className={classNames(styles.root, 'notebl-logo')}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <NoteblLogoIcon
        size={size ? size : 'navSmall'}
        colorScheme={colorScheme ? colorScheme : 'brandDarkBg'}
        animate={animated}
      />
    </div>
  );
};

export default memo(NoteblLogo);
