import NoteActions from '../core/NoteActions';
import Utils from '../utils/Utils';
import { toStringify } from 'lodash';
import Note from '../core/Note';
import {EventBus} from "../transformations2/eventBus";

export type CommandParams = {
  action: CommandActionType;
  note: Note;
  offset: number;
  specifics?: any
};

export enum CommandActionType {
  'CTRL_ARROW_DOWN' = 'CtrlArrowDown',
  'CTRL_ARROW_UP' = 'CtrlArrowUp',
  'ALT_ARROW_LEFT' = 'AltArrowLeft',
  'ALT_ARROW_RIGHT' = 'AltArrowRight',
  'ARROW_DOWN' = 'ArrowDown',
  'ARROW_UP' = 'ArrowUp',
  'ARROW_LEFT' = 'ArrowLeft',
  'ARROW_RIGHT' = 'ArrowRight',
  'DELETE' = 'Delete',
  'OUTDENT' = 'Outdent',
  'INDENT' = 'Indent',
  'ENTER' = 'Enter',
  'BACKSPACE' = 'Backspace',
  'CLONE' = 'Clone',
  'PASTE' = 'Paste',
  'REMOVE' = 'Remove',
  'NAME' = 'Name',
}

export function onCommand(params: CommandParams, eventBus: EventBus): { note: Note; offset: number } {
  const { note, offset, action } = params;
  const map = {
    CtrlArrowDown: () => {
      NoteActions.move(note, 'down');
      return { note, offset };
    },
    CtrlArrowUp: () => {
      NoteActions.move(note, 'up');
      return { note, offset };
    },
    AltArrowLeft: () => {
      NoteActions.toggleCollapse(note);
      return { note, offset };
    },
    AltArrowRight: () => {
      NoteActions.toggleCollapse(note);
      return { note, offset };
    },
    ArrowDown: () => {
      const nextNote = NoteActions.getNextNote(note);
      return nextNote && { note: nextNote, offset };
    },
    ArrowUp: () => {
      const nextNote = NoteActions.getPrevNote(note);
      return nextNote && { note: nextNote, offset };
    },
    ArrowLeft: () => {
      const nextNote = NoteActions.getPrevNote(note);
      return nextNote && { note: nextNote, offset: toStringify(nextNote.source).length };
    },
    ArrowRight: () => {
      const nextNote = NoteActions.getNextNote(note);
      return nextNote && { note: nextNote, offset: 0 };
    },
    Delete: () => {
      NoteActions.mergeNext(note);
      eventBus.pushValue({ id: note.id, source: note.source });
      return { note, offset };
    },
    Outdent: () => {
      NoteActions.shiftTab(note);
      return { note, offset };
    },
    Indent: () => {
      NoteActions.tab(note);
      return { note, offset };
    },
    Enter: () => {
      const source1 = note.source.toString().substring(0, offset);
      const source2 = note.source.toString().substring(offset);
      note.source = source1;

      const sanitizeOptions = {
        allowedTags: ['b', 'i', 'em', 'strong', 'a'],
        allowedAttributes: {
          a: ['href', 'title'],
        },
      };
      const newNote = NoteActions.createNext(note, Utils.sanitize(Utils.decodeHtmlEntity(source2), sanitizeOptions));
      NoteActions.addToEventBus(newNote, eventBus);
      eventBus.pushValue({id: note.id, source: note.source});
      return newNote && { note: newNote, offset: 0 };
    },
    Backspace: () => {
      const prevNote = NoteActions.getPrevNote(note);
      const offset = toStringify(prevNote?.source).length;
      const mergedNote = NoteActions.mergeIntoPrev(note);
      if(mergedNote)
        eventBus.pushValue({ id: mergedNote.id, source: mergedNote.source });
      return mergedNote && { note: mergedNote, offset };
    },
    Clone: () => {
      const clonedNote = NoteActions.clone(note);
      NoteActions.addToEventBus(clonedNote, eventBus);
      return { note: clonedNote, offset };
    },
    Paste: () => {
      const copiedNote = params.specifics.copiedNote;
      if (copiedNote) {
        NoteActions.addToEventBus(copiedNote, eventBus);
        NoteActions.insertAfter(copiedNote, note);
        NoteActions.copiedNote(null);
        return { note: copiedNote, offset };
      }
      return { note, offset };
    },
    Remove: () => {
      const getIds = (n, ids?) => {
        if(!ids) ids = [];
        ids.push(n.id);
        n.children?.forEach(child => getIds(child, ids));
        return ids;
      };

      const next = note.getNextSibling();
      NoteActions.remove(note);
      eventBus.remove(getIds(note));
      return {note: next, offset: null};
    },
    Name: () => {
      if(params.specifics?.name){
        note.updateName(params.specifics.name);
      }
      return { note, offset };
    },    
  };

  return map[action]() || { note: null, offset: null };
}
