/* eslint-disable no-template-curly-in-string */
export default {
  "notebookId": "b234",
  "rootId": "b234",
  "mode": "outline",
  "notes": [
    {
      "id": "b234",
      "source": "Welcome to Notebl",
      "children": [
        "k0", "k1", "k2", "k3", "k4", "k5"
      ]
    },
    {
      "id": "k0",
      "source": "Notebl helps to go from concept to a dynamic data-driven story, then share and discuss it with your clients or stakeholders. Keeps users updated as the story develops.",
    },
    {
      "id": "k1",
      "source": "Notebl helps business users communicate progress by simplifying creation and sharing of business presentations.",
    },
    {
      "id": "k2",
      "source": "How does it works?",
      "children": [
        "k11"
      ]
    },
    {
      "id": "k11",
      "source": "Users compose a notebook just like a bulleted list in a text editor. Then they bring in data by pasting CSV, uploading a file or connecting to a data API. Data can be cleaned up and manipulated, if needed. Then data trends are visualized and KPIs are highlighted, styles and branding can be applied. The data presentation is ready to be shared and discussed with colleagues and stake holders."
    },
    {
      "id": "k3",
      "source": "Benefits",
      "children": ["z21", "z22", "z23", "z24", "z25", "z26", "z27"],
      "layout": { "name": "BulletedList" },
    },
    { "id": "z21", "source": "Create business presentations fast, type or paste the content, Notebl automatically creates a web page and a slideshow for your presentation or report." },
    { "id": "z22", "source": "Easily create data-driven presentations, injecting data once and using it in multiple places." },
    { "id": "z23", "source": "Make business presentations that are easy to read, collaborate on and even edit, on any device." },
    { "id": "z24", "source": "Templates on steroids, reuse data, content, logic, styles." },
    { "id": "z25", "source": "Make it easy for users to ask questions and share feedback within a context of a presentation." },
    { "id": "z26", "source": "Keep all communication secure, with content owners in control." },
    { "id": "z27", "source": "Easily review changes between iterations." },
    {
      "id": "k4",
      "source": "Use cases",
      "children": ["z41", "z42", "z43", "z44", "z45", "z46", "z47"],
      "layout": { "name": "BulletedList" },
    },
    { "id": "z41", "source": "Pre-sales presentation by a Sales Rep." },
    { "id": "z42", "source": "Post-sale report by Analytics." },
    { "id": "z43", "source": "Sharing data with clients." },
    { "id": "z44", "source": "Collecting data from vendors or multiple data sources." },
    { "id": "z45", "source": "Automate weekly and monthly reports, have Sales Rep approve them before being sent to clients." },
    { "id": "z46", "source": "Delegate work, with review and approval of iterations." },
    { "id": "z47", "source": "Collaboration of multiple distributed teams on a single report." },

    {
      "id": "k5",
      "source": "Example",
      "children": ["n1", "n2", "n3", "n4", "n5", "n6"],
    },
    { "id": "n1", "source": "Start with a structure", "children": ["n11", "n12"], },
    { "id": "n11", "source": "Compose a core of you story as a bulleted list. Each bullet is called a 'note'. Notes can contain text or data and can have children notes forming a hierarchy. The bulleted list is called a 'notebook'." },

    {
      "id": "n12", "source": "Structure: ",
      "children": ["b7"],
    },

    {
      "id": "b7",
      "source": "Weekly Update: Campaign Stats",
      "children": ["b8", "b9", "b10", "b11"],
      "layout": { "name": "BulletedList" },
    },

    { "id": "b8", "source": "Total Impressions" },
    { "id": "b9", "source": "Total Spend" },
    { "id": "b10", "source": "CPM" },
    { "id": "b11", "source": "Video Completion Rate" },

    { "id": "n2", "source": "Add data", "children": ["n21", "n22"] },
    { "id": "n21", "source": "Copy and paste CSV or JSON data right into a note. Give that note a name to make it available for referencing by other notes." },

    { "id": "n22", "source": "Structure and data:", "children": ["ba7", "ba20"], "layout": { "name": "Example" }, },

    { "id": "ba7", "source": "Weekly Update: Campaign Stats", "children": ["ba8", "ba9", "ba10", "ba11"], "layout": { "name": "Example" }, },

    { "id": "ba8", "source": "Total Impressions" },
    { "id": "ba9", "source": "Total Spend" },
    { "id": "ba10", "source": "CPM" },
    { "id": "ba11", "source": "Video Completion Rate" },
    { "id": "ba20", "source": "Data:", "children": ["ba21"] },
    {
      "id": "ba21",
      "name": "DailyImpressionsCSV",
      "source": "Date	Impressions	Watched100	Spend\n2020-04-06	1248	1228	32.9472\n2020-04-07	6211	6080	163.9704\n2020-04-08	14708	14515	388.2912\n2020-04-09	12894	12662	340.4016\n2020-04-10	12198	11978	322.0272\n2020-04-11	13201	12968	348.5064\n2020-04-12	14189	13938	374.5896",
    },


    {
      "id": "n3", "source": "Use 'Transformations' to clean up and manipulate the data. ",
      "children": ["n31", "n32"],
    },
    { "id": "n31", "source": "'Transformations' are algorithms, little programs, that transform the data." },

    {
      "id": "n32", "source": "Structure and cleaned data:",
      "children": ["bb7", "bb20"],
      "layout": { "name": "Example" },
    },

    {
      "id": "bb7",
      "source": "Weekly Update: Campaign Stats",
      "children": ["bb8", "bb9", "bb10", "bb11"],
      "layout": { "name": "Example" },
    },

    { "id": "bb8", "source": "Total Impressions" },
    { "id": "bb9", "source": "Total Spend" },
    { "id": "bb10", "source": "CPM" },
    { "id": "bb11", "source": "Video Completion Rate" },
    { "id": "bb20", "source": "Data:", "children": ["bb21", "bb22", "bb23", "bb24"] },
    {
      "id": "bb21",
      "name": "DailyImpressions",
      "source": "${DailyImpressionsCSV}",
      "transformations": [{
        "name": "parseCSV",
        "params": {
          "delimiter": "tab",
          "columns": true,
          "parsers": {
            "Date": "parseAndFormatDate",
            "Impressions": "parseNumber",
            "Watched100": "parseNumber",
            "Spend": "parseNumber",
          }
        }
      }
      ],
      "formatter": { "name": "Table" }
    },
    {
      "id": "bb22",
      "name": "TotalImpressions",
      "source": "${DailyImpressions}",
      "transformations": [{
        "name": "calculateTableTotalByColumn",
        "params": {
          "column": 'Impressions'
        }
      },
      ],
      "formatter": {
        "name": "Format Number",
      }
    },
    {
      "id": "bb23",
      "name": "TotalCompletions",
      "source": "${DailyImpressions}",
      "transformations": [{
        "name": "calculateTableTotalByColumn",
        "params": {
          "column": 'Watched100'
        }
      },
      ],
    },
    {
      "id": "bb24",
      "name": "TotalSpend",
      "source": "${DailyImpressions}",
      "transformations": [{
        "name": "calculateTableTotalByColumn",
        "params": {
          "column": 'Spend'
        }
      },
      ],
      "formatter": {
        "name": "Format Number",
      }
    },

    {
      "id": "n4", "source": "User 'formatters' and 'layouts' to present your data and analysis",
      "children": ["n41", "n42", "n43", "n44"],
    },
    { "id": "n41", "source": "Reference the cleaned data in your story." },
    { "id": "n42", "source": "Apply a format to create charts from your data." },
    { "id": "n43", "source": "Apply a layout to represent your data story in the best light." },

    {
      "id": "n44", "source": "Visualized data:",
      "children": ["bc70", "bc20"],
      // "transformations": [
      //   {
      //     "name": "setStyle",
      //     "data": "exampleStyle"
      //   },
      // ],
      // "refs": { "exampleStyle": "q1" }
    },

    {
      "id": "bc70",
      "source": "Weekly Update",
      "children": ["bc7", "bc12"],
      "layout": { "name": "Example", "params": { "template": "Super" } },
    },

    {
      "id": "bc7",
      "source": "Campaign Stats",
      "children": ["bc8", "bc9", "bc10", "bc11"],
      "layout": { "name": "Columns", "params": { "columns": "4" } },
    },

    { "id": "bc8", "source": "Total Impressions", "children": ["bc81"] },
    {
      "id": "bc81", "source": "${TotalDailyImpressions}",
      "formatter": {
        "name": "Format Number"
      }
    },
    { "id": "bc9", "source": "Total Spend", "children": ["bc91"], },
    {
      "id": "bc91", "source": "${TotalSpend}",
      "formatter": { "name": "Format Number" }
    },
    { "id": "bc10", "source": "CPM", "children": ["bc101"], },
    { "id": "bc101", "source": "$26.40", },
    { "id": "bc11", "source": "Video Completion Rate", "children": ["bc111"], },
    {
      "id": "bc111",
      "source": "${TotalCompletions/TotalDailyImpressions}",
      "formatter": {
        "name": "Format Number",
        "params": {
          "format": '0,0.00%'
        }
      }
    },
    {
      "id": "bc12",
      "source": "${DailyImpressions}",
      "formatter": {
        "name": "Linechart",
        "params": {
          "series": [{
            "dataFields": {
              "value": "Impressions",
              "category": "Date"
            }
          }],
          "categoryWidth": 240
        },
      },
    },
    { "id": "bc20", "source": "Data:", "children": ["bc21", "bc22", "bc23", "bc24"], "collapseChildren": true },
    {
      "id": "bc21",
      "name": "DailyImpressions",
      "source": "Date	Impressions	Watched100	Spend\n2020-04-06	1248	1228	32.9472\n2020-04-07	6211	6080	163.9704\n2020-04-08	14708	14515	388.2912\n2020-04-09	12894	12662	340.4016\n2020-04-10	12198	11978	322.0272\n2020-04-11	13201	12968	348.5064\n2020-04-12	14189	13938	374.5896",
      "transformations": [{
        "name": "parseCSV",
        "params": {
          "delimiter": "tab",
          "columns": true,
          "parsers": {
            "Date": "parseAndFormatDate",
            "Impressions": "parseNumber",
            "Watched100": "parseNumber",
            "Spend": "parseNumber",
          }
        }
      }
      ],
      "formatter": { "name": "Table" }
    },
    {
      "id": "bc22",
      "name": "TotalDailyImpressions",
      "source": "${DailyImpressions}",
      "transformations": [{
        "name": "calculateTableTotalByColumn",
        "params": {
          "column": 'Impressions'
        }
      }
      ],
    },
    {
      "id": "bc23",
      "name": "Total Completions",
      "source": "${DailyImpressions}",
      "transformations": [{
        "name": "calculateTableTotalByColumn",
        "params": {
          // "data": "${DailyImpressions}",
          "column": 'Watched100'
        }
      }
      ]
    },
    {
      "id": "bc24",
      "name": "TotalSpend",
      "source": "${DailyImpressions}",
      "transformations": [{
        "name": "calculateTableTotalByColumn",
        "params": {
          "column": 'Spend'
        }
      }
      ],
      "refs": { "Daily Impressions": "bc21" }
    },


    { "id": "n5", "source": "Share your data story", "children": ["n51", "n52"], },
    { "id": "n51", "source": "Select the people to share your presentation with and define their access." },
    { "id": "n52", "source": "Get notifications of the feedback and updates from clients and teammates." },
    { "id": "n6", "source": "Iterate to refine the presentation and to keep it relevant", "children": ["n61"], },
    { "id": "n61", "source": "Review changes of content and data between iterations." },

    {
      "id": "q1",
      "name": "exampleStyle",
      "source": {
        "&.note > .note-children .note-actions": {
          "display": "none"
        },
        "&.note > .note-children": {
          "marginLeft": "45px",
          "marginRight": "20px",
          "border": "1px solid #cdd",
          "padding": "10px 20px",
          "borderRadius": "5px",
          "& > .note .note": {
            "marginLeft": 20,
          },
          "& > .note .note-value": {
            "display": "list-item",
            "listStyle": "disc",
          }

        },
      },
    }
  ]
}