import _ from 'lodash';
import { Unit } from './eventBus';

export const CalculateTableTotalByColumn = (unit: Unit, params) => {
  // example parameters JSON
  // "params": {
  //   "column": 'Impressions'
  // }

  const data = params.data || unit.data;
  const column = params.column || null;

  let error: string;
  let result;

  if (!column) {
    error = 'Error, needs a "column" parameter to know which total to calculate';
  } else if (!Array.isArray(data)) {
    error = 'Error: Data is not an array';
  } else if (!data[0][column]) {
    error = 'Error: column not found';
  } else {
    result = _.sumBy(data, (x) => x[column]);
  }
  // console.log('CalculateTableTotalByColumn', data, '----', error ? { data: error } : { data: result });

  return error ? { data: error } : { data: result };
};

CalculateTableTotalByColumn.description = () => 'Sum values in a table colum';

// Legacy

// export const calculateTableTotalByColumn: TransFunctionType = (selfNoteValue: ComputedType, params, resolvedRefs) => {
//   const column = getDataFromParams(params, resolvedRefs, undefined, 'column');
//   const data = getDataFromParams(params, resolvedRefs, selfNoteValue.data);

//   if (!column) {
//     return {
//       data: 'Error, needs a "column" parameter to know which total to calculate',
//     };
//   }
//   if (!Array.isArray(data)) {
//     return {
//       data: 'Error: Data is not an array',
//     };
//   }
//   if (!data[0][column]) {
//     return {
//       data: 'Error: column not found',
//     };
//   }

//   const result = _.sumBy(data, (x) => x[column]);

//   return {
//     ...selfNoteValue,
//     data: result,
//   };
// };
