import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import NotebookView from '../components/NotebookView';
import { connect } from 'react-redux';
import Notebook from '../core/Notebook';
import AppPreloader from '../components/AppPreloader';
import Note from '../core/Note';
import { EventBus } from '../transformations2/eventBus';
import NoteActions from '../core/NoteActions';
import { NotebookContext } from '../NotebookContext';
import { initializeNotebookAction } from '../store/notebook/initializeNotebookAction';
import { StoreState } from '../store/storeFactory';
import { NotebookList } from '../services/NotebookList';

const useStyles = makeStyles({
  root: {
    minWidth: 300,
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
  },
  notebookContainer: {},
});

interface NotebookPageProps {
  notebookId: string;
  noteId: string;
  notebook: Notebook;
  renderFromNote: Note;
  notebookList: NotebookList;
  loading: boolean;
  loadNotebook: (notebookId: string, noteId: string) => void;
}

const NotebookPage = (props: NotebookPageProps) => {
  const { notebookId, noteId, notebook, renderFromNote, loading, loadNotebook } = props;
  const mode: NotebookViewMode = 'outline';

  useEffect(() => {
    loadNotebook(notebookId, noteId);
  }, [loadNotebook, notebookId, noteId]);

  const styles = useStyles();

  const renderNotebookView = () => {
    const eventBus = new EventBus();

    NoteActions.traverseDown(notebook.root, (note) => note).forEach((note) => eventBus.init(note));

    return (
      <NotebookContext.Provider value={{ eventBus }}>
        <NotebookView
          notebook={notebook}
          mode={mode}
          embedded={false}
          editable={props.notebookList.sharedTo?.permission !== 'view'}
          page="notebook"
          renderFromNote={renderFromNote}
          authenticated={true}
        />
      </NotebookContext.Provider>
    );
  };

  return (
    <div className={classNames('panel', styles.root)}>
      <div className="notebook-container">{loading ? <AppPreloader /> : renderNotebookView()}</div>
    </div>
  );
};

const mapStateToProps = (state: StoreState, ownProps) => {
  const { notebook } = state;

  return {
    notebookId: ownProps.match.params.notebookId,
    noteId: ownProps.match.params.noteId,
    notebook: notebook.notebook,
    notebookList: notebook.notebookList,
    renderFromNote: notebook.renderFromNote,
    loading: notebook.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadNotebook: (notebookId: string, noteId: string) => dispatch(initializeNotebookAction(notebookId, noteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotebookPage);
