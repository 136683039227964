import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';
import NotebookListItem from '../components/NotebookListItem';
import PageContainer from '../components/PageContainer';
// interface UpdatesPageProps {}

const useStyles = makeStyles({
  section: {
    padding: 20,
    '& > h1': {
      // ...theme.typography.content.h1,
      ...theme.typography.app.h1,
      marginTop: 0,
    },
  },
  notebooksList: {},
  notebooksListRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    minHeight: 200,
    alignItems: 'stretch',
    marginTop: 20,
  },
});

const UpdatesPage = () => {
  document.title = 'Notebl Updates';
  const styles = useStyles();

  return (
    <PageContainer pageTitle="Updates">
      <section className={styles.section}>
        <h1>Recently updated by you</h1>
        <div className={classNames('notebooks-list', styles.notebooksList)}>
          <div className={classNames('list-row', styles.notebooksListRow)}>
            <NotebookListItem notebookId="h789" title="Test Notebook" summary="basic test" />
            <NotebookListItem
              notebookId="a123"
              title="Introduction to Notebl"
              summary="(intro), Problems to solve, Notebl Prototype Phases"
            />
            <NotebookListItem notebookId="b234" title="Welcome to Notebl" summary="(intro), Problems to solve" />
          </div>
        </div>
      </section>
    </PageContainer>
  );
};

export default UpdatesPage;
