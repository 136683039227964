import Note from '../core/Note';
import React from 'react';
import NoteControls from '../components/NoteControls';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';
import _ from 'lodash';
import { FormatterFactory2 } from '../formaters/FormatterFactory2';
import { LayoutRenderFactory2 } from './LayoutRenderFactory2';

const useStyles = makeStyles({
  note: {
    display: 'flex',
    flexFlow: 'column',
    position: 'relative',
    '&.top-note': {
      padding: 10,
      paddingLeft: 60,
      paddingTop: 40,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      '& > .note-self': {
        position: 'relative',
        maxWidth: 900,
        width: '100%',
      },
      '& > .note-children': {
        position: 'relative',
        maxWidth: 900,
        width: '100%',
      },
      '&:not(.leaf) > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h1,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 5,
        },
      },
    },
    '& > .note-self': {
      '&:hover > .note-actions': {
        visibility: 'visible',
      },
    },
    '&.level-1:not(.leaf)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h2,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 5,
        },
      },
    },
    '&.level-2:not(.leaf)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h3,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 3,
        },
      },
    },
  },
  noteSelf: {
    display: 'flex',
    position: 'relative',
  },
  noteValue: {
    padding: '5px 6px',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    lineHeight: '140%',
  },
  noteControls: {
    position: 'absolute',
    left: -40,
    visibility: 'hidden',
  },
  noteChildren: {
    paddingLeft: 0,
  },
});

const PageTitleReadRenderer = (props: LayoutRenderProps) => {
  const { note, collapseNote, className } = props;
  const index = note.parent ? _.findIndex(note.parent.children, { id: note.id }) : 'top';
  const absoluteLevel = note.absoluteLevel();
  const styles = useStyles();
  const displayLevel = note.displayLevelFrom(props.topNote);
  const displayLevelClassName = _.isNull(displayLevel) ? '' : 'level-' + displayLevel;

  const pageTitleNote: Note =
    note.getFirstDescendentBy([
      { name: 'Title' },
      (note: Note, idx) => {
        return idx === 0 && note.isLeaf() && note;
      },
    ]) || note;

  if (pageTitleNote !== note) {
    pageTitleNote.layoutRender = { name: 'Hidden' };
  }

  console.log('Looking for page title in, found ', props.note, pageTitleNote);

  const logoUrlNote: Note = note.getFirstDescendentBy([
    { name: 'Logo' },

    // 1. Look for notes with name "logourl", case insensitive
    (note: Note) => note.name?.toLowerCase() === 'logourl',

    // 2. Look for notes with name "Logo"
    (note: Note) => {
      console.log('check', note, note.name?.toLowerCase());
      return note.name?.toLowerCase() === 'logo';
    },
  ]);

  if (logoUrlNote && logoUrlNote !== note) {
    logoUrlNote.layoutRender = { name: 'Hidden' };
  }
  console.log('search for logo note', logoUrlNote);

  return (
    <div
      className={classNames(
        'note',
        'page-title',
        `index-${index}`,
        `absLevel-${absoluteLevel}`,
        note.isLeaf() && 'leaf',
        displayLevelClassName,
        note === props.topNote && 'top-note',
        styles.note,
        className
      )}
      key={note.id}
    >
      {logoUrlNote && (
        <div className="page-title-logo">
          <div className="logo-wrapper">
            <FormatterFactory2 note={logoUrlNote} /* formatName="Image" */ />
          </div>
        </div>
      )}

      <div className="title-wrapper">
        <h1>
          <FormatterFactory2 note={pageTitleNote} />
        </h1>
      </div>
      {/* <div className={classNames('note-self', styles.noteSelf)}>
        <NoteControls
          onCollapseClick={(event) => collapseNote(note)}
          collapsible={!note.isLeaf()}
          onBulletClick={(event) => props.zoomNote(note)}
          closed={note.isChildrenCollapsed}
          className={classNames('note-actions', styles.noteControls)}
        />
        <div className={classNames('note-value', styles.noteValue)}>
          <FormatterFactory2 note={props.note} />
        </div>
      </div> */}

      {!note.isChildrenCollapsed && !note.isLeaf() && (
        <div className={classNames('note-children', styles.noteChildren)}>
          {note.children.map((child: Note, idx) => {
            return (
              <LayoutRenderFactory2
                mode="read"
                key={child.id}
                note={child}
                topNote={props.topNote}
                collapseNote={props.collapseNote}
                zoomNote={props.zoomNote}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PageTitleReadRenderer;
