const basicVerticalSpacing = 10;
const buttonPaddingSides = basicVerticalSpacing;
const buttonPaddingVertical = 6;
const formButtonMinWidth = basicVerticalSpacing * 8;

// const fontSizes = {
//   body: 14,
//   h1: 24,
//   h2: 20,
//   h3: 18,
//   h4: 14
// };

const noteblRed = '#DA0A0A';
const linkColor = '#0884CC';
const linkHover = '#1EACFF';

const colors = {
  noteblRed,
  darkBg: '#242648',
  darkBgText: 'rgba(255,255,255,0.6)',
  darkBgTitle: 'rgba(255,255,255,0.8)',
  darkBgLink: 'rgba(255,255,255,0.8)',
  darkBgIcon: 'rgba(255,255,255,0.4)',
  darkBgLinkHover: noteblRed,
  darkBgLinkDisabled: 'rgba(255,255,255,0.3)',
  lightBgText: 'rgb(34,34,34)',
  lightBgTextSecondary: 'rgb(120,120,120)',
  btnPrimary: {
    backgroundColor: linkColor,
    color: '#FFFFFF',
  },
  btnPrimaryHover: {
    backgroundColor: '#029FFA',
  },
  btnPrimaryDisabled: {
    backgroundColor: '#B6C6D0',
    color: 'rgba(255,255,255,0.5)',
  },
  btnSecondary: {
    backgroundColor: '#F5F5F5',
    color: 'black',
  },
  btnSecondarySelected: {
    backgroundColor: '#e5ecef',
    color: '#303f63',
  },
  btnSecondaryHover: {
    backgroundColor: '#E0E0E0',
    color: 'rgba(0,0,0,0.85)',
  },
  btnSecondaryDisabled: {
    backgroundColor: '#E5E5E5',
    color: 'rgb(120,129,120)',
  },
  btnIcon: {
    color: 'rgba(0,0,0,0.35)',
    backgroundColor: 'white',
  },
  btnIconHover: {
    color: 'rgba(0,0,0,0.9)',
    backgroundColor: '#f8f8f8',
  },
  btnIconDisabled: {
    color: 'rgba(0,0,0,0.15) !important',
    backgroundColor: 'white',
  },
  btnDanger: {
    backgroundColor: 'rgba(230,10,99,0.8)',
    color: '#FFFFFF',
  },
  btnDangerHover: {
    backgroundColor: 'rgba(230,10,99,1)',
    color: '#FFFFFF',
  },
  btnAction: {
    backgroundColor: 'rgba(150,210,253,0.22)',
    color: linkColor,
  },
  btnActionHover: {
    backgroundColor: 'rgba(150,210,253,0.5)',
    color: '#0067A3',
  },
  btnActionDisabled: {
    backgroundColor: 'rgba(216,216,216,0.22)',
    color: '#B5B5B5',
  },
  notePreviewBg: 'rgba(0 0 0 / 3%)',
  noteSettingsDivider: 'rgba(0 0 0 / 12%)',
  notePreviewDivider: 'rgba(0 0 0 / 8%)',
  inputBorderHover: 'rgba(0 0 0 / 12%)',
  inputBorderFocus: 'rgba(0 0 0 / 20%)',
  inputBorderError: 'rgba(218 11 11 / 50%) !important',
  collapserBorder: 'rgba(0 0 0 / 8%)',
  borderColor: 'rgba(0 0 0 / 8%)',
  linkColor: linkColor,
  linkHoverColor: linkHover,
};

const userContentFontFamily =
  "-apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
const appContentFontFamily = "'Montserrat', sans-serif";

const typography = {
  app: {
    body: {
      fontFamily: appContentFontFamily,
      fontSize: 14,
    },
    interface: {
      fontFamily: appContentFontFamily,
      fontSize: 12,
    },
    h1: {
      fontFamily: appContentFontFamily,
      fontSize: 22,
      fontWeight: 400,
      letterSpacing: -0.5,
      marginTop: 10,
      marginBottom: 0,
    },
    h2: {
      fontFamily: appContentFontFamily,
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: -0.3,
      marginTop: 8,
      marginBottom: 0,
    },
    button: {
      fontFamily: appContentFontFamily,
      fontSize: 13,
    },
    input: {
      fontFamily: appContentFontFamily,
      fontSize: 12,
    },
  },
  content: {
    body: {
      fontFamily: userContentFontFamily,
      fontSize: 13,
    },
    h1: {
      fontFamily: userContentFontFamily,
      fontSize: 24,
      fontWeight: 700,
      letterSpacing: -0.6,
      marginTop: 10,
      marginBottom: 0,
    },
    h2: {
      fontFamily: userContentFontFamily,
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: -0.3,
      marginTop: 8,
      marginBottom: 0,
    },
    h3: {
      fontFamily: userContentFontFamily,
      fontSize: 16,
      fontWeight: 700,
      marginTop: 4,
      marginBottom: 0,
    },
    h4: {
      fontFamily: userContentFontFamily,
      fontWeight: 700,
      marginTop: 0,
      marginBottom: 0,
    },
    button: {
      fontSize: 14,
    },
    input: {
      fontFamily: userContentFontFamily,
      fontSize: 13,
    },
    figureTitle: {
      fontFamily: userContentFontFamily,
      fontWeight: 700,
      paddingTop: basicVerticalSpacing,
      paddingBottom: basicVerticalSpacing,
    },
  },
};

export const theme = {
  fontFamily: appContentFontFamily,
  colors,
  typography,
  basicVerticalSpacing,
  buttonPaddingSides,
  buttonPaddingVertical,
  formButtonMinWidth,
  formButtons: {
    ...typography.app.button,
    paddingTop: buttonPaddingVertical,
    paddingBottom: buttonPaddingVertical,
    paddingRight: buttonPaddingSides,
    paddingLeft: buttonPaddingSides,
    minWidth: formButtonMinWidth,
    border: 'none',
    display: 'flex',
    cursor: 'pointer',
    borderRadius: 0,
    '&[disabled]': {
      opacity: 0.4,
    },
  },
  iconButton: {
    ...typography.app.button,
    border: 'none',
    display: 'flex',
    borderRadius: 4,
    cursor: 'pointer',
    '&[disabled]': {
      opacity: 0.4,
    },
    '& label': {
      marginBottom: 0,
      cursor: 'inherit',
    },
  },
  formButtonPrimary: {
    ...colors.btnPrimary,
    '&:hover': {
      ...colors.btnPrimaryHover,
    },
  },
  controlButtonIcon: {
    ...colors.btnIcon,
    ...typography.app.button,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      ...colors.btnIconHover,
    },
    '&[disabled]': {
      cursor: 'default',
      ...colors.btnIconDisabled,
    },
  },
  controlButtonPrimary: {
    ...colors.btnPrimary,
    ...typography.app.button,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      ...colors.btnPrimaryHover,
    },
    '&[disabled]': {
      cursor: 'default',
      ...colors.btnPrimaryDisabled,
    },
  },
  controlButtonSecondary: {
    ...colors.btnSecondary,
    ...typography.app.button,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      ...colors.btnSecondaryHover,
    },
    '&[disabled]': {
      cursor: 'default',
      opacity: 0.6,
      ...colors.btnSecondaryDisabled,
    },
  },
  controlButtonActions: {
    ...colors.btnAction,
    ...typography.app.button,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      ...colors.btnActionHover,
    },
    '&[disabled]': {
      cursor: 'default',
      ...colors.btnActionDisabled,
    },
  },
  controlButtonDanger: {
    ...colors.btnDanger,
    ...typography.app.button,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      ...colors.btnDangerHover,
    },
  },
  controlInputText: {
    ...typography.content.input,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.borderColor,
    boxSizing: 'border-box',
    'textarea&': {
      minHeight: 100,
    },
    outline: 'none',
    padding: '5px 6px',
    '&:hover': {
      borderWidth: 1,
      borderColor: colors.inputBorderHover,
    },
    '&:focus': {
      borderWidth: 1,
      borderColor: colors.inputBorderFocus,
    },
  },
  controlInputCheckbox: {
    'label&': {
      padding: 5,
      margin: 0,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    'label&>input': {
      marginRight: basicVerticalSpacing,
    },
  },
  controlInputSelect: {
    ...typography.app.input,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.borderColor,
    outline: 'none',
    padding: '5px 6px',
    '&:hover': {
      borderWidth: 1,
      borderColor: colors.inputBorderHover,
    },
    '&:focus': {
      borderWidth: 1,
      borderColor: colors.inputBorderFocus,
    },
  },
  notebookSettingsPanelWidth: 375,
  spacer: {
    width: 20,
    minWidth: 20,
    height: '100%',
    cursor: 'default',
  },
  menuPanel: {
    zIndex: 10,
    borderRadius: 4,
    boxShadow: '0 10px 10px -5px rgba(0 0 0 /25%)',
  },
};
