import React, { memo } from 'react';

const PlusIcon = () => {
  return (
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1" y1="9" x2="11" y2="9" stroke="currentColor" />
      <line x1="6" y1="4" x2="6" y2="14" stroke="currentColor" />
    </svg>
  );
};

export default memo(PlusIcon);
