import { ActionType } from './actionType';
import firebase from 'firebase';
import { ApplicationState } from './state';
import { push } from 'connected-react-router';

export const onAuthStateChanged = (user: firebase.User) => {
  return async (dispatch, getState, firebase: firebase.app.App) => {
    const action = new UpdateAuthAction({
      currentUser: user,
      initialized: true,
    });

    return dispatch({ ...action });
  };
};

export const applicationLogin = (input: { userName: string; password: string }) => async (
  dispatch,
  getState,
  firebase: firebase.app.App
) => {
  const auth = firebase.auth();
  try {
    await auth.signInWithEmailAndPassword(input.userName, input.password);

    firebase.analytics().logEvent('app authenticated');

    dispatch(push('/notebooks'));
  } catch (e) {
    const action = new UpdateAuthAction({
      authenticationError: e.message,
    });

    return dispatch({ ...action });
  }
};

export const applicationSignout = () => async (dispatch, getState, firebase: firebase.app.App) => {
  const auth = firebase.auth();
  return await auth.signOut();
};

export class UpdateAuthAction {
  readonly type = ActionType.UPDATE_AUTH;
  constructor(public payload: Partial<ApplicationState>) {}
}

export type Actions = UpdateAuthAction;
