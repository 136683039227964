import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, NoteblIconProps>((theme: Theme) => ({
  root: (props: NoteblIconProps) => ({
    width: props.size === 'navSmall' ? 34 : props.size === 'navBig' ? 68 : 200,
    height: props.size === 'navSmall' ? 18 : props.size === 'navBig' ? 36 : 107,
    '& rect.brandLines': {
      fill:
        props.colorScheme === 'uniformDark' ? '#dddddd' : props.colorScheme === 'uniformLight' ? '#eeeeee' : '#DA0A0A',
    },
    '& path.brandLetters': {
      fill:
        props.colorScheme === 'uniformDark'
          ? '#dddddd'
          : props.colorScheme === 'uniformLight'
          ? '#eeeeee'
          : props.colorScheme === 'brandDarkBg'
          ? 'white'
          : 'black',
    },
  }),
}));

export interface NoteblIconProps {
  colorScheme?: 'brand' | 'brandDarkBg' | 'uniformLight' | 'uniformDark';
  size?: 'navSmall' | 'navBig' | 'applicationDefault';
  animate?: boolean;
}

const NoteblLogoIcon = (props: NoteblIconProps) => {
  const { animate } = props;
  const styles = useStyles(props);
  return (
    <svg
      // width="200"
      // height="107"
      viewBox="0 0 200 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.root}
    >
      <path
        d="M31.9556 0.597299V35.4398H25.3355L7.96401 14.2854V35.4398H0V0.597299H6.66985L23.9916 21.7517V0.597299H31.9556Z"
        className="brandLetters"
      />
      <path
        d="M54.5842 36.0371C50.9672 36.0371 47.6986 35.2573 44.7785 33.6977C41.8915 32.1381 39.6185 29.9978 37.9593 27.2767C36.3333 24.5225 35.5203 21.4364 35.5203 18.0186C35.5203 14.6007 36.3333 11.5312 37.9593 8.81018C39.6185 6.05596 41.8915 3.89904 44.7785 2.33943C47.6986 0.779809 50.9672 0 54.5842 0C58.2011 0 61.4531 0.779809 64.3401 2.33943C67.227 3.89904 69.5001 6.05596 71.1592 8.81018C72.8184 11.5312 73.648 14.6007 73.648 18.0186C73.648 21.4364 72.8184 24.5225 71.1592 27.2767C69.5001 29.9978 67.227 32.1381 64.3401 33.6977C61.4531 35.2573 58.2011 36.0371 54.5842 36.0371ZM54.5842 29.1682C56.6415 29.1682 58.4998 28.7036 60.159 27.7745C61.8181 26.8121 63.1123 25.4848 64.0414 23.7925C65.0037 22.1001 65.4849 20.1755 65.4849 18.0186C65.4849 15.8616 65.0037 13.937 64.0414 12.2447C63.1123 10.5523 61.8181 9.24156 60.159 8.31243C58.4998 7.35011 56.6415 6.86895 54.5842 6.86895C52.5268 6.86895 50.6685 7.35011 49.0094 8.31243C47.3502 9.24156 46.0394 10.5523 45.0771 12.2447C44.148 13.937 43.6834 15.8616 43.6834 18.0186C43.6834 20.1755 44.148 22.1001 45.0771 23.7925C46.0394 25.4848 47.3502 26.8121 49.0094 27.7745C50.6685 28.7036 52.5268 29.1682 54.5842 29.1682Z"
        className="brandLetters"
      />
      <path
        d="M83.939 7.1676H72.7894V0.597299H103.152V7.1676H92.0025V35.4398H83.939V7.1676Z"
        className="brandLetters"
      />
      <path
        d="M132.976 28.9691V35.4398H105.997V0.597299H132.328V7.06805H114.011V14.6339H130.188V20.9055H114.011V28.9691H132.976Z"
        className="brandLetters"
      />
      <path
        d="M163.483 17.3217C165.474 17.9522 167.033 19.0141 168.161 20.5073C169.29 21.9674 169.854 23.7759 169.854 25.9328C169.854 28.9857 168.659 31.3417 166.27 33.0008C163.914 34.6268 160.463 35.4398 155.917 35.4398H137.898V0.597299H154.921C159.169 0.597299 162.421 1.41029 164.677 3.03628C166.967 4.66226 168.112 6.86895 168.112 9.65636C168.112 11.3487 167.697 12.8585 166.867 14.1859C166.071 15.5132 164.943 16.5585 163.483 17.3217ZM145.912 6.66985V14.8827H153.926C155.917 14.8827 157.427 14.5343 158.455 13.8375C159.484 13.1406 159.998 12.1119 159.998 10.7514C159.998 9.39089 159.484 8.3788 158.455 7.71513C157.427 7.01828 155.917 6.66985 153.926 6.66985H145.912ZM155.32 29.3673C157.443 29.3673 159.036 29.0188 160.098 28.322C161.193 27.6251 161.74 26.5467 161.74 25.0866C161.74 22.1997 159.6 20.7562 155.32 20.7562H145.912V29.3673H155.32Z"
        className="brandLetters"
      />
      <path d="M174.465 0.597299H182.529V28.8695H200V35.4398H174.465V0.597299Z" className="brandLetters" />
      {/* <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200 51.4H0V62.4H200V51.4ZM0 73.4H137.8V84.4H0V73.4ZM0 95.4H106V106.4H0V95.4Z"
        className="brandLines"
      /> */}
      <rect y="51.4" width="200" height="11" className="brandLines">
        {animate && (
          <animate
            attributeName="width"
            attributeType="XML"
            dur="2s"
            values="200; 138; 106; 75; 106; 138; 200"
            repeatCount="indefinite"
          />
        )}
      </rect>
      <rect y="73.4" width="138" height="11" className="brandLines">
        {animate && (
          <animate
            attributeName="width"
            attributeType="XML"
            dur="2s"
            values="138; 106; 75; 106; 138; 200; 138"
            repeatCount="indefinite"
          />
        )}
      </rect>
      <rect y="95.4" width="106" height="11" className="brandLines">
        {animate && (
          <animate
            attributeName="width"
            attributeType="XML"
            dur="2s"
            values="106; 75; 106; 138; 200; 138; 106"
            repeatCount="indefinite"
          />
        )}
      </rect>
    </svg>
  );
};

export default memo(NoteblLogoIcon);
