import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  root: {}
});

export interface NotebookIconProps {
  className?: string;
}

const NotebookIcon = ({ className }: NotebookIconProps) => {
  const styles = useStyles();
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.root, className)}
    >
      <rect x="0.5" y="1" width="13" height="14" stroke="currentColor" />
      <line x1="3" y1="4" x2="11" y2="4" stroke="currentColor" />
      <line x1="3" y1="7" x2="9" y2="7" stroke="currentColor" />
      <line x1="3" y1="10" x2="7" y2="10" stroke="currentColor" />
    </svg>
  );
};

export default memo(NotebookIcon);
