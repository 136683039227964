import Note from '../core/Note';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';
import WebFont from 'webfontloader';
import _ from 'lodash';
import { FormatterFactory2 } from '../formaters/FormatterFactory2';
import { LayoutRenderFactory2 } from './LayoutRenderFactory2';
import { relative } from 'path';

const useStyles = makeStyles({
  note: {
    display: 'flex',
    flexFlow: 'column',
    // color: 'red',
    position: 'relative',
    '&.top-note': {
      padding: 10,
      paddingLeft: 60,
      paddingTop: 40,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      '& > .note-self': {
        position: 'relative',
        maxWidth: 900,
        width: '100%',
      },
      '& > .note-children': {
        position: 'relative',
        maxWidth: 900,
        width: '100%',
      },
      '&:not(.leaf) > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h1,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 5,
        },
      },
    },
    '& > .note-self': {
      '&:hover > .note-actions': {
        visibility: 'visible',
      },
    },
    '&.level-1:not(.leaf)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h2,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 5,
        },
      },
    },
    '&.level-2:not(.leaf)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h3,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 3,
        },
      },
    },
  },
  noteSelf: {
    display: 'flex',
    position: 'relative',
  },
  noteValue: {
    padding: '5px 0',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    lineHeight: '140%',
  },
  noteControls: {
    position: 'absolute',
    left: -40,
    visibility: 'hidden',
  },
  noteChildren: {
    paddingLeft: 0,
  },
  wrapper: {
    '& .note': {
      fontFamily: 'Montserrat',
    },
    '&.marketingreport-wrapper': {
      position: 'relative',
      '& > .note > .note-self': {
        position: 'absolute',
        maxWidth: 700,
        margin: '0 auto',
        '& > .note-value': {
          color: 'white',
        },
      },
      '& > .note.columns > .note-self > .note-value': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '&:not(.panel)': {
        padding: 0,
        boxShadow: '0 10px 20px rgba(36,38,72,0.2)',
        cursor: 'pointer',
        borderRadius: 4,
        overflow: 'hidden',
        '& .note-actions': {
          display: 'none',
        },
      },
      '& > .marketingreport-renderer > .note-children > .note:not(.page-title)': {
        paddingLeft: 40,
        paddingRight: 40,
      },
      '&.panel': {
        padding: 0,
        boxShadow: 'none',
      },
      '& .level-1:not(.page-title)': {
        margin: '0 auto',
        maxWidth: 700,
      },
      '& .level-1:not(.page-title):not(.footer)': {
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: 2,
          top: 0,
          left: 0,
          background: 'linear-gradient(125.66deg, #FF0042 10.97%, #361495 95.98%)',
        },
        paddingTop: 40,
        marginTop: 40,
        position: 'relative',
      },
      '& > .marketingreport-renderer > .note-self': {
        paddingLeft: 40,
        paddingRight: 40,
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        alignSelf: 'center',
        '&> .note-value': {
          margin: '0 auto',
          maxWidth: 700,
        },
      },
      '& .page-title': {
        marginTop: 0,
        maxWidth: '100%',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        color: 'white',
        paddingTop: 20,
        paddingRight: 40,
        paddingLeft: 40,
        paddingBottom: 20,

        background: 'linear-gradient(125.66deg, #FF0042 10.97%, #361495 95.98%)',
        '& > .note-self': {
          maxWidth: 700,
          margin: '0 auto',
        },
        '& > .note-children': {
          width: '100%',
          maxWidth: 700,
        },
        '& .page-title-logo': {
          position: 'absolute',
          top: 0,
          width: 700,
          '& > .logo-wrapper': {
            display: 'flex',
            justifyContent: 'flex-end',
            '& > img': {
              maxWidth: 200,
            },
          },
          '& + .title-wrapper': {
            marginTop: 40,
            '& > h1': {
              paddingRight: 30,
            },
          },
        },
        '& > .title-wrapper': {
          width: '100%',
          maxWidth: 700,

          '& > h1': {
            maxWidth: 500,
            fontFamily: 'Ledger',
            fontSize: '3em',
            lineHeight: '110%',
            letterSpacing: -1,
            paddingTop: 40,
            paddingBottom: 40,
          },
        },
      },

      '& .note svg.recharts-surface': {
        '& g.recharts-line path.recharts-curve': {
          'stroke': '#ff0043',
        },
        '& g.recharts-line-dots circle': {
          'stroke': '#ff0043',
        },
        '& g.recharts-bar-rectangle path.recharts-rectangle': {
          'fill': '#ff0043',
        },
      },
    },
  },
});

const MarketingReportReadRenderer = (props: LayoutRenderProps) => {
  const { note, collapseNote, className } = props;
  const index = note.parent ? _.findIndex(note.parent.children, { id: note.id }) : 'top';
  const absoluteLevel = note.absoluteLevel();
  const styles = useStyles();
  const isTop = note === props.topNote;
  const displayLevel = note.displayLevelFrom(props.topNote);
  const displayLevelClassName = _.isNull(displayLevel) ? '' : 'level-' + displayLevel;

  // Applying Layouts:
  // 1. Find "special" notes, for example, find page title
  // 2. apply a needed template, but keep if user has already applied it in the UI

  const metaData: Note = note.getFirstDescendentBy([{ name: 'MetaData' }, { name: 'Settings' }]);
  // console.log('metaData.layoutRender?.name:', metaData, metaData.layoutRender, metaData.layoutRender?.name ===' Hidden');
  if (metaData && metaData.layoutRender?.name !== 'Hidden') {
    metaData.layoutRender = { name: 'Hidden' };
  }

  const pageTitleNote: Note = note.getFirstDescendentBy([
    // condition to search for a page title note among Marketing Report Children

    // 1. Look for notes with name "PageTitle"
    { name: 'PageTitle' },

    // 2. Otherwise, look for notes with layout "PageTitle"
    (note: Note) => note.layoutRender.name === 'PageTitle',
  ]);
  if (pageTitleNote && pageTitleNote.layoutRender?.name !== 'PageTitle') {
    pageTitleNote.layoutRender = { name: 'PageTitle' };
  }

  WebFont.load({
    google: {
      families: ['Ledger', 'Montserrat'],
    },
  });

  console.log('title note', pageTitleNote);
  if (props.note.layoutRender?.template === 'h1') {
    return (
      <h1>
        {' '}
        Page title
        <FormatterFactory2 note={props.note} />
      </h1>
    );
  } else {
    return (
      <div
        className={classNames('marketingreport-wrapper', styles.wrapper, className)}
        onClick={(event) => {
          !isTop && props.zoomNote(note);
        }}
      >
        <div
          className={classNames(
            'note',
            'marketingreport-renderer',
            `index-${index}`,
            `absLevel-${absoluteLevel}`,
            note.isLeaf() && 'leaf',
            displayLevelClassName,
            note === isTop && 'top-note',
            styles.note
          )}
          key={note.id}
        >
          {!pageTitleNote && (
            <div className="page-title">
              <div className="title-wrapper">
                <h1>
                  <FormatterFactory2 note={props.note} />
                </h1>
              </div>
            </div>
          )}
          {pageTitleNote && (
            <div className={classNames('note-self', styles.noteSelf)}>
              <div className={classNames('note-value', styles.noteValue)}>
                <FormatterFactory2 note={pageTitleNote} />
              </div>
            </div>
          )}

          {!note.isChildrenCollapsed && !note.isLeaf() && (
            <div className={classNames('note-children', styles.noteChildren)}>
              {note.children.map((child: Note, idx) => {
                return (
                  <LayoutRenderFactory2
                    mode="read"
                    key={child.id}
                    note={child}
                    topNote={props.topNote}
                    collapseNote={props.collapseNote}
                    zoomNote={props.zoomNote}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default MarketingReportReadRenderer;
