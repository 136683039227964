import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, UserBubbleProps>((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    width: 28,
    minWidth: 28,
    height: 28,
    minHeight: 28,
    overflow: 'hidden',
    backgroundColor: props => (props.color ? props.color : '#CCCCCC'),
    borderRadius: 14,
    alignItems: 'center',
    textDecoration: 'unset'
  }
}));

export interface UserBubbleProps {
  initials: string;
  className?: string;
  color?: string;
}

const PeopleIcon = (props: UserBubbleProps) => {
  const styles = useStyles(props);
  return <div className={classNames('user-icon', styles.root, props.className)}>{props.initials}</div>;
};

export default memo(PeopleIcon);
