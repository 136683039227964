import { ActionType } from './actionType';
import { ShareableLink } from '../../services/ShareableLink';
import Note from '../../core/Note';
import * as NotebookActions from '../../core/NotebookActions';
import { loadNotebook } from './initializeNotebookAction';
import { NotebookAction } from './actions';
import firebase from 'firebase';

export const initializeShareableNotebook = (linkIdId: string, noteId: string) => {
  return async (dispatch, getState) => {
    const loadinAction: NotebookAction = {
      type: ActionType.UPDATE_NOTEBOOK,
      notebookId: null,
      noteId: null,
      loading: true,
    };

    dispatch(loadinAction);

    const data = await firebase.firestore().collection('ShareableLinks').doc(linkIdId).get();

    const shareableLink = data.data() as ShareableLink;

    console.log('shareableLink', shareableLink);

    if (!shareableLink) {
      const readyActionEmpty: NotebookAction = {
        type: ActionType.UPDATE_NOTEBOOK,
        notebookId: null,
        loading: false,
      };
      console.log('before dispatch', readyActionEmpty);
      dispatch(readyActionEmpty);
    } else {
      const notebook = await loadNotebook(shareableLink.notebookId);
      const renderFromNote: Note = NotebookActions.getNoteById(notebook, shareableLink.noteId) || notebook.root;

      const readyAction: NotebookAction = {
        type: ActionType.UPDATE_NOTEBOOK,
        notebookId: shareableLink.notebookId,
        notebook: notebook,
        renderFromNote: renderFromNote,
        loading: false,
        archived: shareableLink.archived,
      };
      console.log('before dispatch', readyAction);
      dispatch(readyAction);
    }
  };
};
