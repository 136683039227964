import Note from "../../core/Note";
import { ActionType } from "./actionType";
import { ShareableLink } from "../../services/ShareableLink";
import { NotebookState } from "./state";
import { NotebookAction } from "./actions";
import * as firebase from "firebase";
import _ from "lodash";

export const unshareAction = (note: Note, shareableLink: ShareableLink) => {
    return async (dispatch, getState) => {

        const { notebook } = getState() as { notebook: NotebookState };

        note.shareableLinks = _(note.shareableLinks)
            .without(shareableLink)
            .value();

        const emails = _(shareableLink.invitations).map(i => i.email).value();

        const firestore = firebase.firestore();
        const notebookList = firestore.collection('notebookList');

        const sharedNotebooks = await notebookList
            .where("notebookId", "==", notebook.notebookId)
            .where("sharedTo.email", "in", emails)
            .get()

        sharedNotebooks.docs.forEach(async i =>
            await notebookList.doc(i.id).delete()
        )

        await firestore.collection('ShareableLinks')
            .doc(shareableLink.id)
            .update({ archived: true });

        const copy: any = {...notebook.notebook};
        const readyAction: Partial<NotebookAction> = {
            type: ActionType.UPDATE_NOTEBOOK,
            notebook: copy,
        };

        dispatch(readyAction);
    };
  };