import Note from '../core/Note';
import React from 'react';
import NoteControls from '../components/NoteControls';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';
import _ from 'lodash';
import { If } from 'react-if';
import { FormatterFactory2 } from '../formaters/FormatterFactory2';
import { LayoutRenderFactory2 } from './LayoutRenderFactory2';

const useStyles = makeStyles({
  note: {
    display: 'flex',
    flexFlow: 'column',
    position: 'relative',
    '&.top-note.note': {
      padding: 10,
      paddingLeft: 60,
      paddingTop: 40,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      '& > .note-self': {
        position: 'relative',
        maxWidth: 800,
        width: '100%',
      },
      '& > .note-children': {
        position: 'relative',
        maxWidth: 800,
        width: '100%',
      },
      '&:not(.leaf) > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h1,
          marginTop: 0,
          paddingLeft: 0,
        },
        '& > .note-actions': {
          top: 7,
        },
      },
    },
    '& > .note-self': {
      '&:hover > .note-actions': {
        visibility: 'visible',
      },
    },
    '&.level-1:not(.leaf)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h2,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 5,
        },
      },
    },
    '&.level-2:not(.leaf)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h3,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 3,
        },
      },
    },
  },
  noteSelf: {
    display: 'flex',
    position: 'relative',
  },
  noteValue: {
    padding: '5px 0',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    lineHeight: '140%',

    ...theme.typography.content.h3,

  },
  noteControls: {
    position: 'absolute',
    left: -40,
    visibility: 'hidden',
  },
  noteChildren: {
    paddingLeft: 0,
  },
  wrapper: {
    '&:not(.panel)': {
      padding: '10px 20px',
      border: '1px solid #f1f1f1',
      background: '#f8f8f8',
      borderRadius: 4,
      overflow: 'hidden',
      '& .note-actions': {
        display: 'none',
      },    
    },  
    '&.panel': {
      padding: 0,
      boxShadow: 'none',
    },
    '&.superExample':{
      "background": "linear-gradient(to right top, rgb(140, 48, 133) 0%, rgb(40, 73, 162) 100%)",
      "color": "white",
      "padding": 20,
      "& > .note > .note-self > .note-value": {
        "color": "#ffee66",
        "fontSize": 26,
        "borderBottom": "1px solid rgba(255, 238, 102, 0.2)",
        "width": "100%",
        "display": "block",
        "paddingBottom": 9,
      },
      "& > .note > .note-children .columns .value": {
        "fontSize": 26,
        "fontWeight": "bold"
      },
      "& .note-actions": {
        "display": "none",
      },
      "& .chart": {
        "& h2": {
          "fontSize": 16,
          "color": "#ffee66",
        },
        "& svg": {
          "& text": {
            "fill": "white"
          },
          "& .recharts-cartesian-axis-tick-line": {
            "stroke": "rgba(255,255,255,0.3)",
          },
          "& .recharts-reference-line line": {
            "stroke": "rgba(255,255,255,0.3)",
          },
          "& .recharts-line-curve": {
            "stroke": "#ff0043",
          },
          "& .recharts-line-dot": {
            "stroke": "#ff0043",
            "fill": "#ff0043",
          },
        }
      },      
    }
  }  
});

const ExampleReadRenderer = (props: LayoutRenderProps) => {
  const { note, collapseNote, className } = props;

  const index = note.parent ? _.findIndex(note.parent.children, { id: note.id }) : 'top';
  const absoluteLevel = note.absoluteLevel();
  const displayLevel = note.displayLevelFrom(props.topNote);
  const displayLevelClassName = _.isNull(displayLevel) ? '' : 'level-' + displayLevel;

  // const layoutParams = note.layoutRender.params || {};
  const template = note.layoutRender.params?.template; 

  const isTop = note === props.topNote;

  const styles = useStyles();

  return (
    <div
    className={classNames('example-wrapper', styles.wrapper, className, template==='Super' && 'superExample')}
    onClick={(event) => {
      !isTop && props.zoomNote(note);
    }}
  >    
    <div
      className={classNames(
        'note', 'example-renderer',
        `index-${index}`,
        `absLevel-${absoluteLevel}`,
        note.isLeaf() && 'leaf',
        displayLevelClassName,
        note === props.topNote && 'top-note',
        styles.note,
        className
      )}
      key={note.id}
    >
      <div className={classNames('note-self', styles.noteSelf)}>
        <NoteControls
          onCollapseClick={(event) => collapseNote(note)}
          collapsible={!note.isLeaf()}
          onBulletClick={(event) => props.zoomNote(note)}
          closed={note.isChildrenCollapsed}
          className={classNames('note-actions', styles.noteControls)}
        />
        <div className={classNames('note-value', styles.noteValue)}>
          <FormatterFactory2 note={props.note} />
        </div>
      </div>

      <If condition={!note.isChildrenCollapsed && !note.isLeaf()}>
        <div className={classNames('note-children', styles.noteChildren)}>
            {note.children.map((child: Note, idx) => {
              return (
                <LayoutRenderFactory2
                  mode="read"
                  key={child.id}
                  note={child}
                  topNote={props.topNote}
                  collapseNote={props.collapseNote}
                  zoomNote={props.zoomNote}
                />
              );
            })}
          </div>
      </If>
    </div>
    </div>
  );
};

export default ExampleReadRenderer;
