import { ActionType } from './actionType';
import { NotebookState } from './state';

export const copyNote = note => {
  return {type: ActionType.COPY_NOTE, noteToCopy: note}
};

export type Action<TActionType, TPayload> = { readonly type: TActionType } & Partial<TPayload>;

export type NotebookAction = Action<ActionType, NotebookState>;
export type EmptifyNotebookAction = Action<ActionType, { notebookId: string }>;