import React, { forwardRef } from 'react';
import { If } from 'react-if';
import Note from '../core/Note';

export interface ReferenceSuggestionProps {
    items: { active: boolean, note: Note }[],
    itemSelected: (note: Note) => void;
}

const ReferenceSuggestion = (props: ReferenceSuggestionProps, ref) => {

    return (
        <div className="input__marker input__marker--custom" ref={ref}>
            <ul className="custom-suggestions">
                {props.items.map((suggestion, index) => {
                    return (
                        <li key={index}
                            onClick={() => props.itemSelected(suggestion.note)}
                            className={suggestion.active ? "custom-suggestions--active" : ""}>
                            {suggestion.note.name}
                        </li>
                    );
                })}
                <If condition={!props.items.length}>
                    <li>no suggestions found</li>
                </If>
            </ul>
        </div>
    )
};

export default forwardRef(ReferenceSuggestion);
