import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import _ from 'lodash';
import { theme } from '../../../shared/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.15s linear',
    '& > svg + span': {
      marginLeft: 5,
    },
  },
  importancePrimary: {
    ...theme.controlButtonPrimary,
  },
  importanceSecondary: {
    ...theme.controlButtonSecondary,
  },
  importanceBasic: {
    ...theme.controlButtonIcon,
  },
  active: {
    cursor: 'pointer',
  },
  inactive: {
    cursor: 'default',
    '& hover': {
      backgroundColor: 'inherit',
    },
  },
  label: {},
});

export interface ControlButtonProps {
  label?: string;
  onClick?: (event: any) => void;
  className?: string;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: React.ReactNode;
  disabled?: boolean;
  importance?: 'primary' | 'secondary';
}

const ControlButton = ({
  label,
  className,
  onClick,
  icon,
  endIcon,
  children,
  disabled,
  importance,
}: ControlButtonProps) => {
  const styles = useStyles();
  return (
    <button
      className={classNames(
        styles.root,
        className,
        _.toBoolean(disabled) ? styles.inactive : styles.active,
        importance === 'primary'
          ? styles.importancePrimary
          : importance === 'secondary'
          ? styles.importanceSecondary
          : styles.importanceBasic
      )}
      disabled={_.toBoolean(disabled)}
      onClick={!_.toBoolean(disabled) ? onClick : () => {}}
    >
      {icon}
      {label && <span className={styles.label}>{label}</span>}
      {children && <span className={styles.label}>{children}</span>}
      {endIcon}
    </button>
  );
};

export default memo(ControlButton);
