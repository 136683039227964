import React from 'react';
import _ from 'lodash';
import { FormaterProps } from './FormatterFactory2';

const DefaultFormatter = (props: FormaterProps) => {
  return _.isString(props.unit.data) ? props.unit.data :
    _.isPlainObject(props.unit.data) || _.isArrayLike(props.unit.data) ? 
        <pre>{JSON.stringify(props.unit.data, null, 2)}</pre> :
          props.unit.data ? props.unit.data.toString() : '(...)';
};

export default DefaultFormatter;
