import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import LoginPage from './pages/LoginPage';
import UpdatesPage from './pages/UpdatesPage';
import NotebookPage from './pages/NotebookPage';
import ComparePage from './pages/ComparePage';
import NotebooksPage from './pages/NotebooksPage';
import AppContainer from './components/AppContainer';
import { connect } from 'react-redux';
import { applicationLogin, applicationSignout } from './store/application/actions';
import { isUndefined } from 'lodash';
import AppPreloader from './components/AppPreloader';
import './App.css';
import ShareableNotePage from './pages/ShareableNotePage';
import RawNotebookPage from './pages/RawNotebookPage';

type Props = {
  history;
  authenticationError: string;
  userName: string;
  applicationInitialized: boolean;
  login: (input: { userName: string; password: string }) => void;
  signOut: () => void;
};

const App: React.FC<Props> = (props) => {
  const { login, signOut, history, authenticationError, userName, applicationInitialized } = props;

  const ShareableRoute = (props) => {
    const { baseUrl } = props;
    const render = (props) => <ShareableNotePage {...props} baseUrl={baseUrl} userName={userName} />;
    return <Route {...props} render={render} />;
  };

  const CustomRoute = ({ children, ...rest }) => {
    const appContainer = (props) => (
      <AppContainer
        authenticationError={authenticationError}
        singOut={signOut}
        login={login}
        userName={userName}
        showNav={rest.showNav}
      >
        {React.cloneElement(children, props)}
      </AppContainer>
    );

    const anonymousOnProtectedPage = isUndefined(rest.public) && !userName;

    const render = (props) =>
      applicationInitialized ? (
        anonymousOnProtectedPage ? (
          <Redirect to="/" />
        ) : (
          appContainer(props)
        )
      ) : (
        <AppPreloader darkBg={true} />
      );

    return <Route {...rest} render={render} />;
  };

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/welcome" />
        </Route>
        <CustomRoute path="/login" public showNav={false}>
          <LoginPage />
        </CustomRoute>
        <CustomRoute path="/notebooks">
          <NotebooksPage />
        </CustomRoute>
        <CustomRoute path="/updates">
          <UpdatesPage />
        </CustomRoute>
        <CustomRoute path="/notebook/:notebookId/:noteId?" showNav={false}>
          <NotebookPage />
        </CustomRoute>
        <CustomRoute path="/compare/:id1/:id2">
          <ComparePage />
        </CustomRoute>
        <CustomRoute path="/updates">
          <UpdatesPage />
        </CustomRoute>
        <CustomRoute path="/raw/:notebookId" showNav={false}>
          <RawNotebookPage />
        </CustomRoute>
        <ShareableRoute path="/link/:linkId/:noteId?" baseUrl="link" />

        <Route
          path="/welcome/:noteId?"
          render={(props) => <ShareableNotePage {...props} linkId="uFxy1Cxi3" baseUrl="welcome" />}
        />
      </Switch>
    </ConnectedRouter>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { application } = state;

  return {
    history: ownProps.history,
    authenticationError: application.authenticationError,
    userName: application.currentUser?.email,
    applicationInitialized: application.initialized,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (input: { userName: string; password: string }) => dispatch(applicationLogin(input)),
    signOut: () => dispatch(applicationSignout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
