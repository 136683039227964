import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { reducer as applicationReducer } from './application/reducer';
import { reducer as notebookReducer } from './notebook/reducer';
import { reducer as rawNotebookReducer } from './rawNotebook/reducer';
import { reducer as notebookListReducer } from './notebooks/reducer';
import { routerMiddleware, connectRouter, RouterState } from 'connected-react-router'
import { ApplicationState } from './application/state';
import { NotebookState } from './notebook/state';
import { RawNotebookState } from './rawNotebook/state';
import { NotebookListState } from './notebooks/state';

export type StoreState = {
    application: ApplicationState;
    notebook: NotebookState;
    notebookList: NotebookListState;
    rawNotebook: RawNotebookState;
    router: RouterState<{}>;
}

export function storeFactory(firebase: firebase.app.App, history) {

    const reducers = combineReducers<StoreState>({
        router: connectRouter(history),
        application: applicationReducer,
        notebook: notebookReducer,
        rawNotebook: rawNotebookReducer,
        notebookList: notebookListReducer,
    });

    const middleware = applyMiddleware(
        routerMiddleware(history),
        thunk.withExtraArgument(firebase),
    );

    return createStore(reducers, middleware);
}