import React, { memo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import NoteblLogo from './NoteblLogo';
import classNames from 'classnames';

import { theme } from '../shared/theme';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
  appBranding: {
    '& .notebl-logo': {
      margin: '32px 40px 16px',
      textAlign: 'center',
      '& #logo': {
        width: 68,
        height: 36,
        '& .logo-word': {
          fill: '#FFFFFF',
        },
        '& .logo-lines': {
          fill: '#da0a0a',
        },
      },
    },
  },
  form: {
    padding: 40,
    alignItems: 'flex-start',
    display: 'flex',
    flexFlow: 'column',
  },
  formField: {
    boxSizing: 'border-box',
    width: '100%',
    marginBottom: 10,
    '& > label': {
      opacity: 0.6,
    },
    '& > input[type="text"], & > input[type="password"] ': {
      boxSizing: 'border-box',
      color: 'white',
      width: '100%',
      padding: '5px 0',
      fontSize: 14,
      lineHeight: 1.2,
      outline: 'none',
      border: 'none',
      borderBottom: '1px solid rgba(36,38,72,0.2)',
      backgroundColor: 'transparent',
    },
  },
  loginForm: {
    ...theme.typography.app.body,
    backgroundColor: 'white',
    borderRadius: 2,
    '& h2': {
      ...theme.typography.app.h2,
      marginTop: 0,
      marginBottom: 20,
    },
  },
  signUpForm: {},

  signUpButton: {
    ...theme.formButtons,
    ...theme.formButtonPrimary,
  },
  loginButton: {
    ...theme.formButtons,
    ...theme.formButtonPrimary,
  },
});

export interface LoginPanelProps {
  className?: string;
  onLogin: (input: { userName: string; password: string }) => void;
  authenticationError: string;
}

const LoginPanel = ({ className, onLogin, authenticationError }: LoginPanelProps) => {
  const styles = useStyles();

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    onLogin({ userName, password });
  };

  return (
    <div className={classNames(styles.root, 'login-panel', className)}>
      <div className="nav">
        <div id="branding" className={styles.appBranding}>
          <Link to="/welcome">
            <NoteblLogo size="navBig" />
          </Link>
        </div>

        <form className={classNames(styles.form, styles.loginForm)} onSubmit={onSubmit}>
          <h2>Login</h2>
          <div className={styles.formField}>
            <label htmlFor="loginEmail">Email</label>
            <input
              type="text"
              name="email"
              id="loginEmail"
              value={userName}
              onChange={(i) => setUserName(i.target.value)}
            />
          </div>
          <div className={styles.formField}>
            <label htmlFor="loginPassword">Password </label>
            <input
              type="password"
              name="password"
              id="loginPassword"
              value={password}
              onChange={(i) => setPassword(i.target.value)}
            />
          </div>
          <button className={styles.loginButton}>Login</button>
          {authenticationError && <div>{authenticationError}</div>}
        </form>

        {/* <form className={classNames(styles.form, styles.signUpForm)}>
          <h2>Don't have an account?</h2>
          <div className={styles.formField}>
            <label htmlFor="signUpEmail">Let's create you a new account</label>
            <input type="text" id="signUpEmail" placeholder="your@email.com" />
          </div>
          <Link to="/notebooks">
            <button className={styles.signUpButton}>Sign Up</button>
          </Link>
        </form> */}
      </div>
      <div id="nav-bottom"></div>
    </div>
  );
};

export default memo(LoginPanel);
