import _ from "lodash";

declare module "lodash" {
  interface LoDashStatic {
    swap<TValue>(array: Array<TValue>, index1: number, index2: number): TValue[];
    toStringify(input: unknown): string;
    toBoolean(input: unknown): boolean;
    isExisty(input: unknown): boolean;
    isHexColor(input: unknown): boolean;
  }
  interface LoDashExplicitWrapper<TValue> {
    swap(index1: number, index2: number): LoDashExplicitWrapper<TValue>;
    toStringify(input: unknown): LoDashExplicitWrapper<string>;
    toBoolean(input: unknown): LoDashExplicitWrapper<boolean>;
    isExisty(input: unknown): LoDashExplicitWrapper<boolean>;
    isHexColor(input: unknown): LoDashExplicitWrapper<boolean>;
  }
  interface LoDashImplicitWrapper<TValue> {
    swap(index1: number, index2: number): LoDashImplicitWrapper<TValue>;
    toStringify(input: unknown): LoDashImplicitWrapper<string>;
    toBoolean(input: unknown): LoDashImplicitWrapper<boolean>;
    isExisty(input: unknown): LoDashImplicitWrapper<boolean>;
    isHexColor(input: unknown): LoDashImplicitWrapper<boolean>;
  }
}

const swap = (array: Array<any>, index1: number, index2: number) => {
  let temp = array[index1];
  array[index1] = array[index2];
  array[index2] = temp;
  return array;
};

const toStringify = (input: unknown) => {
  return _.isObject(input) ? JSON.stringify(input) : input === null || input === undefined ? '' : input.toString();
};

const toBoolean = (input: unknown) => {
  return input ? true : false;
}

const isExisty = (input: unknown) => {
  return input === null || input === undefined ? false : true;
}

const isHexColor = (input: unknown) => {
  if (typeof input !== 'number' && typeof input !== 'string') {
    return false;
  }
  return /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/.test(input.toString());
}

_.mixin({
  swap,
  toStringify,
  toBoolean,
  isExisty,
  isHexColor,
});