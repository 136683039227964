import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Notebook from '../core/Notebook';
import AppPreloader from '../components/AppPreloader';
import Note from '../core/Note';
import { EventBus } from '../transformations2/eventBus';
import NoteActions from '../core/NoteActions';
import { NotebookContext } from '../NotebookContext';
import { If, Then, Else } from 'react-if';
// import NoteViewRead2 from '../components/NoteViewRead2';
// import NoteViewSlides2 from '../components/NoteViewSlides2';
// import NoteViewOutline, { NoteViewOutlineRef } from '../components/NoteViewOutline';
// import NotebookActions from '../components/NotebookActions';
import { initializeShareableNotebook } from '../store/notebook/initializeShareableNotebook';
// import { useHistory } from 'react-router-dom';
import { StoreState } from '../store/storeFactory';
import NotebookView from '../components/NotebookView';
import { isUndefined } from 'lodash';

const useStyles = makeStyles({
  root: {
    minWidth: 300,
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
  },
  panel: {
    width: '100%',
    overflowY: 'scroll',
  },
  outlinePanel: {
    maxWidth: 800,
  },
  notebookContainer: {},
  pageHeader: {
    '& > .notebook-actions': {
      background: 'none',
    },
    '&:hover > .notebook-actions': {
      backgroundColor: 'white',
    },
  },
  navigation: {
    top: 0,
    left: 0,
    display: 'flex',
    zIndex: 1,
    position: 'fixed',
    width: 'auto',
    boxShadow: '0 0px 10px 1px rgba(0,0,0,0.2)',
    borderRadius: '0 0 10px 0',
    '&:before': {
      content: null,
    },
  },
});

type ShareableNotePageProps = {
  linkId: string;
  noteId: string;
  archived: boolean;
  notebook: Notebook;
  renderFromNote: Note;
  baseUrl: string;
  userName?: string;
  loading: boolean;
  loadShareableNotebook: (linkId: string, noteId: string) => void;
};

const ShareableNotePage = (props: ShareableNotePageProps) => {
  const { loadShareableNotebook, noteId, baseUrl, userName } = props;

  const authenticated = !isUndefined(userName);
  const archived = !isUndefined(props.archived) && props.archived;

  useEffect(() => {
    loadShareableNotebook(props.linkId, props.noteId);
    console.log('loadShareableNotebook effect');
  }, [loadShareableNotebook, props.linkId, props.noteId]);

  const styles = useStyles();

  console.log('ShareableNotePage', props);

  const renderNotebookView = () => {
    const eventBus = new EventBus();

    NoteActions.traverseDown(props.renderFromNote, (note) => note).forEach((note) => eventBus.init(note));
    const renderFromNote: Note = props.renderFromNote.getDescendentById(noteId) || props.renderFromNote;

    return (
      <If condition={archived}>
        <Then>
          <div>This link was archived and is no longer accessible.</div>
        </Then>
        <Else>
          <NotebookContext.Provider value={{ eventBus }}>
            <NotebookView
              notebook={props.notebook}
              linkId={props.linkId}
              mode="read"
              page={baseUrl}
              embedded={true}
              authenticated={authenticated}
              renderFromNote={renderFromNote}
            />
          </NotebookContext.Provider>
        </Else>
      </If>
    );
  };

  const renderLinkNotFound = () => {
    return <div>Notebook not found</div>;
  };

  return (
    <div className={classNames('full-screen', styles.root)}>
      <div className={classNames('notebook-container', styles.notebookContainer)}>
        {props.loading ? <AppPreloader /> : !props.notebook ? renderLinkNotFound() : renderNotebookView()}
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState, ownProps) => {
  const { notebook } = state;
  const linkId = ownProps.linkId || ownProps.match.params.linkId;
  const noteId = ownProps.noteId || ownProps.match.params.noteId;
  return {
    linkId: linkId,
    noteId: noteId,
    baseUrl: ownProps.baseUrl,
    userName: ownProps.userName,
    notebook: notebook.notebook,
    renderFromNote: notebook.renderFromNote,
    loading: notebook.loading,
    archived: notebook.archived,
  };
};

const mapDispatchToProps = (dispatch) => {
  console.log('mapDispatchToProps', dispatch);
  return {
    loadShareableNotebook: (linkId, noteId) => dispatch(initializeShareableNotebook(linkId, noteId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareableNotePage);
