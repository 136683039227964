import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import BulletIcon from './shared/icons/BulletIcon';
import { theme } from '../shared/theme';
import _ from 'lodash';

const useStyles = makeStyles({
  root: {
    minWidth: 20,
    minHeight: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.15s linear',

    ...theme.colors.btnIcon,
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      ...theme.colors.btnIconHover,
      backgroundColor: 'inherit',
    },
  },
  inactive: {
    ...theme.colors.btnIconDisabled,
    backgroundColor: 'transparent',
    '&:hover': {
      ...theme.colors.btnIconDisabled,
    },
  },
});

export interface BulletProps {
  onClick?: (event: any) => void;
  className?: string;
}

const Bullet = ({ className, onClick }: BulletProps) => {
  const styles = useStyles();
  return (
    <div
      className={classNames(styles.root, className, !_.toBoolean(onClick) && styles.inactive)}

      // For some reason, onClick does not fire on virtual notes in the first time.
      // It's something to do with onBlur in <ContentEditable>, but can't figure out exactly.
      // So I am changing it to onMouseUp.
      onMouseUp={onClick}
    >
      <BulletIcon />
    </div>
  );
};

export default memo(Bullet);
