import Note from "../../core/Note";
import { NotebookAction } from "./actions";
import { ActionType } from "./actionType";
import _ from "lodash";
import { NotebookState } from "./state";

export const removeShareableLinkAction = (note: Note, linkId: string) => {
    return async (dispatch, getState, firebase: firebase.app.App) => {

        const { notebook } = getState() as { notebook: NotebookState };

        note.shareableLinks = _(note.shareableLinks).filter(i => i.id != linkId).value();

        const firestore = firebase.firestore();
        await firestore.collection("ShareableLinks").doc(linkId).delete();

        const copy: any = {...notebook.notebook};
        const readyAction: Partial<NotebookAction> = {
            type: ActionType.UPDATE_NOTEBOOK,
            notebook: copy,
        };

        dispatch(readyAction);
    };
  };