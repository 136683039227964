import React from 'react';
import _ from 'lodash';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  LineChart,
  Line,
  ReferenceLine,
  LabelProps,
} from 'recharts';

import helpers from '../transformations/shared/helpers';
import { getRows, getDataFromParams, existAndEqual, isDataValid, isParams } from '../transformations/shared/utils';
import moment from 'moment';
import { FormaterProps } from './FormatterFactory2';

const DEFAULT_COLOR = '#568EC7';

const LinechartFormatter = (props: FormaterProps) => {
  const params = props.note.formatter.params;

  // https://lodash.com/docs/4.17.15#get
  // path is "The path of the property to get"
  const path = params.path;
  const dataSource = params.data ?? props.unit.data;
  const data = path ? _.get(dataSource, path) : dataSource;

  const { valid: dataValid, message: dataErrorMessage } = isDataValid(data);
  if (!dataValid) {
    return <div className="chart error">{dataErrorMessage}</div>;
  }

  const { valid: paramsValid, message: paramsErrorMessage } = isParams(params);
  if (!paramsValid) {
    return <div className="error">{paramsErrorMessage}</div>;
  }

  const _series0 = Array.isArray(params.series) && params.series.length > 0 ? params.series[0] : {};
  const _category = _series0.dataFields.category;
  const _value = _series0.dataFields.value;
  const _collectedRows = getRows(data, _category, _value);

  const color = _.isHexColor(params.color) ? params.color : DEFAULT_COLOR;

  const _maxValue = _.maxBy(_collectedRows, (x) => x[_value])[_value];
  const _minValue = _.minBy(_collectedRows, (x) => x[_value])[_value];
  const _firstCategory = _collectedRows[0][_category];

  return (
    <div className="chart rechart line-chart">
      {!!params.title && <h2>{params.title}</h2>}
      <ResponsiveContainer minHeight={150} aspect={3} debounce={1}>
        <LineChart height={150} data={_collectedRows} margin={{ top: 15, right: 30, bottom: 10, left: 30 }}>
          {existAndEqual(params.showGrid, 'yes') && <CartesianGrid vertical={false} strokeDasharray="3 3" />}
          {existAndEqual(params.showGrid, 'yes') && <YAxis type="number" axisLine={false} orientation="right" />}
          <XAxis
            dataKey={_category}
            axisLine={false}
            minTickGap={4}
            interval={'preserveStartEnd'}
            height={14}
            tickFormatter={(value) => {
              const m = moment(value);
              if (m.isValid()) {
                const _moment = helpers.parseDate(value);
                return helpers.formatMoment(_moment, 'D');
              } else return value;
            }}
          />
          <XAxis
            dataKey={_category}
            axisLine={false}
            minTickGap={0}
            xAxisId={'month'}
            tickLine={false}
            interval={0}
            height={20}
            tickFormatter={(value) => {
              const m = moment(value);
              if (m.isValid()) {
                const _moment = helpers.parseDate(value);
                return helpers.formatMoment(_moment, 'D') === '1' || value === _firstCategory
                  ? helpers.formatMoment(_moment, 'MMM')
                  : '';
              } else return value;
            }}
          />
          <Tooltip
            formatter={(value) => helpers.formatNumber(value, params.valueFormat)}
            // formatter={(value, name, props) => ( return ["formatted value", "formatted name"， ] )}
          />
          <ReferenceLine x={_collectedRows[0][_category]} stroke="#dddddd" />
          <ReferenceLine x={_collectedRows[_collectedRows.length - 1][_category]} stroke="#dddddd" />
          <Line dataKey={_value} type="monotone" stroke={color} strokeWidth={2}>
            <LabelList
              dataKey={_value}
              formatter={(value) => helpers.formatNumber(value, params.valueFormat)}
              position="top"
              content={(props: LabelProps) => {
                return props.value === _maxValue
                  ? '▲' + props.formatter(props.value)
                  : props.value === _minValue
                  ? '▼' + props.formatter(props.value)
                  : props['index'] === 0 || props['index'] === _collectedRows.length - 1
                  ? props.formatter(props.value)
                  : '';
              }}
            />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LinechartFormatter;
