import React from 'react';
import { FormaterProps } from './FormatterFactory2';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { theme } from '../shared/theme';

const useStyles = makeStyles({
  linkButton: {
    border: 'none',
    padding: 0,
    color: theme.colors.linkColor,
    background: 'none',
    textDecoration: 'underline',
    '&:hover':{
      background: 'none',
      textDecoration: 'none',  
    }
  }
});

const LinkTo = (props: FormaterProps) => {

  const linkId:string = props.note.formatter.params?.linkId;
  const cleanLinkId:string = _.replace(linkId, 'https://notebl.com/link/', '');

  const history = useHistory();
  const zoomNote = () => {
    history.push(`/link/${cleanLinkId}`);
  };

  const styles = useStyles();

  if (!linkId) {
    return <div className="error">Add "linkId" in format parameters</div>;
  }

  return (
    <button onClick={zoomNote} className={styles.linkButton}>{props.unit.data}</button>
  )
};

LinkTo.description = () => 'Make a note a clickable link to another note for navigation';

export default LinkTo;
