import firebase from 'firebase';
import { StoreState } from '../storeFactory';
import { toast } from 'react-toastify';
import { ActionType } from '../notebook/actionType';
import { EmptifyNotebookAction } from '../notebook/actions';

export const saveRawNotebookAction = (data: any) => {
    return async (dispatch, getState, firebase: firebase.app.App) => {

        const { rawNotebook } = getState() as StoreState;

        const firestore = firebase.firestore();
        await firestore
          .collection('notebooks')
          .doc(rawNotebook.notebookId)
          .update(data);

        const readyAction: EmptifyNotebookAction = {
            type: ActionType.EMPTY_STATE,
            notebookId: rawNotebook.notebookId,
        };
        dispatch(readyAction);
    
        toast.success('Notebook saved');
    };
};