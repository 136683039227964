import Note from "../../core/Note";
import { ActionType } from "./actionType";
import { NotebookState } from "./state";
import { NotebookAction } from "./actions";
import * as firebase from "firebase";
import _ from "lodash";
import { Invitation } from "../../services/Invitation";

export const updateInvitationAction = (note: Note, invitation: Invitation, permission: 'view' | 'edit') => {
    return async (dispatch, getState) => {

        const { notebook } = getState() as { notebook: NotebookState };

        const firestore = firebase.firestore();
        const notebookList = firestore.collection('notebookList');

        const sharedNotebooks = await notebookList
            .where("notebookId", "==", notebook.notebookId)
            .where("sharedTo.email", "==", invitation.email)
            .get()

        const docId = sharedNotebooks.docs[0].id;

        await notebookList.doc(docId).update({
            "sharedTo.permission": permission
        })

        invitation.permission = permission;

        const copy: any = {...notebook.notebook};
        const readyAction: Partial<NotebookAction> = {
            type: ActionType.UPDATE_NOTEBOOK,
            notebook: copy,
        };

        dispatch(readyAction);
    };
  };