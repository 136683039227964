import React, { useState, useEffect, createRef, forwardRef, useImperativeHandle, useRef } from 'react';
import _, { map, chain, findIndex, toStringify } from 'lodash';
import '../utils/lodash-mixins';
import Note from '../core/Note';
import Notebook from '../core/Notebook';
import { If } from 'react-if';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import ReferenceSuggestion from './ReferenceSuggestion';
import NoteActions from '../core/NoteActions';
import { getCursorXY } from './NoteEditable.getPosition';
import ContentEditable from './react-contenteditable';
import { FormatterFactory2 } from '../formaters/FormatterFactory2';
import {CommandActionType, CommandParams} from "./NotebookView.onCommand";

const useStyles = makeStyles({
  contentEditable: {
    '& ins': {
      background:  '#f6e7f9',
      borderRadius: 5,
      textDecoration: 'none',
      paddingLeft: 5,
      paddingRight: 5,
      boxShadow: '0 0 0 2px #f6e7f9',
      marginRight: '0.5em',
    },
    '& var': {
      background: '#d8d4f5',
      borderRadius: 5,
      textDecoration: 'none',
      paddingLeft: 5,
      paddingRight: 5,
    },
    '& mark': {
      background: 'white',
      borderRadius: 5,
      textDecoration: 'none',
      paddingLeft: 5,
      paddingRight: 5,
      marginLeft: '0.3em',
      marginRight: '0.3em',
    },
    '& .transformation-editor':{
      // background: 'white',
      // border: '1px solid rgba(0,0,0,0.1)',
      // padding: '4px 6px',
      marginTop: '0.5em',
      // marginLeft: -6,
      fontSize: 13,
      color: '#555555',
    }
  }
})
 

interface NoteEditableProps {
  note: Note;
  isEditing: boolean;
  notebook: Notebook;
  className?: string;
  onSourceUpdate: (source: string) => void;
  insertSuggestionReference: (params: {
    startOffset: number;
    currentOffset: number;
    note: Note,
    updateCursorPosition: boolean
  }) => void;
  onFocus: () => void;
  onBlur: () => void;
  onCommand: (params: CommandParams) => void;
}

export type NoteEditableState = {
  visible: boolean;
  startOffset: number;
  items: { active: boolean; note: Note }[];
  currentOffset: number;
};

export interface NoteEditableRef {
  focus: (offset: number) => void;
}

const NoteEditable = (props: NoteEditableProps, ref) => {
  const [state, setState] = useState<NoteEditableState>({
    visible: false,
    startOffset: -1,
    currentOffset: 0,
    items: [],
  });

  const reference = _.isEmpty(ref) ? createRef() : ref;
  useImperativeHandle(reference, () => {
    const apiRef = {
      focus: focusNote,
    };
    return apiRef as NoteEditableRef;
  });

  const focusNote = (offset: number) => {
    const el = props.note.domRef;
    el.focus();
    const range = document.createRange();
    const sel = window.getSelection();
    sel.removeAllRanges();
    const contextLength = el.childNodes[0]?.textContent?.length;
    range.setEnd(el.childNodes[0] || el, Math.min(offset, contextLength));
    range.collapse(false);
    sel.addRange(range);
  };

  const shouldToggleSuggestionOn = (event: React.KeyboardEvent<HTMLDivElement>): boolean => {
    if (event.key === '=') {
      const target = event.target as HTMLDivElement;
      const value = target.innerText;
      const currentOffset = window.getSelection().getRangeAt(0).startOffset;
      const previousChar = value.charAt(currentOffset - 2);
      return previousChar.trim() === '';
    }
  };

  const getSuggestionItems = (term: string = null, activeIndex: number = 0) => {
    const notes = NoteActions.traverseDown(props.note.root, (note) => note);
    return chain(notes)
      .filter((note) => note.id !== props.note.id)
      .filter((note) => !!note.name)
      .filter((name) => !term || name.name.toLowerCase().startsWith(term.toLowerCase()))
      .map((note, index) => ({ note: note, active: index === activeIndex }))
      .value();
  };

  const processEditableKeystroke = (event: React.KeyboardEvent<HTMLDivElement>): CommandParams => {
    const cursorPosition = window.getSelection().getRangeAt(0).startOffset;
    const target = event.target as HTMLDivElement;

    const map = {
      ArrowDown: () => (event.ctrlKey ? CommandActionType.CTRL_ARROW_DOWN : CommandActionType.ARROW_DOWN),
      ArrowUp: () => (event.ctrlKey ? CommandActionType.CTRL_ARROW_UP : CommandActionType.ARROW_UP),
      Enter: () => CommandActionType.ENTER,
      Tab: () => (event.shiftKey ? CommandActionType.OUTDENT : CommandActionType.INDENT),
      Backspace: () => cursorPosition === 0 && CommandActionType.BACKSPACE,
      Delete: () => cursorPosition === target.innerText.length && CommandActionType.DELETE,
      ArrowLeft: () => (event.altKey ? CommandActionType.ALT_ARROW_LEFT : cursorPosition === 0 && CommandActionType.ARROW_LEFT),
      ArrowRight: () => (event.altKey ? CommandActionType.ALT_ARROW_RIGHT : cursorPosition === target.innerText.length && CommandActionType.ARROW_RIGHT),
    };

    const result = (func) => {
      const action = func && func();

      return (
        action && {
          action: action,
          note: props.note,
          offset: cursorPosition,
        }
      );
    };

    return result(map[event.key]);
  };

  const updateReferenceSuggestion = (event: React.KeyboardEvent<HTMLDivElement>): Partial<NoteEditableState> => {
    const currentOffset = window.getSelection().getRangeAt(0).startOffset;

    if (event.which === 35 || event.which === 27 || event.which === 32) return { visible: false };

    if (event.which === 38) {
      event.preventDefault();
      return toggleActive(stateRef.current, 'UP');
    }

    if (event.which === 40) {
      event.preventDefault();
      return toggleActive(stateRef.current, 'DOWN');
    }

    if (event.which === 8 || event.which === 37 || event.which === 39)
      if (currentOffset < stateRef.current.startOffset) return { visible: false };
  };

  const toggleActive = (state: NoteEditableState, direction: 'UP' | 'DOWN'): Partial<NoteEditableState> => {
    const activeIndex = findIndex(state.items, (i) => i.active);
    let newIndex = activeIndex + (direction === 'UP' ? -1 : 1);

    if (newIndex === -1) newIndex = state.items.length - 1;
    if (newIndex === state.items.length) newIndex = 0;
    return {
      items: map(state.items, (i, index) => ({
        ...i,
        active: index === newIndex,
      })),
    };
  };

  useEffect(() => {
    if (state.visible) {
      const { x } = getCursorXY(props.note.domRef, state.currentOffset);
      referenceSuggestionRef.current.setAttribute('style', `left: ${x - 20}px; top: 32px`);
    }
  }, [state.visible, state.currentOffset, props.note.domRef]);

  const stateRef = useRef(state);
  stateRef.current = state;

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (stateRef.current.visible) {
      if (event.which === 13) {
        event.preventDefault();

        const activeItem = _(stateRef.current.items)
          .filter((i) => i.active)
          .head();

        if (activeItem) {
          props.insertSuggestionReference({
            startOffset: stateRef.current.startOffset,
            currentOffset: stateRef.current.currentOffset,
            note: activeItem.note,
            updateCursorPosition: true,
          });
        }
        setState((prevState) => ({ ...prevState, visible: false }));
      } else {
        const newState = updateReferenceSuggestion(event);
        if (newState) {
          setState((prevState) => ({ ...prevState, ...newState }));
        }
      }
    } else {
      const command = processEditableKeystroke(event);
      if (command) {
        props.onCommand(command);
        event.preventDefault();
      }
    }
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const currentOffset = window.getSelection().getRangeAt(0).startOffset;

    if (shouldToggleSuggestionOn(event)) {
      setState((prevState) => ({
        ...prevState,
        startOffset: currentOffset,
        currentOffset: currentOffset,
        visible: true,
        items: getSuggestionItems(),
      }));
    } else if (stateRef.current.visible) {
      const value = (event.target as any).innerText;
      const term = value.slice(stateRef.current.startOffset, currentOffset);
      const activeIndex = findIndex(stateRef.current.items, (i) => i.active);

      setState((prevState) => ({
        ...prevState,
        currentOffset: currentOffset,
        items: getSuggestionItems(term, activeIndex),
        visible: true,
      }));
    }
  };

  function suggestionSelected(note: Note) {

    props.insertSuggestionReference({
      startOffset: stateRef.current.startOffset,
      currentOffset: stateRef.current.currentOffset,
      note: note,
      updateCursorPosition: false,
    });
    console.log(stateRef.current);
    
    setState((prevState) => ({ ...prevState, visible: false }));
  }

  const referenceSuggestionRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const clickOutside = (event) => {
      if (!referenceSuggestionRef.current?.contains(event.target)) {
        setState((prevState) => ({ ...prevState, visible: false }));
      }
    };
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  });

  const disabled = !!props.note.virtualOf;

  const styles = useStyles();
  
  return (
    <>
      <ContentEditable
        ref={(r) => (props.note.domRef = r && r.el.current)}
        onChange={(e) => props.onSourceUpdate(e.target.value)}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        html={toStringify(props.note.source)}
        useChildren={!props.isEditing}
        className={classNames(props.className, styles.contentEditable)}
        // disabled={disabled}
      >
        {!props.isEditing && <FormatterFactory2 note={props.note} />}
      </ContentEditable>
      <If condition={state.visible}>
        <ReferenceSuggestion items={state.items} itemSelected={suggestionSelected} ref={referenceSuggestionRef} />
      </If>
    </>
  );
};

export default forwardRef(NoteEditable);
