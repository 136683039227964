import React, { memo } from 'react';
import { When } from 'react-if';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import CogIcon from './shared/icons/CogIcon';
import CloseIcon from './shared/icons/CloseIcon';
import { theme } from '../shared/theme';
import NoteSettingsEditor from './NoteSettingsEditor';
import ControlButton from './shared/controls/ControlButton';
import Note from '../core/Note';
import { CommandParams } from './NotebookView.onCommand';

const useStyles = makeStyles({
  root: {
    padding: 10,
    display: 'flex',
    flexFlow: 'column nowrap',
    boxSizing: 'border-box',
    position: 'absolute',
    right: 0,
  },
  panelExpanded: {
    position: 'relative',
    flex: `0 0 ${theme.notebookSettingsPanelWidth}px`,
    maxWidth: theme.notebookSettingsPanelWidth,
    height: '100%',
    overflowY: 'scroll',
  },
  panelHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginBottom: theme.basicVerticalSpacing,
  },
  panelToggle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
    '& > button': {
      ...theme.iconButton,
      position: 'relative',
      color: theme.colors.lightBgTextSecondary,
      '& > svg + label': {
        marginLeft: 5,
      },
      '&.selected': {
        color: theme.colors.lightBgText,
        cursor: 'default',
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
  },
  panelCloseControls: {
    flex: 0,
  },
  primaryNoteEditor: {
    paddingBottom: theme.basicVerticalSpacing * 2,
    '&:after': {
      borderColor: theme.colors.noteSettingsDivider,
    },
  },
  settingsSectionTitle: {
    ...theme.typography.app.body,
    color: theme.colors.lightBgText,
  },
});

interface NotebookSidePanelProps {
  note: Note;
  updateNote: (note: Note) => void;
  showSidePanel: boolean;
  isRoot: boolean;
  zoomNote: (note: Note) => void;
  toggleSidePanel: () => void;
  layoutRenderSelected: ({ name, params }: { name?: string; params?: any }) => void;
  formatterSelected: ({ name, params }: { name?: string; params?: any }) => void;
  onCommand: (command: CommandParams) => void;
}

const NotebookSidePanel = (props: NotebookSidePanelProps) => {
  const styles = useStyles();
  const { note, showSidePanel, toggleSidePanel, zoomNote, updateNote } = props;

  return (
    <div className={classNames(styles.root, 'notebook-side-panel', showSidePanel && styles.panelExpanded)}>
      <div className={styles.panelHeader}>
        <When condition={showSidePanel}>
          <div className={styles.panelToggle}>
          <div className={styles.settingsSectionTitle}>Note Settings</div>
          </div>
          <div className={styles.panelCloseControls}>
            <ControlButton icon={<CloseIcon />} onClick={toggleSidePanel} />
          </div>
        </When>
      </div>
      {note.id !== null && (
        <>
          <NoteSettingsEditor
            note={note}
            className={styles.primaryNoteEditor}
            zoomNote={zoomNote}
            updateNote={updateNote}
            formatterSelected={props.formatterSelected}
            layoutRenderSelected={props.layoutRenderSelected}
            onCommand={props.onCommand}
          />
        </>
      )}
    </div>
  );
};

export default NotebookSidePanel;
