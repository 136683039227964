import Note from '../core/Note';
import React from 'react';
import NoteControls from '../components/NoteControls';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import classNames from 'classnames';
import { theme } from '../shared/theme';
import _ from 'lodash';
import { If } from 'react-if';
import { FormatterFactory2 } from '../formaters/FormatterFactory2';
import { LayoutRenderFactory2 } from './LayoutRenderFactory2';
import Bullet from '../components/Bullet';

const useStyles = makeStyles<Theme, BulletedListStyleProps>((materialTheme: Theme) => ({
  note: (props) => ({
    display: 'flex',
    flexFlow: 'column',
    position: 'relative',
    // color: '#4449a7',
    // color: theme.colors.lightBgText,

    '&.top-note.note': {
      padding: 10,
      paddingLeft: 60,
      paddingTop: 40,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      '& > .note-self': {
        position: 'relative',
        maxWidth: 800,
        width: '100%',
      },
      '& > .note-children': {
        position: 'relative',
        maxWidth: 800,
        width: '100%',
      },
      '&:not(.leaf) > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h1,
          marginTop: 0,
          paddingLeft: 0,
        },
        '& > .note-actions': {
          top: 7,
        },
      },
    },
    '& > .note-self': {
      paddingLeft: props.level ? props.level * 20 : 0,
      '&:hover > .note-actions': {
        visibility: 'visible',
      },
    },
    '&.level-1:not(.leaf):not(.bulleted-list-child)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h2,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 5,
        },
      },
    },
    '&.level-2:not(.leaf):not(.bulleted-list-child)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h3,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 3,
        },
      },
    },
  }),
  noteSelf: {
    display: 'flex',
    position: 'relative',
  },
  noteValue: {
    padding: '5px 0',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    lineHeight: '140%',
  },
  noteControls: {
    position: 'absolute',
    left: -40,
    visibility: 'hidden',
  },
  noteChildren: {
    paddingLeft: 0,
  },
}));

export interface BulletedListStyleProps {
  level?: number;
}

const BulletedListReadRenderer = (props: LayoutRenderProps) => {
  const { note, collapseNote, className } = props;

  const index = note.parent ? _.findIndex(note.parent.children, { id: note.id }) : 'top';
  const absoluteLevel = note.absoluteLevel();
  const displayLevel = note.displayLevelFrom(props.topNote);
  const displayLevelClassName = _.isNull(displayLevel) ? '' : 'level-' + displayLevel;


  const bulletedListStartLevel = props.note.layoutRender.params?.level || absoluteLevel;
  const listLevel = absoluteLevel - bulletedListStartLevel;

  note.children.forEach((child: Note) => {
    if(!child.layoutRender.name){
      child.layoutRender = { name: 'BulletedList', params: {template: "BulletedListChild", level: bulletedListStartLevel} };
    }
  });

  const showBullet = props.note.layoutRender.params?.template === "BulletedListChild";
  const styleProps:BulletedListStyleProps = {level: listLevel};

  const styles = useStyles(styleProps);

  return (
    <div
      className={classNames(
        'note', 'bulleted-list-renderer',
        `index-${index}`,
        `absLevel-${absoluteLevel}`,
        note.isLeaf() && 'leaf',
        displayLevelClassName,
        note === props.topNote && 'top-note',
        showBullet && 'bulleted-list-child',
        listLevel && `bulleted-list-level-${listLevel}`,
        styles.note,
        className
      )}
      key={note.id}
    >
      <div className={classNames('note-self', styles.noteSelf)}>
        <NoteControls
          onCollapseClick={(event) => collapseNote(note)}
          collapsible={!note.isLeaf()}
          onBulletClick={(event) => props.zoomNote(note)}
          closed={note.isChildrenCollapsed}
          className={classNames('note-actions', styles.noteControls)}
        />
          {showBullet && <Bullet className='bullet' />}
        <div className={classNames('note-value', styles.noteValue)}>
          <FormatterFactory2 note={props.note} />
        </div>
      </div>

      <If condition={!note.isChildrenCollapsed && !note.isLeaf()}>
        <div className={classNames('note-children', styles.noteChildren)}>
            {note.children.map((child: Note, idx) => {
              return (
                <LayoutRenderFactory2
                  mode="read"
                  key={child.id}
                  note={child}
                  topNote={props.topNote}
                  collapseNote={props.collapseNote}
                  zoomNote={props.zoomNote}
                />
              );
            })}
          </div>
      </If>
    </div>
  );
};

export default BulletedListReadRenderer;
