import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyCvLSspHCtEp5RbVCeQNqb6ad87Fbw-OSM',
  authDomain: 'notebl-814df.firebaseapp.com',
  databaseURL: 'https://notebl-814df.firebaseio.com',
  projectId: 'notebl-814df',
  storageBucket: 'notebl-814df.appspot.com',
  messagingSenderId: '706130018363',
  appId: '1:706130018363:web:74b973f868323f7278dc1e',
  measurementId: 'G-EGZM1BRQ0H',
};

export function runFirebaseApplication() {
  const app = firebase.initializeApp(firebaseConfig);

  // local testing with emulator
  // firebase.firestore().settings({
  //   host: 'localhost:8080',
  //   ssl: false,
  // });
  // firebase.functions().useFunctionsEmulator('http://localhost:5001');

  return app;
}
