import React, { useCallback, useState } from 'react';
import _ from 'lodash';

import { ShareableLink } from '../services/ShareableLink';
import { CheckBox } from './shared/controls/CheckBox';
import { If, Then, Else } from 'react-if';
import ControlButton from './shared/controls/ControlButton';
import classNames from 'classnames';
import { Collapsible } from './Collapsible';
import { Invitation } from '../services/Invitation';

interface ShareableLinkEditorProps {
  styles;
  shareableLink: ShareableLink;
  sendInvitation: (invitation: Invitation) => void;
  removeInvitation: (invitation: Invitation) => void;
  updateInvitation: (invitation: Invitation, permission: 'view' | 'edit') => void;
  togglePrivate: (_private: boolean) => void;
  unshare: () => void;
  className?: string;
}

const ShareableLinkEditor = (props: ShareableLinkEditorProps) => {
  const [state, setState] = useState({
    private: props.shareableLink.private,
    email: '',
    permission: 'view' as 'view' | 'edit',
  });

  const [invitedUsersOpen, setInvitedUsersOpen] = useState(true);

  const toggleTransformations = useCallback(() => {
    setInvitedUsersOpen(!invitedUsersOpen);
  }, [invitedUsersOpen]);

  const handleLinkCopy = useCallback((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const el = event.target as HTMLInputElement;
    el.select();
    document.execCommand('copy');
  }, []);

  return (
    <div className={classNames(props.styles.settingsGroupRow, props.className)}>
      <div className={props.styles.settingsGroupRowProperty}>
        <div className={props.styles.sharingLink}>
          <div className={props.styles.sharingLinkInput}>
            <input readOnly value={`https://notebl.com/link/${props.shareableLink.id}`} onClick={handleLinkCopy} />
          </div>
        </div>
      </div>

      <div className={classNames(props.styles.settingsGroupRowPropertyLine, 'spread')}>
        <a href={`/link/${props.shareableLink.id}`} target="NoteblSharedLink">
          Test link in new tab
        </a>
        <ControlButton label="Unshare" onClick={() => props.unshare()} />
      </div>

      <div className={props.styles.settingsGroupRowProperty}>
        <CheckBox
          isChecked={state.private}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.togglePrivate(e.currentTarget.checked);
            const next = { private: e.currentTarget.checked };
            setState((prev) => ({ ...prev, ...next }));
          }}
          label="Private Link"
        />
      </div>

      <If condition={state.private}>
        <Then>
          <div className={props.styles.settingsGroupDescription}>
            Only people you invite will be able to access the note via this link.
          </div>
          <div className={props.styles.settingsGroupPropertyName}>Invite People</div>

          <div className={props.styles.settingsGroupRowPropertyLine}>
            <input
              value={state.email}
              onChange={(e) => {
                const next = { email: e.currentTarget.value };
                setState((prev) => ({ ...prev, ...next }));
              }}
              className={props.styles.controlInputText}
              placeholder="Enter email to invite..."
            />

            <select
              value={state.permission}
              onChange={(e) => {
                const next = { permission: e.currentTarget.value as any };
                setState((prev) => ({ ...prev, ...next }));
              }}
              className={classNames(props.styles.controlInputSelect, props.styles.sharingPermissions)}
            >
              <option value="edit">edit</option>
              <option value="view">view</option>
            </select>
          </div>

          <div className={props.styles.settingsGroupRowProperty}>
            <ControlButton
              label="Send Invitation"
              disabled={state.email.trim().length === 0}
              onClick={() => {
                props.sendInvitation({
                  email: state.email,
                  permission: state.permission,
                });

                setState((prev) => ({ ...prev, email: '', permission: 'view' }));
              }}
              importance="secondary"
            />
          </div>

          <If condition={props.shareableLink.invitations.length > 0}>
            <Collapsible
              className={props.styles.transformationsList}
              onExpandCollapse={toggleTransformations}
              collapsed={!invitedUsersOpen}
              title={
                <div className={props.styles.settingsGroupTitleLabel}>
                  <span onClick={toggleTransformations} className={props.styles.settingsGroupTitleName}>
                    Invited
                  </span>
                </div>
              }
            >
              {props.shareableLink.invitations.map((i, index) => {
                return (
                  <div key={index} className={props.styles.settingsGroupRowPropertyLine}>
                    <div className={props.styles.settingsGroupRowPropertyLineValue}>{i.email}</div>
                    <select
                      value={i.permission}
                      onChange={(e) => {
                        const permission = e.currentTarget.value as 'edit' | 'view' | 'remove';

                        if (permission === 'remove') props.removeInvitation(i);
                        else props.updateInvitation(i, permission);
                      }}
                      className={classNames(props.styles.controlInputSelect, props.styles.sharingPermissions)}
                    >
                      <option value="edit">edit</option>
                      <option value="view">view</option>
                      <option value="remove">remove</option>
                    </select>
                  </div>
                );
              })}
            </Collapsible>
          </If>
        </Then>
        <Else>
          <div className={props.styles.settingsGroupDescription}>
            This link is public, anyone with this link can read or present this note and its descendants
          </div>
          <div className={props.styles.settingsGroupDescription}>
            Don't forget to save the notebook, to keep this link with a note.
          </div>
        </Else>
      </If>
    </div>
  );
};

export default ShareableLinkEditor;
