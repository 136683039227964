import classNames from "classnames";
import React from "react";
import { theme } from '../../../shared/theme';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
	controlInputCheckbox: {
		...theme.controlInputCheckbox,
	},

});
interface CheckBoxProps {
	isChecked?: boolean;
	className?: string;
	label: string;
	handleChange: (ev: React.ChangeEvent) => void;
}
export const CheckBox = (props: CheckBoxProps) => {
	const {
		isChecked,
		className,
		label,
		handleChange
	} = props;

	const styles = useStyles();
	return (
		<label className={classNames(styles.controlInputCheckbox, 'checkbox', className)}>
			<input
				type="checkbox"
				checked={!!isChecked}
				onChange={handleChange}
			/>
			{label}
		</label>
	);
};
