import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { history } from './history';
import { storeFactory } from './store/storeFactory';
import { onAuthStateChanged } from './store/application/actions';
import { runFirebaseApplication } from './services/firebase';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const firebase = runFirebaseApplication();
firebase.analytics();
const auth = firebase.auth();
auth.onAuthStateChanged((user) => store.dispatch(onAuthStateChanged(user)));

const store = storeFactory(firebase, history);

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
    <ToastContainer position="bottom-center" autoClose={3000} />
  </Provider>,
  document.getElementById('root')
);
