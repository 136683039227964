import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    '& >.panel': {
      flex: '1 1 auto',
    },
  },
  pageContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100vh',
    alignItems: 'center',
  },
  pageTitle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '10px 20px',
    height: 40,
    width: '100%',
    position: 'relative',
    ...theme.typography.app.body,
    color: theme.colors.lightBgTextSecondary,
    '&:before': {
      content: '""',
      width: 'calc(100% - 20px)',
      position: 'absolute',
      borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      bottom: 0,
      right: 0,
    },
  },
  pageTitleName: {},
  pageTitleActions: {
    marginLeft: 20,
  },
  page: {
    padding: '20px 20px 40px 20px',
    width: '100%',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    height: 'calc(100vh - 40px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '& > section': {
      padding: 20,
    },
    '& > section > h1': {
      // ...theme.typography.content.h1,
      ...theme.typography.app.h1,
      marginTop: 0,
    },
  },
});

interface PageContainerProps {
  pageTitle: string;
  children: React.ReactNode;
  pageActions?: React.ReactNode;
}

const PageContainer = ({ pageTitle, children, pageActions }: PageContainerProps) => {
  const styles = useStyles();
  return (
    <div className={classNames('panel', styles.root)}>
      <div className={classNames('page-container', styles.pageContainer)}>
        <div className={classNames('page-title', styles.pageTitle)}>
          <div className={styles.pageTitleName}>{pageTitle}</div>
          {pageActions && <div className={styles.pageTitleActions}>{pageActions}</div>}
        </div>
        <div className={classNames('page', styles.page)}>{children}</div>
      </div>
    </div>
  );
};

export default PageContainer;
