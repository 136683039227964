import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

// import notebookObj_b234 from '../api/notebook/b234.js';
// import NotebookView from '../components/NotebookView';
// import Notebook from '../core/Notebook';
// import { NotebookContext } from '../NotebookContext';
// import { EventBus } from '../transformations2/eventBus';
// import NoteActions from '../core/NoteActions';
import LoginPanel from '../components/LoginPanel';

const useStyles = makeStyles({
  loginPage: {
    padding: '40px 40px 0 40px',
    flex: '1 1 66%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'row nowrap',
    height: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '@media (max-width: 600px)': {
      padding: 0,
      overflowY: 'auto',
    },
  },
});

export interface LoginPageProps {
  login?: (input: { userName: string; password: string }) => void;
  authenticationError?: string;
  className?: string;
}

const LoginPage = (props: LoginPageProps) => {
  const styles = useStyles();

  // const notebook = new Notebook(notebookObj_b234 as NotebookData);
  // const eventBus = new EventBus();

  // NoteActions.traverseDown(notebook.root, (note) => note).forEach((note) => eventBus.init(note));

  console.log('login page props', props);

  return (
    <div className={styles.loginPage}>
      <LoginPanel onLogin={props.login} authenticationError={props.authenticationError} />
    </div>
  );
};

export default memo(LoginPage);
