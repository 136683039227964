import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import AppPanel from './AppPanel';
import LoginPanel from './LoginPanel';

import { theme } from '../shared/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '100vh',
    minHeight: '100vh',
    maxHeight: '100vh',
    backgroundColor: '#242648',
  },
  sidePanel: {
    flex: '0 0 200px',
    fontFamily: theme.fontFamily,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.darkBg,
    color: theme.colors.darkBgText,
    maxHeight: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '@media (max-width: 600px)': {
      display: 'none',
    },

    '& h1': {
      ...theme.typography.app.h1,
      color: theme.colors.darkBgTitle,
      fontWeight: 400,
    },
    '& h2': {
      ...theme.typography.app.h2,
      color: theme.colors.darkBgTitle,
      fontWeight: 400,
      marginBottom: 10,
    },
    '& > .nav': {
      width: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
    },
  },
  public: {},
});

export interface AppContainerProps {
  className?: string;
  userName: string;
  login?: (input: { userName: string; password: string }) => void;
  singOut?: () => void;
  authenticationError?: string;
  children: React.ReactElement;
  showNav?: boolean;
}

const AppContainer = (props: AppContainerProps) => {
  const { userName, children, className, singOut, showNav = true } = props;

  const styles = useStyles();

  return (
    <div className={classNames(styles.root, 'app', className, !userName && styles.public)}>
      {showNav && userName && <AppPanel className={styles.sidePanel} singOut={singOut} userName={userName} />}
      {React.cloneElement(children, props)}
    </div>
  );
};

export default memo(AppContainer);
