import React from 'react';
import _ from 'lodash';
// import helpers from '../transformations/shared/helpers';
import { getDataFromParams } from '../transformations/shared/utils';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { theme } from '../shared/theme';
import { FormaterProps } from './FormatterFactory2';

const useStyles = makeStyles({
  table: {
    display: 'table',
    width: '100%',
    overflowX: 'hidden',
    '& > table': {
      borderCollapse: 'collapse',
      '& > caption': {
        captionSide: 'top',
        width: 'auto',
        textAlign: 'left',
        ...theme.typography.content.figureTitle,
      },
      '& > thead > tr > th': {
        fontWeight: 700,
        paddingRight: 20,
        textAlign: 'left',
        paddingBottom: theme.basicVerticalSpacing,
        paddingTop: theme.basicVerticalSpacing,
      },
      '& > thead > tr:last-child > th': {
        borderBottom: `1px solid ${theme.colors.borderColor}`,
      },
      '& > tbody > tr:first-child > *': {
        paddingTop: theme.basicVerticalSpacing,
      },
      '& > tbody > tr > th': {
        paddingRight: 10,
        paddingLeft: 10,
        fontWeight: 700,
        textAlign: 'left',
        '&.index-column': {
          opacity: 0.3,
          fontWeight: 400,
        },
      },
      '& > tbody > tr > td': {
        paddingRight: 20,
        paddingTop: theme.basicVerticalSpacing / 2,
        paddingBottom: theme.basicVerticalSpacing / 2,
      },
      '& td.number-column, & th.number-column': {
        textAlign: 'right',
        paddingRight: 20,
      },
    },
  },
});

//

const TableFormatter2 = (props: FormaterProps) => {

  const params = props.note.formatter.params;

  const styles = useStyles();

  const path = props.note.formatter.params?.path;

  // https://lodash.com/docs/4.17.15#get
  // path is "The path of the property to get"
  const data = path ? _.get(props.unit.data, path) : props.unit.data;
  const columns = props.note.formatter.params?.columns;
  
  // console.log('Table Formatter for props', props, data, columns);

  if (!Array.isArray(data)) {
    return <div className="table-container error">Error: data is not an array</div>;
  }

  const showTitle = !_.isEmpty(params?.title) && _.isString(params?.title);
  const showIndex = !(_.isBoolean(params?.hideIndex) && params.hideIndex);

  // define columns:
  // if columns spec is given in params, use it
  // otherwise check if the data items are json, then make a collection of all possible keys
  // otherwise don't define columns and table header
  const collectedColumns =
    Array.isArray(columns) && columns.length > 0
      ? columns
      : _.isPlainObject(data[0])
      ? data.reduce((accumulator, currentValue) => _.union(accumulator, Object.keys(currentValue)), [])
      : undefined;

  const collectedColumnsTypeClasses = !!collectedColumns
    ? collectedColumns.map((val: string | number) =>
        !_.isEmpty(data[0]) && _.isNumber(data[0][val]) ? 'number-column' : null
      )
    : [];

  // console.log('collectedColumns & Types', collectedColumns, collectedColumnsTypeClasses);

  return (
    <div className={classNames('table-container', styles.table)}>
      <table>
        {showTitle && <caption>{params.title}</caption>}
        {collectedColumns && (
          <thead>
            <tr>
              {showIndex && <th></th>}
              {collectedColumns.map((value, index) => {
                return (
                  <th key={index} className={collectedColumnsTypeClasses[index]}>
                    {value}
                  </th>
                );
              })}
            </tr>
          </thead>
        )}
        <tbody>
          {data.map((currentValue, index1) => {
            return (
              <tr key={index1}>
                {showIndex && (
                  <th className="index-column" scope="row">
                    {' '}
                    {index1}
                  </th>
                )}
                {!!collectedColumns ? (
                  collectedColumns.map((val, index2) => (
                    <td key={index1 * 10000000 + index2} className={collectedColumnsTypeClasses[index2]}>
                      {!!currentValue[val] ? currentValue[val].toString() : 0}
                    </td>
                  ))
                ) : !collectedColumns && Array.isArray(currentValue) ? (
                  currentValue.map((val, index2) => (
                    <td key={index1 * 10000000 + index2} className={collectedColumnsTypeClasses[index2]}>
                      {!!val ? val.toString() : 0}
                    </td>
                  ))
                ) : (
                  <td key={index1 * 10000000 + 1}>{!!currentValue ? currentValue.toString() : 0}</td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
  // const params = props.params;

  // const referableValues = _({})
  //   .assign(props.note.inheritedReferences, props.note.references)
  //   .mapValues((note) => note.computedValue)
  //   .value();

  // const data = getDataFromParams(params, referableValues);

  // const _series0 = Array.isArray(params.series) && params.series.length > 0 ? params.series[0] : {};
  // const _category = _series0.dataFields.category;
  // const _value = _series0.dataFields.value;
  // const _collectedRows = getRows(data, _category, _value);

  // const color = _.isHexColor(params.color) ? params.color : DEFAULT_COLOR;
  // const categoryWidth = _.isNumber(params.categoryWidth) ? params.categoryWidth : DEFAULT_CATEGORY_WIDTH_ON_HORIZONTAL;
  // const minHeight = _.isNumber(params.minHeight) ? params.minHeight : DEFAULT_HORIZONTAL_BARCHART_HEIGHT;
  // const barSize = _.isNumber(params.barSize) ? params.barSize : undefined;

  // const _maxValue = _.maxBy(_collectedRows, (x) => x[_value])[_value];
  // const axisTickFormat = _maxValue < 10 ? '0.00' : _maxValue < 10000 ? '0,0' : '0a';

  // return (
  //   <div className="chart rechart bar-chart bar-chart-horizontal">
  //     {!!params.title && <h2>{params.title}</h2>}
  //     <ResponsiveContainer width="100%" minHeight={minHeight} debounce={1}>
  //       <BarChart
  //         width={500}
  //         height={500}
  //         data={_collectedRows}
  //         layout="vertical"
  //         margin={{ top: 5, right: 50, left: 5, bottom: 5 }}
  //       >
  //         <XAxis
  //           type="number"
  //           axisLine={false}
  //           tickFormatter={(value) => helpers.formatNumber(value, axisTickFormat)}
  //         />
  //         <YAxis type="category" tickLine={false} axisLine={false} dataKey={_category} width={categoryWidth} />
  //         <Tooltip formatter={(value) => helpers.formatNumber(value, params.valueFormat)} />
  //         <Bar dataKey={_value} fill={color} barSize={barSize} minPointSize={1}>
  //           <LabelList
  //             dataKey={_value}
  //             formatter={(value) => helpers.formatNumber(value, params.valueFormat)}
  //             position="right"
  //           />
  //         </Bar>
  //       </BarChart>
  //     </ResponsiveContainer>
  //   </div>
  // );
};

export default TableFormatter2;
