import firebase from 'firebase';
import * as NotebookActions from '../../core/NotebookActions';
import * as NoteActions from '../../core/NoteActions';
import Notebook from '../../core/Notebook';
import _ from 'lodash';
import paths from 'deepdash/paths';
import { toast } from 'react-toastify';
import { NotebookList } from '../../services/NotebookList';
import { NoteSummary } from '../../services/NoteSummary';

export const saveNotebookAction = () => {
  return async (dispatch, getState) => {
    const { notebook } = getState();
    const instance: Notebook = notebook.notebook;
    const serialized = NotebookActions.serialize(instance);
    const notebookjson = removeUndefinedvalues(serialized);

    const firestore = firebase.firestore();

    const noteSummary: NoteSummary = NoteActions.summarize(instance.root) as NoteSummary;

    const notebookList: Partial<NotebookList> = {
      lastUpdated: firebase.firestore.Timestamp.now(),
      ...noteSummary,
    };

    await firestore.collection('notebookList').doc(notebook.notebookId).set(notebookList, { merge: true });

    await firestore.collection('notebooks').doc(notebook.notebookId).update(notebookjson);

    toast.success('Notebook saved');
  };
};

function removeUndefinedvalues(obj) {
  const keys = paths(obj);
  const values = _(keys)
    .map((path: string) => {
      const value = _.get(obj, path);
      return { path, value };
    })
    .filter((i) => i.value !== undefined)
    .value();

  return _(values).reduce((acc, i) => _.set(acc, i.path, i.value), {});
}
