import csvParse from 'csv-parse/lib/sync';
import _ from 'lodash';
import helpers from '../transformations/shared/helpers';
import { Unit } from './eventBus';

export const ParseCSVTransformation = (unit: Unit, params) => {

  // example parameters JSON
  // "params": {
  //     "delimiter": "tab",
  //     "columns": true,
  //     "parsers": {
  //       "Date": "parseAndFormatDate",
  //       "Impressions": "parseNumber",
  //       "Watched100": "parseNumber",
  //       "Spend": "parseNumber",
  //     }
  //   }

  const csvString = unit.data;

  const delimiter = params.delimiter || "tab";
  const parsers = params.parsers || [];

  // Spec: https://csv.js.org/parse/options/columns/
  // columns === true => Infer the columns names from the first line.
  // typeof columns === 'array' => Declare the column definition before processing the data.
  const columns = Array.isArray(params.columns) ? params.columns : _.toBoolean(params.columns) ? true : undefined;

  const delimiterTypes = {
      'comma': ',',
      'tab': '	',
  };

  const parsersArray = params.parsers ? Object.keys(parsers) : [];    

  const reviver = (value, context) => {
      //https://csv.js.org/parse/options/cast/
      // console.log('Parsing csv rows', context, value);
      return context && context.column && parsers && parsers[context.column] && helpers[parsers[context.column]]
          ? helpers[parsers[context.column]](value)
          : value;
  };

  const recordsSkipper = (value, { lines }) => (parsersArray.length > 0 && lines && lines === 1 ? null : value);

  var csvObj = csvParse(csvString, {
      columns: columns,
      skip_empty_lines: true,
      delimiter: delimiterTypes[delimiter] || delimiterTypes.comma,
      cast: reviver,
      on_record: recordsSkipper,
      trim: true,
      relax_column_count: true,
    });

  console.log('ParseCSVTransformation', csvObj);

  return { data: csvObj };
}

ParseCSVTransformation.description = () => 'Parser from CSV into data';
