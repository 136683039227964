import Note from "../core/Note";
import React, { memo, useEffect, useRef } from "react";

const DefaultSlidesRenderer = (props: LayoutRenderProps) => {
   
    return (
        <div className="slides">
          {props.note.children.map((i, index) => 
            <section
              key={index}
              data-auto-animate
              data-note-id={props.note.id}
              data-background-color='#242648'
              data-background-image='/cloudStorage/notebl_logo.svg'
              data-background-size='200px'
              data-background-repeat='repeat'
              data-background-opacity='0.1'>
              {i.source}
            </section>
        )}
      </div>
    )
};

export default DefaultSlidesRenderer;