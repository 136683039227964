import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import stc from 'string-to-color';
import { theme } from '../shared/theme';
import { Invitation } from '../services/Invitation';
import _ from 'lodash';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    flex: '1 1 100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: '30px 20px 10px',
    backgroundColor: '#eeeeee',
    borderRadius: 2,
    marginRight: 20,
    marginBottom: 20,
    minHeight: 100,
    maxWidth: 375,
    transform: 'scale(1)  translate(0px, 0px)',
    '&:hover': {
      transform: 'scale(1.02)  translate(0px, -3px)',
      transition: 'all 0.25s ease-in-out',
      boxShadow: '0 10px 10px rgba(0,0,0,0.1)',
    },
    '&:last-child': {
      // marginRight: 0,
    },
  },
  title: {
    fontSize: 20,
    paddingBottom: 5,
    color: theme.colors.lightBgText,
  },
  summary: {
    ...theme.typography.content.body,
    flex: '1 1',
    fontSize: 13,
    color: theme.colors.lightBgTextSecondary,
  },
  sub: {
    flex: '0 0',
    marginTop: 10,
    fontSize: 11,
    color: lighten(theme.colors.lightBgTextSecondary, 0.2),
  },
  permission: {
    color: theme.colors.lightBgText,
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '5px 10px 5px 15px',
    background: 'rgba(0,0,0,0.2)',
    opacity: 0.5,
    borderRadius: '0 0 0 20px',
  },
});

export interface NotebookListItemProps {
  notebookId: NotebookId;
  noteId?: NoteId;
  title: string;
  summary: string;
  className?: string;
  createdBy?: string;
  invitation?: Invitation;
}

const NotebookListItem = (props: NotebookListItemProps) => {
  const { notebookId, noteId, title, summary, className, invitation } = props;
  const styles = useStyles();
  const bgCalculatedColor = lighten(stc(notebookId), 0.8);
  const link = _.isEmpty(noteId) ? `/notebook/${notebookId}` : `/notebook/${notebookId}/${noteId}`;
  const displayId = _.isEmpty(noteId) ? notebookId : `${notebookId} &middot; ${noteId}`;

  const sub = !!invitation ? `${displayId} &middot; created by ${props.createdBy}` : displayId;

  return (
    <Link
      to={link}
      className={classNames('list-item', styles.root, className)}
      style={{ backgroundColor: bgCalculatedColor }}
    >
      {invitation?.permission && (
        <div className={styles.permission}>
          {invitation?.permission === 'edit' ? 'Editable' : invitation?.permission === 'view' ? 'Read-only' : ''}
        </div>
      )}
      <div className={classNames('list-item-title', styles.title)}>{title}</div>
      <div className={classNames('list-item-summary', styles.summary)}>{summary}</div>
      <div className={classNames(styles.sub)} dangerouslySetInnerHTML={{ __html: sub }}></div>
    </Link>
  );
};

export default memo(NotebookListItem);
