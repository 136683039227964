import Note from '../core/Note';
import React from 'react';
import NoteControls from '../components/NoteControls';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';
import _ from 'lodash';
import { FormatterFactory2 } from '../formaters/FormatterFactory2';
import { LayoutRenderFactory2 } from './LayoutRenderFactory2';

const DEFAULT_MIN_COLUMNS = 4;
const DEFAULT_COLUMN_GAP = 20;

const useStyles = makeStyles({
  note: {
    display: 'flex',
    flexFlow: 'column',
    position: 'relative',
    '&.columns':{
      '& > .note-self > .note-value':{
        fontWeight: 700,
      }
    },
    '&.top-note': {
      padding: 10,
      paddingLeft: 60,
      paddingTop: 40,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      '& > .note-self': {
        position: 'relative',
        maxWidth: 900,
        width: '100%',
      },
      '& > .note-children': {
        position: 'relative',
        maxWidth: 900,
        width: '100%',
      },
      '&:not(.leaf) > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h1,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 5,
        },
      },
    },
    '& > .note-self': {
      '&:hover > .note-actions': {
        visibility: 'visible',
      },
    },
    '&.level-1:not(.leaf)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h2,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 5,
        },
      },
    },
    '&.level-2:not(.leaf)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h3,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 3,
        },
      },
    },
  },
  noteSelf: {
    display: 'flex',
    position: 'relative',
  },
  noteValue: {
    padding: '5px 0',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    lineHeight: '140%',
  },
  noteControls: {
    position: 'absolute',
    left: -40,
    visibility: 'hidden',
  },
  noteChildren: {
    padding: 0,
  },
  rowWrapper:{
    display: 'flex',
    flexFlow: 'row nowrap',
    '& > div:first-child':{
      paddingLeft: 0, 
    },
    '& > div:last-child':{
      paddingRigth: 0,
    }
  },
  column: {
    paddingRight: DEFAULT_COLUMN_GAP/2, // column gap // TODO: accept layout parameters for a column gap
    paddingLeft: DEFAULT_COLUMN_GAP/2, // column gap 
    flexGrow: 1,
    flexShrink: 1
  }
});

const ColumnsReadRenderer = (props: LayoutRenderProps) => {
  const styles = useStyles();

  if( props.note.isChildrenCollapsed || props.note.isLeaf() ){
    return (
      // <div>Collapsed</div>
      <LayoutRenderFactory2 mode="read" layoutName='Default' {...props} />
    );    
  }

  const { note, collapseNote, className } = props;
  const index = note.parent ? _.findIndex(note.parent.children, { id: note.id }) : 'top';
  const absoluteLevel = note.absoluteLevel();
  const displayLevel = note.displayLevelFrom(props.topNote);
  const displayLevelClassName = _.isNull(displayLevel) ? '' : 'level-' + displayLevel;

  // console.log('Found columns', props.note.layoutRender?.params?.columns);

  const parsedColumnNumber = _.toNumber(props.note.layoutRender?.params?.columns);
  let columns = _.isNumber(parsedColumnNumber) && parsedColumnNumber > 0 ? parsedColumnNumber : DEFAULT_MIN_COLUMNS;

  // columns = 3;
  const childrenInColumns = _.chunk(note.children, columns);

  const hideParent = props.note.layoutRender?.params?.hideParent === 'yes';

  // console.log('childen in columns', childrenInColumns);

  return (
    <div
      className={classNames(
        'note', 'columns',
        `index-${index}`,
        `absLevel-${absoluteLevel}`,
        displayLevelClassName,
        note === props.topNote && 'top-note',
        styles.note,
        className
      )}
      key={note.id}
    >
      {!hideParent && <div className={classNames('note-self', styles.noteSelf)}>
        <NoteControls
          onCollapseClick={(event) => collapseNote(note)}
          collapsible={!note.isLeaf()}
          onBulletClick={(event) => props.zoomNote(note)}
          closed={note.isChildrenCollapsed}
          className={classNames('note-actions', styles.noteControls)}
        />
        <div className={classNames('note-value', styles.noteValue)}>
          <FormatterFactory2 note={props.note} />
        </div>
      </div>
      }

        <div className={classNames('note-children', styles.noteChildren)}>
          {childrenInColumns.map( (row, rowIndex) => {
            return(
              <div className={styles.rowWrapper} key={rowIndex}>
              {row.map((child: Note, idx) => {
                return <div className={styles.column} key={rowIndex * columns + idx} style={{flexBasis: `${100/columns}%`}}>
                  <LayoutRenderFactory2
                    mode="read"
                    key={child.id}
                    note={child}
                    topNote={props.topNote}
                    collapseNote={props.collapseNote}
                    zoomNote={props.zoomNote}
                  />
                </div>
              }
              )}
            </div>
            )
          }
          ) }
        </div>
    </div>
  );
};

export default ColumnsReadRenderer;
