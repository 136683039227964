import firebase from 'firebase';
import { NotebookListAction, ActionType } from './state';
import _ from 'lodash';
import { NotebookList } from '../../services/NotebookList';

export const loadNotebookListAction = () => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();

    const notebookList = firestore.collection('notebookList');

    const email = firebase.auth().currentUser.email;

    const [ownNotebooks, sharedNottebooks] = await Promise.all([
      notebookList.where('owner', '==', email).where('sharedTo', '==', null).get(),
      notebookList.where('sharedTo.email', '==', email).get(),
    ]);

    const owneNotebooksData = _([...ownNotebooks.docs])
      .map((i) => i.data() as NotebookList)
      .orderBy((i) => i.lastUpdated, 'desc')
      .value();

    const sharedNotebooksData = _([...sharedNottebooks.docs])
      .map((i) => i.data() as NotebookList)
      .orderBy((i) => i.lastUpdated, 'desc')
      .value();

    const readyAction: NotebookListAction = {
      type: ActionType.UPDATE_NOTEBOOK,
      notebooks: [...owneNotebooksData, ...sharedNotebooksData],
    };

    dispatch(readyAction);
  };
};
