import React, { memo, ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import ChatIcon from './shared/icons/ChatIcon';
import NotebookIcon from './shared/icons/NotebookIcon';
import CalendarIcon from './shared/icons/CalendarIcon';
import TagsIcon from './shared/icons/TagsIcon';
import PeopleIcon from './shared/icons/PeopleIcon';
import { Link, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import { theme } from '../shared/theme';

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: 0,
    width: 'calc(100% - 40px)',
  },
  navItem: {
    border: 'none',
    color: theme.colors.darkBgLink,
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    textTransform: 'unset',
    textDecoration: 'unset',
    fontSize: 13,
    minWidth: '90%',
    fontWeight: 400,
    borderRadius: 0,
    '&:hover': {
      color: theme.colors.darkBgLinkHover,
    },
    '& > svg': {
      marginRight: 10,
      color: theme.colors.darkBgIcon,
      minWidth: 18,
      marginLeft: -2,
    },
  },
  navItemSelected: {
    color: '#ffffff',
    position: 'relative',
    zIndex: 0,
    '&:hover': {
      color: '#ffffff',
      cursor: 'default',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 5,
      bottom: 5,
      right: 0,
      backgroundColor: theme.colors.noteblRed,
      zIndex: -1,
    },
    '& > svg': {
      color: '#ffffff',
    },
  },
  navItemHighlighted: {
    color: theme.colors.darkBgLink,
    position: 'relative',
    zIndex: 0,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 5,
      bottom: 5,
      backgroundColor: theme.colors.noteblRed,
      width: 5,
      zIndex: -1,
    },
    '& > svg': {
      color: theme.colors.darkBgIcon,
    },
  },
  navItemDisabled: {
    background: 'none',
    color: theme.colors.darkBgLinkDisabled,
    cursor: 'default',
    '& > svg': {
      color: theme.colors.darkBgLinkDisabled,
    },
    '&:hover': {
      color: theme.colors.darkBgLinkDisabled,
      cursor: 'default',
    },
  },
});

export interface NavigationProps {
  onSelect?: (event: any) => void;
  className?: string;
}

const Navigation = ({ className, onSelect }: NavigationProps) => {
  const styles = useStyles();
  let selectedNotebookRoute = useRouteMatch('/notebook/:id');
  let compareNotebookRoute = useRouteMatch('/compare/:id1/:id2');

  return (
    <div id="menu" className={styles.root}>
      <NavLink icon={<ChatIcon />} label="Updates" to="/updates" />
      <NavLink
        icon={<NotebookIcon />}
        label="Notebooks"
        to="/notebooks"
        className={(selectedNotebookRoute || compareNotebookRoute) && styles.navItemHighlighted}
      />
      <NavLink icon={<CalendarIcon />} label="Calendar" to="/calendar" disabled={true} />
      <NavLink icon={<TagsIcon />} label="Tags" to="/tags" disabled={true} />
      <NavLink icon={<PeopleIcon />} label="People" to="/people" disabled={true} />
    </div>
  );
};

interface NavLinkProps {
  icon?: React.ReactElement;
  label?: string;
  to: string;
  activeOnlyWhenExact?: boolean;
  className?: string;
  disabled?: boolean;
}

const NavLink = ({ icon, label, to, activeOnlyWhenExact, className, disabled, ...rest }: NavLinkProps) => {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  const styles = useStyles();

  return (
    <Link
      to={to}
      className={classNames(
        className,
        styles.navItem,
        match && match.isExact && styles.navItemSelected,
        disabled && styles.navItemDisabled
      )}
      {...rest}
    >
      {!!icon && icon} {label}
    </Link>
  );
};

export default memo(Navigation);
