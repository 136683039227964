/* eslint-disable no-template-curly-in-string */
export default {
  "notebookId": "a123",
  "rootId": "a123",
  "mode": "outline",
  "notes": [
    {
      "id": "a123",
      "source": "<b>Introduction to Notebl</b>",
      "children": [
        "z1", "z2", "z3"
      ]
    },

    { "id": "z1", "source": "Intro", "children": ["i5", "z11"], "collapseChildren": true, },
    {
      "id": "z11",
      "source": "Notebl helps business users communicate progress by simplifying creation and sharing of business presentations.",
    },
    {
      "id": "z2",
      "source": "Problems to solve",
      "children": ["z21", "z22", "z23", "z24", "z25", "z26", "z27"],
      "collapseChildren": true,
    },
    { "id": "z21", "source": "Create business presentations fast." },
    { "id": "z22", "source": "Easily create data-driven presentations, injecting source data once and using it in multiple places." },
    { "id": "z23", "source": "Make business presentations that are easy to read, collaborate on and even edit, on any device." },
    { "id": "z24", "source": "Templates on steroids, reuse data, content, logic, styles." },
    { "id": "z25", "source": "Make it easy for users to ask questions and share feedback within a context of a presentation." },
    { "id": "z26", "source": "Keep all communication secure, with content owners in control." },
    { "id": "z27", "source": "Easily review changes between iterations." },
    {
      "id": "z3",
      "source": "Notebl Prototype Phases",
      "children": ["z31", "z32", "z33"]
    },
    {
      "id": "z31", "source": "Phase 1: tell the story of quick path from structure to dynamic report, building complexity out of simple blocks",
      "children": ["z311", "z312", "z313", "z314"]
    },
    {
      "id": "z311", "source": "✅Render hierarchical document with text, data and images"
    },
    {
      "id": "z312", "source": "✅Set the data in centralized place, reference it in the content ",
      "children": ["bc222", "c333", "ab222"],
      "collapseChildren": true
    },
    // {
    //   "id": "z313", "source": "✅Simple data parsing, dates, numbers, text "
    // },
    {
      "id": "z313", "source": "✅ Perform data transformations, to individual notes or note branches ",
      "collapseChildren": false,
      "children": ["c334"],
      "refs": {
        "Inherited2": "z314"
      }
    },
    {
      "id": "z314", "source": "✅Transform simple pasted CSV or JSON data into a table and a simple chart",
      "children": ["f666", "f667"],
      "collapseChildren": true,
    },
    {
      "id": "z32", "source": "Phase 2: tell the story of rapid collaborative development and rapid sharing",
      "children": ["z321", "z322", "z323", "z324"]
    },
    {
      "id": "z321", "source": "Secure conversations within context of nodes"
    },
    {
      "id": "z322", "source": "✅Direct editing of notes: type in, create and delete notes, tabbing for hierarchy changes"
    },
    {
      "id": "z323", "source": "✅Transform Notebl document into a styled and branded presentation"
    },
    {
      "id": "z324", "source": "✅Login Page, Starting page with list of user conversations, Conversation Page: notebook and comments, from static JSON "
    },


    {
      "id": "z33", "source": "Phase 3: Illustrate with a demo the story of rapid collaborative development and rapid sharing",
      "children": ["z321", "z322", "z323"]
    },
    {
      "id": "z331", "source": "Compare between notebook iterations"
    },
    {
      "id": "z332", "source": "Get notified of updates to the cloned notes, compare between notebooks"
    },
    {
      "id": "z333", "source": "Dynamic list of notebooks and conversations, loaded from DB "
    },



    {
      "id": "i5",
      "source": "notebl_logo.svg",
      "transformations": [
        {
          "name": "image",
          "params": {
            "attributes": {
              "title": "Notebl logo",
              "alt": "Notebl",
              "style": {
                "padding": "5px"
              }
            }
          }
        }
      ]
    },



    {
      "id": "c334",
      "source": "Hierarchical Changes, recursively update children",
      "children": ["e555", "e556", "e557"],
      "refs": {
        "InheritedRef": "c337"
      }
    },
    {
      "id": "c335",
      "source": "123,456"
    },
    {
      "id": "c336",
      "source": { a: 'aaa', b: 'bbb' }
    },
    {
      "id": "c337",
      "source": "234567"
    },
    {
      "id": "e556",
      "source": "InheritedRef: =InheritedRef Original: =\"NumStr\", Parsed: ${helpers.parseNumber(NumStr)}",
      "refs": {
        "NumStr": "c335"
      }
    },
    {
      "id": "e557",
      "source": "${JSON.stringify(SomeObj)}",
      "refs": {
        "SomeObj": "c336",
      }
    },
    {
      "id": "e555",
      "name": "",
      "source": "Parent Note",
      "collapseChildren": false,
      "transformations": [
        {
          "name": "helloWorld",
          "params": { 'addOn': ' With Parameters!' },
          "recursive": true
        },
        {
          "name": "helloWorld",
          "params": { 'addOn': '${DataSource}' },
          "recursive": true
        }
      ],
      "children": ["e555-0"],
      "refs": {
        "DataSource": "ab222"
      }
    },
    {
      "id": "e555-0",
      "source": "Just a child",
      "collapseChildren": false,
      "children": [
        "e555-2",
        // "e555-3",
        "e555-4",
        "e555-5"
      ]
    },
    {
      "id": "e555-2", "source": "Deep Child", "children": ["e555-3"]
    },
    {
      "id": "e555-3", "source": "Deep Child 2"
    },
    {
      "id": "e555-4", "source": "Deep Child 3"
    },
    {
      "id": "e555-5", "source": "Deep Child 4"
    },

    {
      "id": "bc222",
      "name": "TotalEvents",
      // eslint-disable-next-line
      "source": "Total Events: =DataSource",
      "refs": {
        "DataSource": "ab222"
      }
    },
    {
      "id": "ab222",
      "name": "DataSource",
      "source": 254
    },
    {
      "id": "c333",
      // eslint-disable-next-line
      "source": "This Week Events: =\"Total Events\"",
      "refs": {
        "Total Events": "bc222"
      }
    },




    {
      "id": "f666", "source": "CSV Parsing and visualization", "children": ["f666-1"]
    },
    {
      "id": "f666-1",
      "name": "DataCSV",
      "source": "date,value\n2013-01,53\n2013-02,165\n2013-03,269\n2013-04,344\n2013-05,376",
      "transformations": [{
        "name": "parseCSV",
        "params": {
          "parsers": {
            "date": "parseDate",
            "value": "parseNumber"
          }
        }
      },
      {
        "name": "listToTable",
        "params": {
          "columns": ["date", "value", "test"]
        }
      }
      ]
    },

    {
      "id": "f667", "source": "JSON Parsing and visualization, data in a separate note",
      "children": ["g777", "g777-1", "g777-2", "g777-3", "g777-5", "g7771", "i999"]
    },

    {
      "id": "g777",
      "name": "DataJSON",
      "source": "[{\"date\":\"2013-01\",\"value\":\"53\"},{\"date\":\"2013-02\",\"value\":\"165\",\"extra\":\"Yay\"},{\"date\":\"2013-03\",\"value\":\"269\"},{\"date\":\"2013-04\",\"value\":\"344\"},{\"date\":\"2013-05\",\"value\":\"376\"}]",
      "children": [],
      "transformations": [{
        "name": "parseJSON",
        "params": {
          "parsers": {
            "date": "parseDate",
            "value": "parseNumber"
          }
        }
      }
      ]
    },
    {
      "id": "g777-1",
      "source": "Table for JSON data",
      "transformations": [
        {
          "name": "listToTable",
          "data": "DataJSON",
          // "params": {
          //   "columns": ["date", "value"]
          // }
        }
      ],
      "refs": {
        "DataJSON": "g777"
      },
    },
    {
      "id": "g777-4",
      "name": "DataJSONObject",
      "source": [{ "date": "2013-01", "value": 53 }, { "date": "2013-02", "value": 165 }, { "date": "2013-03", "value": 269 }, { "date": "2013-04", "value": 344 }, { "date": "2013-05", "value": 376 }],
    },
    {
      "id": "g777-2",
      "source": "Table for JSON data",
      "transformations": [
        {
          "name": "rechartBarchartHorizontal",
          "data": "DataJSON",
          "params": {
            "series": [{
              "dataFields": {
                "value": "value",
                "category": "date"
              }
            }]
          }
        }
      ],
      "refs": {
        "DataJSON": "g777"
      },
    },
    {
      "id": "g777-3",
      "source": "Table for JSON data",
      "transformations": [
        {
          "name": "rechartBarchartVertical",
          "data": "DataJSONObject",
          "params": {
            "title": "Barchart Vertical",
            "showGrid": "yes",
            "series": [{
              "dataFields": {
                "value": "value",
                "category": "date"
              }
            }]
          }
        }
      ],
      "refs": {
        "DataJSONObject": "g777-4"
      },
    },
    {
      "id": "g777-5",
      "source": "Table for JSON data",
      "transformations": [
        {
          "name": "rechartLinechart",
          "data": "DataJSON",
          "params": {
            "title": "Linechart with custom color",
            "color": "#ff0000",
            "series": [{
              "dataFields": {
                "value": "value",
                "category": "date"
              }
            }]
          }
        }
      ],
      "refs": {
        "DataJSON": "g777"
      },
    },
    {
      "id": "g7771",
      "name": "DeepJSONParsing",
      "source": "{\"myData\":{\"firstValue\":{\"date\":\"2013-01\",\"value\":\"53\"}}}",
      "children": [],
      "transformations": [{
        "name": "parseJSON",
        "params": {
          "parsers": {
            "date": "parseDate",
            "value": "parseNumber"
          }
        }
      }
      ]
    },
    {
      "id": "h888",
      "name": "hiddenNote",
      "source": "Invisible orphan note",
    },
    {
      "id": "i999",
      "name": "",
      // eslint-disable-next-line
      "source": "Data from JSON ${DataJSON[0].date}, String from hidden: ${hiddenNote}",
      "refs": {
        "DataJSON": "g777",
        "hiddenNote": "h888"
      }
    },
  ]
}