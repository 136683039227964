import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';
import NotebookListItem from '../components/NotebookListItem';
import PageContainer from '../components/PageContainer';
import { connect } from 'react-redux';
import { createNewNotebookAction } from '../store/notebooks/createNewNotebookAction';
import { StoreState } from '../store/storeFactory';
import { loadNotebookListAction } from '../store/notebooks/loadNotebookListAction';
// import { NotebookList } from '../services/NotebookList';
import ControlButton from '../components/shared/controls/ControlButton';
import { ExtendedNotebookList } from '../store/notebooks/state';
import _ from 'lodash';

const useStyles = makeStyles({
  section: {
    padding: 20,
    '& > h1': {
      // ...theme.typography.content.h1,
      ...theme.typography.app.h1,
      marginTop: 0,
    },
  },
  notebooksList: {},
  notebooksListRow: {
    display: 'flex',
    flexFlow: 'row wrap',
    minHeight: 200,
    alignItems: 'stretch',
    marginTop: 20,
  },
  notebooksListRowItem: {
    flex: '1 1 20%',
    '@media (min-width: 600px)': {
      flex: '1 1 50%',
    },
    '@media (min-width: 900px)': {
      flex: '1 1 30%',
    },
    '@media (min-width: 1600px)': {
      flex: '1 1 18%',
    },
  },
  loadingLogoColors: {
    '& .brandLines': {
      fill: '#cccccc',
    },
    '& .brandLetters': {
      fill: '#cccccc',
    },
  },
});

interface NotebooksPageProps {
  notebooks: ExtendedNotebookList[];
  topRecentNotebooks: ExtendedNotebookList[];
  topSharedNotebooks: ExtendedNotebookList[];
  allOtherNotebooks: ExtendedNotebookList[];
  createNewNotebook: () => void;
  loadNotebookList: () => void;
}

const NotebookListSection = (props) => {
  const styles = useStyles();
  return (
    <section className={styles.section}>
      {props.title && <h1>{props.title}</h1>}
      <div className={classNames('notebooks-list', styles.notebooksList)}>
        <div className={classNames('list-row', styles.notebooksListRow)}>
          {props.listOfNotebooks?.map((i, index) => (
            <NotebookListItem
              key={index}
              invitation={i.sharedTo}
              notebookId={i.notebookId}
              noteId={!_.isEmpty(i.sharedTo) && i.noteId}
              title={i.name}
              summary={i.summary}
              className={styles.notebooksListRowItem}
              createdBy={i.owner}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const NotebooksPage = (props: NotebooksPageProps) => {
  document.title = 'Notebl Notebooks';

  useEffect(() => {
    props.loadNotebookList();
    // eslint-disable-next-line
  }, []);

  return (
    <PageContainer
      pageTitle="Notebooks"
      pageActions={<ControlButton label="Create new" onClick={() => props.createNewNotebook()} importance="primary" />}
    >
      <NotebookListSection title="Recently updated" listOfNotebooks={props.topRecentNotebooks} />
      {!_.isEmpty(props.topSharedNotebooks) && (
        <NotebookListSection title="Top shared with you" listOfNotebooks={props.topSharedNotebooks} />
      )}
      {!_.isEmpty(props.allOtherNotebooks) && (
        <NotebookListSection title="All other notebooks" listOfNotebooks={props.allOtherNotebooks} />
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state: StoreState, ownProps) => {
  const { notebookList } = state;

  const topRecentNotebooks = _.take(notebookList.notebooks, 4);
  const topSharedNotebooks = _(notebookList.notebooks)
    .difference(topRecentNotebooks)
    .filter(['sharedWithMe', true])
    .take(4)
    .value();

  const listed = _.union(topRecentNotebooks, topSharedNotebooks);
  const allOtherNotebooks = _.filter(notebookList.notebooks, (o) => !_.includes(listed, o));

  return {
    notebooks: notebookList.notebooks,
    topRecentNotebooks,
    topSharedNotebooks,
    allOtherNotebooks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewNotebook: () => dispatch(createNewNotebookAction()),
    loadNotebookList: () => dispatch(loadNotebookListAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotebooksPage);
