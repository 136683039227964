import React from 'react';
import _ from 'lodash';
// import helpers from '../transformations/shared/helpers';
import { getDataFromParams } from '../transformations/shared/utils';
import { FormaterProps } from './FormatterFactory2';
// import classNames from 'classnames';
// import { makeStyles } from '@material-ui/styles';
// import { theme } from '../shared/theme';


const ImageFormatter = (props: FormaterProps) => {
  
  const params = props.note.formatter.params;
  const data = props.unit.data;

  // const data = getDataFromParams(props.note.formatter.params, referableValues, props.note.computedValue.data);

  console.log('image formatter', props, params);
  return (
    <img src={'/cloudStorage/' + data} alt={data} />
)
};

export default ImageFormatter;

// Legacy:
// 
// const attributes = getDataFromParams(params, resolvedRefs, undefined, 'attributes');
// const data = getDataFromParams(params, resolvedRefs, selfNoteValue.data);

// return {
//   data: data,
//   stylize: selfNoteValue.stylize,
//   renderAt: 'value',
//   render: () => (
//     <img
//       {...attributes}
//       src={'/cloudStorage/' + data}
//       alt={attributes.alt ? attributes.alt : ''}
//       style={attributes.style ? attributes.style : {}}
//     />
//   ),
// };