import React from 'react';
import _ from 'lodash';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, LabelList } from 'recharts';

import helpers from '../transformations/shared/helpers';
import { getRows, isDataValid, isParams } from '../transformations/shared/utils';
import { FormaterProps } from './FormatterFactory2';

const DEFAULT_COLOR = '#568EC7';
const DEFAULT_CATEGORY_WIDTH_ON_HORIZONTAL = 150;
const DEFAULT_HORIZONTAL_BARCHART_HEIGHT = 200;
const DEFAULT_VALUE_LABEL_SPACE_HORIZONTAL = 80;

const BarchartHorizontalFormatter = (props: FormaterProps) => {
  const params = props.note.formatter.params || {};

  const dataSource = params.data ?? props.unit.data;

  // https://lodash.com/docs/4.17.15#get
  // path is "The path of the property to get"
  const data = params.path ? _.get(dataSource, params.path) : dataSource;

  const { valid: dataValid, message: dataErrorMessage } = isDataValid(data);
  if (!dataValid) {
    return <div className="chart error">{dataErrorMessage}</div>;
  }

  // const { valid: paramsValid, message: paramsErrorMessage } = isParams(params);
  // if (!paramsValid) {
  //   return <div className="error">{paramsErrorMessage}</div>;
  // }

  const _series0 = Array.isArray(params.series) && params.series.length > 0 ? params.series[0] : null;
  const _category = _series0 ? _series0.dataFields?.category : params.category || null;
  const _value = _series0 ? _series0.dataFields?.value : params.value || null;

  if (!_category) {
    const availableCategories = _.join(_.keys(data[0]), '", "');
    return (
      <div className="chart error">
        Missing a chart category. In formatter parameters add a parameter "category" and select one of "
        {availableCategories}"
      </div>
    );
  }
  if (!_value) {
    const availableValues = _.join(_.keys(data[0]), '", "');
    return (
      <div className="chart error">
        Missing a chart category. In formatter parameters add a parameter "value" and select one of "{availableValues}"
      </div>
    );
  }

  const _collectedRows = getRows(data, _category, _value);

  // const color = _.isHexColor(params.color) ? params.color : DEFAULT_COLOR;
  const color = params.color ?? DEFAULT_COLOR;
  const categoryWidth = _.isNumber(params.categoryWidth) ? params.categoryWidth : DEFAULT_CATEGORY_WIDTH_ON_HORIZONTAL;
  const rightPadding = _.isNumber(params.rightPadding) ? params.rightPadding : DEFAULT_VALUE_LABEL_SPACE_HORIZONTAL;
  const minHeight = _.isNumber(params.minHeight) ? params.minHeight : DEFAULT_HORIZONTAL_BARCHART_HEIGHT;
  const barSize = _.isNumber(params.barSize) ? params.barSize : undefined;

  const _maxValue = _.maxBy(_collectedRows, (x) => x[_value])[_value];
  const axisTickFormat = _maxValue < 10 ? '0.00' : _maxValue < 10000 ? '0,0' : '0a';

  return (
    <div className="chart rechart bar-chart bar-chart-horizontal">
      {!!params.title && <h2>{params.title}</h2>}
      <ResponsiveContainer width="90%" minHeight={minHeight} debounce={1}>
        <BarChart
          width={500}
          height={500}
          data={_collectedRows}
          layout="vertical"
          margin={{ top: 5, right: rightPadding, left: 5, bottom: 5 }}
        >
          <XAxis
            type="number"
            axisLine={false}
            tickFormatter={(value) => helpers.formatNumber(value, axisTickFormat)}
          />
          <YAxis type="category" tickLine={false} axisLine={false} dataKey={_category} width={categoryWidth} />
          <Tooltip formatter={(value) => helpers.formatNumber(value, params.valueFormat)} />
          <Bar dataKey={_value} fill={color} barSize={barSize} minPointSize={1}>
            <LabelList
              dataKey={_value}
              formatter={(value) => helpers.formatNumber(value, params.valueFormat)}
              position="right"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarchartHorizontalFormatter;
