import { Observable, combineLatest, of, isObservable, defer } from "rxjs";
import { NoteStream, Unit } from "./eventBus";
import _ from "lodash";
import paths from "deepdash/paths";
import { switchMap, map, defaultIfEmpty, distinctUntilChanged } from "rxjs/operators";
import { Transformations } from "./Transformations";
import { interpolateSource } from "./interpolateSource";


export function transformationPipeFactory(streams: Observable<NoteStream[]>) {

    return (transformations: Transformation[]) => _(transformations)
        .filter(i => !i.disabled)
        .map(transformation => (unit: Observable<Unit>) => 

            combineLatest(unit, streams).pipe(

                switchMap(([unit, streams]) =>
                    interpolateParams(transformation.params, streams).pipe(
                        map(params => ({ unit, params, }))
                )),
                distinctUntilChanged((prev, current) => (_.isEqual(prev, current))),
                switchMap(({ unit, params }) => {
                    const result = Transformations[transformation.name](unit, params);
                    return isObservable(result) ? result : defer(() => Promise.resolve(result));
                })
            )
        )
        .value();
}

function interpolateParams(params: any, namedStreams: NoteStream[]): Observable<any> {

    const waits = _(paths(params)).map((path: string) => {

        const value = _.get(params, path);
        const observable = _.isString(value) ? interpolateSource(value, namedStreams) : of(value);
        return observable.pipe(
            map(value => ({ path, value }))
        )
    })
    .value();

    return combineLatest(waits).pipe(
        map(items => _(items).reduce((acc, i) => _.set(acc, i.path, i.value), {})),
        defaultIfEmpty(params),
    )
}