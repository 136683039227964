import React from 'react';

const HiddenRenderer = (props: LayoutRenderProps) => {
  const { note } = props;

  if(note)

  return (
    <div className='hidden' key={note.id} data-note-id={props.note.id}>
    </div>
  );
};

export default HiddenRenderer;
