import Note from "../core/Note";
import React, { memo, useEffect, useRef } from "react";

const DefaultSlidesRenderer = (props: LayoutRenderProps) => {
   
    return (
        <div className="slides">
          <section
              data-auto-animate
              data-note-id={props.note.id}
              data-background-color='#242648'
              data-background-image='/cloudStorage/notebl_logo.svg'
              data-background-size='200px'
              data-background-repeat='repeat'
              data-background-opacity='0.1'>
              Default Slides Renderer
            </section>
      </div>
    )
};

export default DefaultSlidesRenderer;