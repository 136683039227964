import React, { memo, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { theme } from '../shared/theme';
import classNames from 'classnames';
import NoteblLogoIcon from './shared/icons/NoteblLogoIcon';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  darkBg: {
    backgroundColor: theme.colors.darkBg,
  },
  wrapper: {
    opacity: 0.1,
    transition: 'opacity 5s ease-out',
    '&.fullColor': {
      opacity: 1,
    },
  },
  message: {
    width: 200,
    marginTop: 20,
    letterSpacing: 2,
    color: '#eeeeee',
    ...theme.typography.app.h2,
  },
});

export interface AppPreloaderProps {
  darkBg?: boolean;
}

const AppPreloader = ({ darkBg = false }) => {
  const styles = useStyles();
  const preloader = useRef(null);
  useEffect(() => {
    preloader.current.className += ' fullColor';
  }, []);

  return (
    <div className={classNames(styles.root, darkBg && styles.darkBg)}>
      <div ref={preloader} className={styles.wrapper}>
        <NoteblLogoIcon colorScheme="uniformLight" animate={true} />
        {/* <div className={styles.message}>Loading...</div> */}
      </div>
    </div>
  );
};

export default memo(AppPreloader);
