import React from 'react';
import HiddenRenderer from './HiddenRenderer';
import SkipRenderer from './SkipRenderer';
import PageTitleReadRenderer from './PageTitleReadRenderer';
import ColumnsReadRenderer from './ColumnsReadRenderer';
import MarketingReportReadRenderer from './MarketingReportReadRenderer';
import MarketingReportSlidesRenderer from './MarketingReportSlidesRenderer';
import _ from 'lodash';
import DefaultReadRenderer from './DefaultReadRenderer';
import DefaultSlidesRenderer from './DefaultSlidesRenderer';
import BulletedListReadRenderer from './BulletedListReadRenderer';
import ExampleReadRenderer from './ExampleReadRenderer';

type LayoutRender = (props: LayoutRenderProps) => React.ReactElement;

const renderers: { [key: string]: { read: LayoutRender; slides: LayoutRender } } = {
  Default: {
    read: DefaultReadRenderer,
    slides: DefaultSlidesRenderer,
  },
  Skipped: {
    read: SkipRenderer,
    slides: DefaultSlidesRenderer,
  },
  Hidden: {
    read: HiddenRenderer,
    slides: DefaultSlidesRenderer,
  },
  PageTitle: {
    read: PageTitleReadRenderer,
    slides: DefaultSlidesRenderer,
  },
  Columns: {
    read: ColumnsReadRenderer,
    slides: DefaultSlidesRenderer,
  },
  BulletedList: {
    read: BulletedListReadRenderer,
    slides: DefaultSlidesRenderer,
  },
  Example: {
    read: ExampleReadRenderer,
    slides: DefaultSlidesRenderer,
  },
  MarketingReport: {
    read: MarketingReportReadRenderer,
    slides: MarketingReportSlidesRenderer,
  }
};

type LayoutRenderModeProps = { mode: 'read' | "slides", layoutName?: string } & LayoutRenderProps;

export const LayoutRenderFactory2 = (props: LayoutRenderModeProps) => {

  const layoutName = _.isUndefined(props.layoutName) ?  props.note.layoutRender?.name : props.layoutName ;
  const RendererSet = renderers[layoutName] || renderers['Default'];
  const Renderer = RendererSet[props.mode];
  return <Renderer {...props} />
}

export const Renderers = _.keys(renderers);