import { NotebookList } from "../../services/NotebookList";

export enum ActionType {
    UPDATE_NOTEBOOK = 'UPDATE_NOTEBOOK',
}

export type Action<TActionType, TPayload> = { readonly type: TActionType } & Partial<TPayload>;
export type NotebookListAction = Action<ActionType, NotebookListState>;

export type ExtendedNotebookList = NotebookList;

export class NotebookListState {
    notebooks: ExtendedNotebookList[];
}