import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  root: {}
});

export interface CalendarIconProps {
  className?: string;
}

const CalendarIcon = ({ className }: CalendarIconProps) => {
  const styles = useStyles();
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.root, className)}
    >
      <rect x="7" y="1" width="4" height="2.5" rx="0.5" stroke="currentColor" />
      <rect x="13" y="1" width="4" height="2.5" rx="0.5" stroke="currentColor" />
      <rect x="1" y="5" width="4" height="2.5" rx="0.5" stroke="currentColor" />
      <rect x="7" y="5" width="4" height="2.5" rx="0.5" stroke="currentColor" />
      <rect x="13" y="5" width="4" height="2.5" rx="0.5" stroke="currentColor" />
      <rect x="1" y="9" width="4" height="2.5" rx="0.5" stroke="currentColor" />
      <rect x="7" y="9" width="4" height="2.5" rx="0.5" stroke="currentColor" />
    </svg>
  );
};

export default memo(CalendarIcon);
