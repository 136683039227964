import { ActionType } from './actionType';
import Note from '../../core/Note';
import * as NotebookActions from '../../core/NotebookActions';
import Notebook from '../../core/Notebook';
import firebase from 'firebase';
import { NotebookAction } from './actions';
import _ from 'lodash';
import { NotebookList } from '../../services/NotebookList';

export const initializeNotebookAction = (notebookId: string, noteId: string) => {
  return async (dispatch, getState, firebase: firebase.app.App) => {
    const { notebook } = getState();

    const loadingAction: NotebookAction = {
      type: ActionType.UPDATE_NOTEBOOK,
      notebookId: notebookId,
      noteId: noteId,
      loading: true,
    };
    dispatch(loadingAction);

    const newNotebook = notebook.notebookId === notebookId ? notebook.notebook : await loadNotebook(notebookId);

    const renderFromNote: Note = NotebookActions.getNoteById(newNotebook, noteId) || newNotebook.root;

    const firestore = firebase.firestore();
    const notebookList = firestore.collection('notebookList');

    const email = firebase.auth().currentUser.email;

    const [ownNotebooks, sharedNottebooks] = await Promise.all([
      notebookList
        .where('notebookId', '==', notebookId)
        .where('owner', '==', email)
        .where('sharedTo', '==', null)
        .get(),
      notebookList.where('notebookId', '==', notebookId).where('sharedTo.email', '==', email).get(),
    ]);

    const nbl = _([...ownNotebooks.docs, ...sharedNottebooks.docs])
      .map((i) => i.data() as NotebookList)
      .head();

    const readyAction: NotebookAction = {
      type: ActionType.UPDATE_NOTEBOOK,
      notebookId: notebookId,
      notebook: newNotebook,
      renderFromNote: renderFromNote,
      notebookList: nbl,
      loading: false,
    };
    dispatch(readyAction);
  };
};

export async function loadNotebook(notebookId) {
  const notebookjson = await loadFromFireStore(notebookId);
  return new Notebook(notebookjson);
}

async function loadFromFireStore(notebookId) {
  const firestore = firebase.firestore();
  const notebook = await firestore.collection('notebooks').doc(notebookId).get();
  console.log('returned notebook', notebook);
  return notebook.data() as NotebookData;
}
