import _ from 'lodash';
import { ShareableLink } from '../services/ShareableLink';

export default class Note {
  // TODO: make these fields as TS properties with getter/setter.
  id: NoteId;
  name?: string;
  source: string | number;
  transformations?: Transformation[];
  isChildrenCollapsed?: boolean;

  shareableLinks: ShareableLink[];
  children: Note[];
  parent: Note; // null for root note
  references: { [key: string]: Note };
  referedBy: Note[];
  inheritedReferences: { [key: string]: Note };
  inheritedTransformations: Transformation[];
  computedValue: ComputedType;
  isComputed: boolean;
  root: Note; // the greatest ancestor
  virtualOf: Note = null; // null if it's not virtual

  domRef: HTMLDivElement; // TODO: detach DOM logic from core

  private _children?: NoteId[];
  private _references?: { [name: string]: NoteId };

  layoutRender: {
    name?: string;
    params?: any;
    disabled?: boolean;
  };

  formatter: {
    name?: string;
    params?: any;
    disabled?: boolean;
  };

  constructor(data: NoteData) {
    this.id = data.id;
    this.name = data.name;
    this.source = data.source;
    this.isChildrenCollapsed = _.toBoolean(data.collapseChildren);
    this._children = data.children;
    this._references = data.refs;
    this.transformations = data.transformations;
    this.layoutRender = data.layout || {};
    this.formatter = data.formatter || {};
    this.isComputed = false;
    this.shareableLinks = data.shareableLinks || [];
  }

  link(allNotes: Note[], rootNote: Note) {
    const findNoteById = (id: NoteId) => {
      return _.find(allNotes, (note) => note.id === id);
    };
    this.children = _.map(this._children, (childId) => findNoteById(childId));
    this.references = {};
    this.inheritedReferences = {};
    _.each(this._references, (refId: NoteId, refName: string) => {
      const refNote = findNoteById(refId);
      this.references[refName] = refNote;
      if (refNote.referedBy) {
        refNote.referedBy.push(this);
      } else {
        refNote.referedBy = [this];
      }
    });
    this.inheritedTransformations = [];
    this.root = rootNote;
  }

  setParent(parent: Note) {
    this.parent = parent;
    if (parent) {
      _.assign(this.inheritedReferences, parent.inheritedReferences, parent.references);
      _.assign(
        this.inheritedTransformations,
        parent.inheritedTransformations,
        _.filter(parent.transformations, (trans) => trans.recursive)
      );
      this.root = parent.root;
    }

    _.each(this.children, (child) => {
      child.setParent(this);
    });
  }

  // https://docs.google.com/document/d/1agrEmvdas9CwZIH7CCHCBhlANLDpUcKLz1qX2d7gjhc/edit#heading=h.m4x2ftpotj0h
  compute() {}

  recompute() {
    // const resetChildren = (note: Note) => {
    //   _.each(note.children, (child) => {
    //     child.isComputed = false;
    //     resetChildren(child);
    //   });
    // };
    // this.isComputed = false;
    // resetChildren(this);
    // this.compute();
    // _.each(this.referedBy, (note) => {
    //   note.recompute();
    // });
  }

  updateSource(source: string) {
    // console.log('Note.updateSource, id, from, to', this.id, this.source, source);
    this.source = source;
    this.recompute();
  }

  updateName(name: string) {
    this.name = name;
    this.root.recompute();
  }

  updateTransformations(transformations: Transformation[]) {
    this.transformations = transformations;
    this.recompute();
  }

  updateReferences() {
    for (const name in this.references) {
      const refNote = this.references[name];
      if (refNote.referedBy) {
        refNote.referedBy.push(this);
      } else {
        refNote.referedBy = [this];
      }
    }
  }

  isRoot() {
    return !this.parent;
  }

  isLeaf() {
    return _.isEmpty(this.children);
  }

  absoluteLevel() {
    const getLevel = (note: Note) => (note.isRoot() ? 0 : getLevel(note.parent) + 1);
    return getLevel(this);
  }

  displayLevelFrom(note: Note) {
    const getLevel = (note: Note, fromNote: Note) => {
      if (note.id === fromNote.id) return 0;
      if (note.isRoot() && note.id !== fromNote.id) return null;
      const level = getLevel(note.parent, fromNote);
      return _.isNull(level) ? null : level + 1;
    };
    return getLevel(this, note);
  }

  isFirstChild() {
    if (this.parent) {
      return this.parent.children[0]?.id === this.id;
    }
    return false;
  }

  isLastChild() {
    if (this.parent) {
      return this.parent.children[this.parent.children.length - 1]?.id === this.id;
    }
    return false;
  }

  getPrevSibling() {
    const parentNote = this.parent;
    if (parentNote && !this.isFirstChild()) {
      return parentNote.children[_.findIndex(parentNote.children, { id: this.id }) - 1];
    }
    return null;
  }

  getNextSibling() {
    const parentNote = this.parent;
    if (parentNote && !this.isLastChild()) {
      return parentNote.children[_.findIndex(parentNote.children, { id: this.id }) + 1];
    }
    return null;
  }

  getDescendentById(id: NoteId) {
    let found = null;
    _.some(this.children, function iter(child) {
      if (child.id === id) {
        found = child;
        return true;
      }
      return _.some(child.children, iter);
    });
    return found;
  }

  // find children that satisfy an ordered list of conditions, first satisfactory results will be returned
  getFirstDescendentBy(predicates: any[]) {
    let found = null;
    _.find(predicates, (predicate) => {
      found = _.find(this.children, predicate);
      if (!_.isUndefined(found)) return true;
    });
    return found || undefined;
  }

  serialize(): NoteData {
    const refs = {};
    _.each(this.references, (ref: Note, refName: string) => {
      refs[refName] = ref.id;
    });

    return {
      id: this.id,
      name: this.name,
      source: this.source,
      children: _(this.children)
        //.filter((child) => child.virtualOf !== null)
        .map((child) => child.id)
        .value(),
      transformations: this.transformations,
      collapseChildren: this.isChildrenCollapsed,
      refs,
      layout: this.layoutRender,
      formatter: this.formatter,
      shareableLinks: this.shareableLinks,
    };
  }

  flattenDescendents(): Note[] {
    const allNotes: Note[] = [this];
    _.each(this.children, (child) => {
      allNotes.push.apply(allNotes, child.flattenDescendents());
    });
    return allNotes;
  }

  isEditable(): boolean {
    return this.domRef?.isContentEditable;
  }
}
