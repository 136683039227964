import React, { memo, useCallback, useEffect, useState } from 'react';
import { CommandActionType, CommandParams } from './NotebookView.onCommand';
import NoteActions from '../core/NoteActions';
import { copyNote as copyNoteAction } from '../store/notebook/actions';
import { addShareableLinkAction } from '../store/notebook/addShareableLinkAction';
import { removeShareableLinkAction } from '../store/notebook/removeShareableLinkAction';
import { connect } from 'react-redux';
import Note from '../core/Note';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';

interface NoteSettingsActionButtonsProps {
  note: Note;
  onCommand: (command: CommandParams) => void;
  noteToCopy: Note;
  copyNote: (note: Note) => void;
}
const useStyles = makeStyles({
  buttons: {
    '&.confirm_delete': {
      ...theme.controlButtonDanger,
    },
  },
});

const CommandButtons = (props: { onCommand: (command: CommandParams) => void; note: Note }) => {
  const note = props.note;
  const isDisabled = (type) => {
    switch (type) {
      case CommandActionType.INDENT:
        return !note.getPrevSibling();
      case CommandActionType.OUTDENT:
        return !(note.parent && note.parent.parent);
      case CommandActionType.CTRL_ARROW_UP:
        return note.parent.isRoot() && _.findIndex(note.parent.children, { id: note.id }) === 0;
      case CommandActionType.CTRL_ARROW_DOWN:
        return !NoteActions.getNextNote(note);
    }
  };

  const handleClick = useCallback(
    (action: CommandActionType) => () => {
      props.onCommand({ note, action, offset: null });
    },
    [note, props]
  );

  return (
    <>
      <button
        disabled={isDisabled(CommandActionType.CTRL_ARROW_UP)}
        onClick={handleClick(CommandActionType.CTRL_ARROW_UP)}
      >
        &uarr;
      </button>
      <button
        disabled={isDisabled(CommandActionType.CTRL_ARROW_DOWN)}
        onClick={handleClick(CommandActionType.CTRL_ARROW_DOWN)}
      >
        &darr;
      </button>
      <button disabled={isDisabled(CommandActionType.INDENT)} onClick={handleClick(CommandActionType.INDENT)}>
        &rarr;
      </button>
      <button disabled={isDisabled(CommandActionType.OUTDENT)} onClick={handleClick(CommandActionType.OUTDENT)}>
        &larr;
      </button>
    </>
  );
};

const NoteSettingsActionButtons = (props: NoteSettingsActionButtonsProps) => {
  const { note, onCommand, noteToCopy, copyNote } = props;
  const [showPasteButton, setShowPasteButton] = useState(!!noteToCopy);
  const [deleteConfirmed, setDeleteConfirmed] = useState(undefined);

  const handleCloneBtnClick = useCallback(() => {
    onCommand({ note, action: CommandActionType.CLONE, offset: null });
  }, [note, onCommand]);

  const handleCopyBtnClick = useCallback(() => {
    copyNote(note);
    setShowPasteButton(true);
  }, [copyNote, note]);

  const handlePasteBtnClick = useCallback(() => {
    onCommand({
      note,
      action: CommandActionType.PASTE,
      offset: null,
      specifics: { copiedNote: NoteActions.getCloneNote(noteToCopy) },
    });
  }, [note, onCommand, noteToCopy]);

  const handleDeleteBtnClick = useCallback(
    (confirmed) => () => {
      if (confirmed) {
        setDeleteConfirmed(undefined);
        onCommand({ note, action: CommandActionType.REMOVE, offset: null });
      } else {
        setDeleteConfirmed(false);
      }
    },
    [onCommand, note]
  );

  const styles = useStyles();

  useEffect(() => {
    setDeleteConfirmed(undefined);
  }, [note]);
  return (
    <>
      <button
        onClick={handleDeleteBtnClick(deleteConfirmed === false)}
        className={classNames(styles.buttons, deleteConfirmed === false ? 'confirm_delete' : '')}
      >
        {deleteConfirmed === false ? 'confirm' : 'delete'}
      </button>
      <button onClick={handleCloneBtnClick}>clone</button>
      <button onClick={handleCopyBtnClick}>copy</button>
      {showPasteButton && <button onClick={handlePasteBtnClick}>paste</button>}
      <CommandButtons note={note} onCommand={onCommand} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    noteToCopy: state.notebook.noteToCopy,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    copyNote: (note) => dispatch(copyNoteAction(note)),
    addShareableLink: (note) => dispatch(addShareableLinkAction(note)),
    removeShareableLink: (note, linkId) => dispatch(removeShareableLinkAction(note, linkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(NoteSettingsActionButtons));
