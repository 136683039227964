import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  root: {}
});

export interface ChatIconProps {
  className?: string;
}

const ChatIcon = ({ className }: ChatIconProps) => {
  const styles = useStyles();
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.root, className)}
    >
      <rect x="0.5" y="0.5" width="13" height="8.33333" rx="3.5" stroke="currentColor" />
      <path
        d="M1.60986 11.1156C3.34079 9.20285 2.5 8.5 2.5 8.5L7.00003 9.33333C7.00003 9.33333 0.583357 12.25 1.60986 11.1156Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ChatIcon);
