import React, { memo, useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import Note from '../core/Note';
import NoteChildren from './NoteChildren';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';
import '../../node_modules/reveal.js/dist/reset.css';
import '../../node_modules/reveal.js/dist/reveal.css';
import '../../node_modules/reveal.js/dist/theme/black.css';
import Reveal from 'reveal.js';
import { LayoutRenderFactory2 } from '../layoutRenderers/LayoutRenderFactory2';

const useStyles = makeStyles({
  children: {
    position: 'relative',
    paddingLeft: 0,
  },
  note: {
    position: 'relative',
  },
  parent: {
    opacity: 0.4,
  },
  noteSelfFocused: {
    borderRadius: 4,
    backgroundColor: theme.colors.notePreviewBg,
  },
  level0note: {
    margin: '0 auto',
  },
  level0self: {
    marginTop: theme.basicVerticalSpacing * 2,
    position: 'relative',
    '& .note-value > .value': {
      fontSize: 24,
      fontWeight: 700,
    },
    '& > .note-actions': {
      paddingTop: 6,
    },
  },
  level1self: {},
  noteFeatures: {
    width: 'calc(100% - 40px)',
  },
  noteValue: {},
});

export interface NoteViewSlidesProps {
  note: Note;
  className?: string;
}

export interface NoteViewSlidesState {
  leaf: boolean;
  level: number;
}

const NoteViewSlides2 = (props: NoteViewSlidesProps) => {
  const revealRoot = useRef(null);

  useEffect(() => {
    const r = new Reveal(revealRoot.current, {
      controls: true,
      progress: true,
      center: true,
      embedded: true,
      controlsTutorial: false,
      slideNumber: 'c/t',
      history: true,
      hash: false,
    });

    r.initialize();

    return () => {};
  }, [props.note.layoutRender?.name]);

  return (
    <div ref={revealRoot} className="reveal reveal-viewport embedded">
      <LayoutRenderFactory2 mode="slides" note={props.note} />
    </div>
  );
};

export default NoteViewSlides2;
