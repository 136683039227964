import classNames from "classnames";
import React, {memo, useCallback, useEffect, useState, useRef} from "react";
import _ from "lodash";
import {makeStyles} from "@material-ui/styles";

interface Item {
	itemId: string | number;
	value: string;
}
type SearchType = 'contains' | 'starts-with';

interface LookupProps {
	items: Item[];
	isOpen?: boolean;
	cssClasses?: string[];
	selectedItem?: Item;
	disabled?: boolean;
	limit?: number;
	searchType?: SearchType;
	onItemSelected: (i: Item) => void;
}

const useStyles = makeStyles({
	lookup: {
		'& .items': {
			'& .hilite, & :hover': {
				backgroundColor: '#add8e6', // just a sample color
				cursor: 'pointer'
			},
			'& .search-term': {
				'font-weight': 'bold'
			},
			position: 'absolute',
			'z-index': 1,
			top: '30px',
			padding: '3px',
			background: '#fff',
			width: '100%',
			border: 'solid 1px',
		},
		'& input': {
			height: '25px',
			width: '100%'
		},
		position: 'relative'
	}
});

const LookupSearchResult = (props: {value: string, term: string, type: SearchType}) => {

	const valueParts = props.value.split(props.term);

	const shouldHitliteSearchTerm = (ind: number) => {
		switch (props.type) {
			case "starts-with":
				return ind === 0;
			case "contains":
			default:
				return ind < valueParts.length - 1;
		}
	};
	return (
		<>
			{
				_.map(valueParts, (part, i) => {
					return (
						<span key={i}>
							<>{part}</>
							{shouldHitliteSearchTerm(i) && <span className="search-term">{props.term}</span>}
						</span>
					)
				})
			}
		</>
	)
};

const Lookup = (props: LookupProps) => {
	const {
		items,
		isOpen,
		cssClasses,
		selectedItem,
		disabled,
		limit,
		searchType,
		onItemSelected
	} = props;
	let inputRef;
	const styles = useStyles();
	const [showList, setShowList] = useState(false);
	const [itemList, setItemList] = useState([]);
	const [inputValue, setInputValue] = useState(selectedItem && selectedItem.value || '');
	const [clickedItem, setClickedItem] = useState(undefined);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const prevInputValue = useRef(inputValue);

	let itemsNameMapping = {};
	items.forEach(item => {
		itemsNameMapping[item.value] = item;
	});

	const filterItemList = (value: string) => {
		setItemList(_.filter(items, (item) => {
			switch (searchType) {
				case "starts-with":
					return item.value.indexOf(value) === 0;
				case "contains":
				default:
					return item.value.indexOf(value) > -1
			}
		}).slice(0, limit));
	};

	const handleBlur = useCallback((ev: React.FocusEvent) => {
		showList && setShowList(false);
	}, [showList]);

	const handleInputChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
		const value = ev.target.value;
		setInputValue(value);
		filterItemList(value);
	}, []);

	const handleInputFocus = useCallback(() => {
		setShowList(true);
	}, []);

	const handleInputKeyDown = useCallback((ev: React.KeyboardEvent) => {
		if(!showList && ev.key === 'ArrowDown' && itemList.length)
			setShowList(true);
		else if(showList) {
			const index = selectedIndex;
			switch (ev.key) {
				case 'ArrowDown':
					if (index < itemList.length - 1)
						setSelectedIndex(index + 1);
					break;
				case 'ArrowUp':
					if (index > -1)
						setSelectedIndex(index - 1);
					break;
				case 'Enter':
					if (index > -1 && selectedIndex < itemList.length) {
						setClickedItem(itemList[index]);
						setShowList(false);
					}
					break;
				case 'Escape':
					(clickedItem || selectedItem ) && setInputValue((clickedItem || selectedItem).value);
					setShowList(false);
					break;
			}
		}

	}, [selectedIndex, itemList, showList]);

	const handleItemClick = useCallback((item: Item) => (ev: React.MouseEvent<HTMLDivElement>) => {
		setClickedItem(item);
		setShowList(false);
	}, []);

	useEffect(() => {
		if(!showList) {
			if (clickedItem) {
				setInputValue(clickedItem.value);
				onItemSelected(clickedItem);
				setClickedItem(undefined);
			}
			else {
				if (prevInputValue.current !== inputValue && (itemsNameMapping[inputValue] || inputValue === '')) {
					prevInputValue.current = inputValue;
					onItemSelected(itemsNameMapping[inputValue]);
				}
			}
		}
		else{
			filterItemList(clickedItem && clickedItem.value || inputValue);
		}
	}, [showList]);

	useEffect(() => {
		if (isOpen)
			inputRef.focus();
	}, [isOpen]);

	return (
		<div tabIndex={0} className={classNames(styles.lookup, ...(cssClasses || []))} onBlur={handleBlur}>
			<input
				ref={r=>inputRef=r}
				value={inputValue}
				disabled={disabled}
				placeholder="Type to search..."
				onChange={handleInputChange}
				onFocus={handleInputFocus}
				onKeyDown={handleInputKeyDown}
			/>
			{
				showList && <div className={classNames('items')}>
					{
						_.map(itemList, (item, ind) => {
							return <div
								key={item.itemId}
								onMouseDown={handleItemClick(item)} // need to use mouseDown because it fires before blur of the parent
								className={classNames(selectedIndex === ind ? 'hilite' : '')}
							>
								<LookupSearchResult value={item.value} term={inputValue} type={searchType}/>
							</div>
						})
					}
				</div>
			}

		</div>
	);
};

export default memo(Lookup);
