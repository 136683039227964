import firebase from 'firebase';
import { RawNotebookAction, ActionType } from './state';

export const initializeRawNotebookAction = (notebookId: string) => {
    return async (dispatch) => {

        const loadingAction: RawNotebookAction = {
            type: ActionType.UPDATE_NOTEBOOK,
            notebookId: notebookId,
            loading: true,
        };
        dispatch(loadingAction);

        const data = await loadFromFireStore(notebookId);

        const readyAction: RawNotebookAction = {
            type: ActionType.UPDATE_NOTEBOOK,
            data: data,
            loading: false,
        };
        dispatch(readyAction);
    };
};

async function loadFromFireStore(notebookId) {
    const firestore = firebase.firestore();
    const notebook = await firestore.collection('notebooks').doc(notebookId).get();
    return notebook.data()
}