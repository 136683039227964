import React, { memo, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import _ from 'lodash';
import { theme } from '../../../shared/theme';
import ControlButton from './ControlButton';
import TriangleIcon from '../icons/TriangleIcon';
type ControlGroupSwitchOptionValue = string | number;
type ControlGroupSwitchOption = { label: string; value: ControlGroupSwitchOptionValue };

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 auto',
  },
  blockStyle: {
    alignItems: 'stretch',
    '& > label': {
      display: 'flex',
      alignItems: 'center',
      ...theme.controlButtonSecondary,
      paddingRight: theme.buttonPaddingSides,
      paddingLeft: theme.buttonPaddingSides,
      marginBottom: 0,
      transition: 'all 0.3s ease-out',
      // '&:hover': {
      //   color: theme.colors.lightBgText,
      //   ...theme.colors.btnSecondary,
      //   backgroundColor: '#F9F9F9',
      // },
      '&.selected': {
        ...theme.colors.btnSecondarySelected,
        // color: theme.colors.lightBgText,
        cursor: 'default',
        '&:hover': {
          ...theme.colors.btnSecondary,
        },
      },
      '&:first-child': {
        borderRadius: '4px  0 0 4px',
      },
      '&:last-child': {
        borderRadius: '0  4px  4px 0',
      },
    },
  },
  linedStyle: {
    '& > label': {
      paddingRight: theme.buttonPaddingSides,
      paddingLeft: theme.buttonPaddingSides,
      ...theme.controlButtonIcon,
      position: 'relative',
      marginBottom: 0,
      '&.selected': {
        color: theme.colors.lightBgText,
        cursor: 'default',
        '&:hover': {
          backgroundColor: 'inherit',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: -11,
          left: 0,
          right: 0,
          height: 4,
          backgroundColor: theme.colors.noteblRed,
        },
      },
    },
  },
  enabled: {},
  disabled: {},
  label: {},
  input: {
    display: 'none',
  },
  moreOptions: {
    position: 'relative',
    '& > button': {
      height: '100%',
      ...theme.controlButtonSecondary,
      borderRadius: '0 4px 4px 0',
    },
    '&.expanded > button': {
      color: theme.colors.btnSecondary.color,
    },
  },
  moreOptionsOnly: {
    '& > button': {
      borderRadius: 4,
    },
  },
  moreOptionsMenu: {
    position: 'absolute',
    top: '100%',
    display: 'flex',
    flexFlow: 'column',
    width: 100,
    background: 'white',
    boxShadow: '0px 1px 13px -5px #ccc',
    zIndex: 1,
    borderRadius: 5,
    padding: 4,
    '& > label': {
      padding: '5px 10px',
      margin: 0,
      ...theme.typography.app.button,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.colors.btnSecondaryHover.backgroundColor,
      },
      '&.selected': {
        backgroundColor: theme.colors.btnSecondary.backgroundColor,
      },
      '&:not(.selected)': {
        color: theme.colors.btnSecondary.color,
      },
    },
  },
});

export interface ControlGroupSwitchProps {
  name: string;
  options: ControlGroupSwitchOption[];
  moreOptions?: ControlGroupSwitchOption[];
  moreOptionsWidth?: number;
  onChange: (event: any) => void;
  defaultValue?: string | number;
  className?: string;
  disabled?: boolean;
  displayStyle?: 'lined' | 'blocked';
}

const ControlGroupSwitch = ({
  name,
  className,
  options,
  moreOptions,
  moreOptionsWidth,
  onChange,
  defaultValue,
  disabled,
  displayStyle,
}: ControlGroupSwitchProps) => {
  const styles = useStyles();
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const handleClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue: ControlGroupSwitchOptionValue = event.target.value;
      onChange(event);
      setSelectedOption(newValue);
      setShowMoreOptions(false);
    },
    [onChange]
  );

  return (
    (!_.isEmpty(options) || !_.isEmpty(moreOptions)) && (
      <div
        className={classNames(
          styles.root,
          className,
          _.toBoolean(disabled) ? styles.disabled : styles.enabled,
          displayStyle === 'blocked' ? styles.blockStyle : styles.linedStyle
        )}
        data-default={selectedOption}
      >
        {!_.isEmpty(options) &&
          options.map((option) => (
            <label key={option.value} className={classNames(option.value === selectedOption ? 'selected' : '')}>
              <input
                type="radio"
                name={name}
                value={option.value}
                checked={option.value === selectedOption}
                className={styles.input}
                onChange={handleClick}
              />
              <span className={styles.label}>{option.label}</span>
            </label>
          ))}
        {!_.isEmpty(moreOptions) && (
          <div
            className={classNames(
              styles.moreOptions,
              _.isEmpty(options) && !_.isEmpty(moreOptions) && styles.moreOptionsOnly,
              showMoreOptions && 'expanded'
            )}
          >
            <ControlButton endIcon={<TriangleIcon />} onClick={(event) => setShowMoreOptions(!showMoreOptions)} />
            {showMoreOptions && (
              <div className={styles.moreOptionsMenu} style={{ width: moreOptionsWidth ? moreOptionsWidth : 'auto' }}>
                {moreOptions.map((option) => (
                  <label key={option.value} className={classNames(option.value === selectedOption ? 'selected' : '')}>
                    <input
                      type="radio"
                      name={name}
                      value={option.value}
                      checked={option.value === selectedOption}
                      className={styles.input}
                      onChange={handleClick}
                    />
                    <span className={styles.label}>{option.label}</span>
                  </label>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    )
  );
};

export default memo(ControlGroupSwitch);
