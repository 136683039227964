import React, { memo } from 'react';

const CloseIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L8 8M15 15L8 8M8 8L15 1M8 8L1 15" stroke="currentColor" />
    </svg>
  );
};

export default memo(CloseIcon);
