import _ from 'lodash';
import shortid from 'shortid';
import Note from './Note';

let _copiedNote: Note;
const copiedNote = (value?: Note) => {
  if (value !== undefined) _copiedNote = value;
  return _copiedNote;
};

export const remove = (note: Note, mergeIntoPreviousSibling?: boolean) => {
  const parent = note.parent;

  if (parent) {
    const deleteIndex = _.findIndex(parent.children, ['id', note.id]);

    if (mergeIntoPreviousSibling) {
      if (note.source && _.isEmpty(note.children) && deleteIndex > 0) {
        const prevNote = parent.children[deleteIndex - 1];
        prevNote.source += note.source.toString();
      } else {
        return;
      }
    }

    // TODO: if it's being referred, give a warning to the user and delete all refs.

    parent.children.splice(deleteIndex, 1);

    // TODO: remove from notebook too

    parent.recompute();
  }
};

export const createNext = (note: Note, newSource?: string) => {
  const newNote = new Note({
    id: shortid.generate(),
    source: newSource,
  });

  if (note.isRoot() || (!note.isLeaf() && newSource === '')) {
    newNote.setParent(note);
    note.children = _.concat(newNote, note.children || []);
    note.recompute();
  } else {
    const parent = note.parent;
    newNote.setParent(parent);
    const index = _.findIndex(parent.children, { id: note.id });
    if (!_.isEmpty(note.children)) {
      const prevSibling = parent.children[index];
      if (!_.isEmpty(prevSibling.children)) {
        newNote.children = prevSibling.children.slice();
        prevSibling.children.length = 0;
        _.each(newNote.children, (child) => child.setParent(newNote));
      }
      parent.children.splice(index + 1, 0, newNote);
    } else {
      parent.children.splice(index + 1, 0, newNote);
    }
    parent.recompute();
  }

  return newNote;
};

export const toggleCollapse = (note: Note) => {
  note.isChildrenCollapsed = !note.isChildrenCollapsed;
};

export const tab = (note: Note) => {
  const parent = note.parent;
  const prevSibling = note.getPrevSibling();
  if (prevSibling) {
    prevSibling.isChildrenCollapsed = false;
    // add current note as the last child of the previous(above) sibling
    parent.children = _.without(parent.children, note);
    prevSibling.children = _.concat(prevSibling.children || [], note);
    note.setParent(prevSibling);
    parent.recompute();
  }
};

export const shiftTab = (note: Note) => {
  if (note.parent && note.parent.parent) {
    // make the current note as a child of its grand parent, after the parent
    const parent = note.parent;
    const grandParent = parent.parent;
    const parentIndex = _.findIndex(grandParent.children, ['id', parent.id]);
    grandParent.children.splice(parentIndex + 1, 0, note);
    parent.children = _.without(parent.children, note);
    note.setParent(grandParent);
    grandParent.recompute();
  }
};

export const mergeNext = (note: Note) => {
  // merge next sibling
  const parent = note.parent;

  if (parent) {
    const deleteIndex = _.findIndex(parent.children, { id: note.id }) + 1;
    if (_.isEmpty(note.children) && deleteIndex < parent.children.length) {
      const nextNote = parent.children[deleteIndex];
      note.source += nextNote.source.toString();

      // TODO: if it's being referred by any other notes, give a warning to the user and delete all refs.

      if (!_.isEmpty(nextNote.children)) {
        note.children = nextNote.children.slice();
        nextNote.children.length = 0;
      }
      parent.children.splice(deleteIndex, 1);

      // TODO: remove from notebook too

      parent.recompute();
    }
  }
};

export const mergeIntoPrev = (note: Note) => {
  const parent = note.parent;

  if (parent) {
    const deleteIndex = _.findIndex(parent.children, { id: note.id });
    const prevNote = parent.children[deleteIndex - 1];
    if (deleteIndex > 0 && _.isEmpty(prevNote.children)) {
      prevNote.source += note.source.toString();

      // TODO: if it's being referred by any other notes, give a warning to the user and delete all refs.

      parent.children.splice(deleteIndex, 1);
      if (!_.isEmpty(note.children)) {
        prevNote.children = note.children.slice();
        note.children.length = 0;
      }

      // TODO: remove from notebook too

      parent.recompute();
      return prevNote;
    }
  }
};

export const getNextNote = (note: Note, noteIndex?: number, goToNextSibling?: boolean) => {
  const parent = note?.parent;
  let nextNote: Note;
  if (parent) {
    const index = noteIndex || _.findIndex(parent.children, { id: note.id });
    if (!_.isEmpty(note.children) && !goToNextSibling) {
      if (note.isChildrenCollapsed) nextNote = parent.children[index + 1];
      else nextNote = note.children[0];
    } else if (index === parent.children.length - 1) {
      nextNote = getNextNote(parent, undefined, true);
    } else {
      nextNote = parent.children[index + 1];
    }
    return nextNote || getNextNote(nextNote);
  } else if (note && note.isRoot && !_.isEmpty(note.children) && !goToNextSibling) {
    return note.children[0];
  }
};

export const getPrevNote = (note: Note, noteIndex?: number): Note => {
  const parent = note.parent;
  let prevNote;
  if (parent) {
    const index = noteIndex || _.findIndex(parent.children, { id: note.id });
    if (index === 0 && !note.isRoot()) prevNote = parent;
    else {
      prevNote = parent.children[index - 1];
      if (prevNote && !_.isEmpty(prevNote.children)) prevNote = getLastDeepestChild(prevNote);
    }

    return prevNote || getPrevNote(prevNote);
  }
};

export const getPrevSibling /*could be a cousin*/ = (note: Note, noteIndex?: number) => {
  const parent = note.parent;
  if (parent.children[noteIndex - 1]?.children?.length && parent.children[noteIndex - 1].isChildrenCollapsed) {
    return { note: parent.children[noteIndex - 1], index: noteIndex - 1 };
  }

  const prevNote = getPrevNote(note, noteIndex);
  if (!prevNote.isRoot() && prevNote === parent) {
    const parentsIndex = _.findIndex(parent.parent?.children, { id: parent.id });
    const parentsPrevSibling = parent.parent.children[parentsIndex - 1];
    if (parentsPrevSibling?.children?.length) {
      if (parentsPrevSibling.isChildrenCollapsed) {
        return { note: parentsPrevSibling, index: parentsIndex - 1 };
      }
      const parentsPrevNote = getPrevNote(parent, parentsIndex);
      return { note: parentsPrevNote, index: parentsPrevNote.parent.children.length };
    }
    return { note: note.parent, index: parentsIndex };
  }
  return !prevNote?.isRoot() && { note: prevNote, index: _.findIndex(prevNote.parent.children, { id: prevNote.id }) };
};

export const getNextSibling /*could be a cousin*/ = (note: Note, noteIndex?: number) => {
  if (note.children?.length) {
    if (note === note.root) return {};
    const index = noteIndex || _.findIndex(note.parent.children, { id: note.id });
    let nextSibling = note.parent.children[index + 1];
    if (!nextSibling) return getNextSibling(note.parent) || {};
    if (nextSibling.children?.length) {
      if (nextSibling.isChildrenCollapsed) {
        return { note: nextSibling, index: index + 1 };
      }
      return { note: nextSibling.children[0], index: 0 };
    }
    return { note: nextSibling, index: index + 1 };
  } else {
    const nextNote = getNextNote(note, noteIndex);
    if (nextNote?.children?.length) return { note: nextNote.children[0], index: 0 };
    return nextNote && { note: nextNote, index: _.findIndex(nextNote.parent.children, { id: nextNote.id }) };
  }
};

export const move = (note: Note, direction: 'up' | 'down') => {
  const getSibling = (dir) => {
    switch (dir) {
      case 'up':
        return getPrevSibling;
      case 'down':
        return getNextSibling;
    }
  };

  const parent = note.parent;
  if (parent) {
    const index = _.findIndex(parent.children, { id: note.id });
    let { note: siblingNote, index: siblingIndex } = getSibling(direction)(note, index) || {};
    if (siblingNote) {
      while (siblingNote.virtualOf) {
        // FIXME: skip virtual notes from getNextSibling() and getPrevSibling()
        const sibling = getSibling(direction)(siblingNote, siblingIndex) || {};
        siblingNote = sibling.note;
        siblingIndex = sibling.index;
      }
      note.parent.children.splice(index, 1);
      note.setParent(siblingNote.parent);
      siblingNote.parent.isChildrenCollapsed = false;
      siblingNote.parent.children.splice(siblingIndex, 0, note);
      note.parent.recompute();
      siblingNote.parent.recompute();
    }
  }
};

export const insertAfter = (noteToInsert: Note, insertAfter: Note) => {
  const parent = insertAfter.parent;
  if (parent) {
    const index2 = _.findIndex(parent.children, { id: insertAfter.id });
    noteToInsert.setParent(parent);
    parent.children.splice(index2 + 1, 0, noteToInsert);
    parent.recompute();
  }
};

const getCloneNote = (note: Note) => {
  const clone = _.cloneDeep(note);
  clone.id = shortid.generate();
  clone.children = [];

  _.each(note.children, (child) => {
    if (!child.virtualOf) {
      const childClone = getCloneNote(child);
      childClone.updateReferences();
      clone.children.push(childClone);
    }
  });
  return clone;
};

export const clone = (note: Note) => {
  const parent = note.parent;
  if (parent) {
    const clone = getCloneNote(note);
    insertAfter(clone, note);
    return clone;
  }
};

function getLastDeepestChild(note: Note) {
  if (!note.isChildrenCollapsed && !_.isEmpty(note.children))
    return getLastDeepestChild(note.children[note.children.length - 1]);
  return note;
}

function traverseDown<T>(note: Note, visitor: (note: Note) => T): T[] {
  const item = visitor(note);
  const children = _.flatMap(note.children, (child) => traverseDown(child, visitor));
  return [item, ...children];
}

function addToEventBus(note, eventBus) {
  traverseDown(note, (note) => note).forEach((note) => eventBus.init(note));
}

export const summarize = (note: Note) => {
  return {
    noteId: note.id,
    name: note.source.toString(),
    summary: _(note.children)
      .map((i) => i.source.toString())
      .reduce((acc, i) => `${acc}/${i}`, ''),
  };
};

export default {
  remove,
  createNext,
  toggleCollapse,
  tab,
  shiftTab,
  mergeNext,
  mergeIntoPrev,
  getNextNote,
  getPrevNote,
  move,
  insertAfter,
  clone,
  getCloneNote,
  traverseDown,
  addToEventBus,
  copiedNote,
  summarize,
};
