import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { If } from 'react-if';
import classNames from 'classnames';
import NoteViewOutline, { NoteViewOutlineRef } from './NoteViewOutline';
import NoteViewRead2 from './NoteViewRead2';
import NoteViewSlides2 from './NoteViewSlides2';
import NotebookSidePanel from './NotebookSidePanel';
import NotebookActions from './NotebookActions';
import { connect } from 'react-redux';
import Note from '../core/Note';
import Notebook from '../core/Notebook';
import { CommandParams, onCommand as notebookViewOnCommand } from './NotebookView.onCommand';
import { makeStyles } from '@material-ui/styles';
import { isUndefined } from 'lodash';
import { NotebookContext } from '../NotebookContext';
import Utils from '../utils/Utils';
import HidingPageHeader from './HidingPageHeader';
import { saveNotebookAction } from '../store/notebook/saveNotebookAction';
import { NeverResolved } from '../transformations2/eventBus';
import { filter } from 'rxjs/internal/operators/filter';

const useStyles = makeStyles({
  panels: {
    minWidth: 300,
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
    height: 'calc(100vh - 40px)',
    '& > .panel': {
      flex: '1 1 50%',
      overflowY: 'scroll',
      paddingLeft: 20,
      paddingRight: 20,
      '&:not(:last-child)': {
        borderRight: '1px solid rgba(0, 0, 0, 0.05)',
      },
    },
  },
  panelOutline: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    paddingBottom: 100,
    '& > *': {
      maxWidth: 800,
      width: '100%',
      boxSizing: 'border-box',
    },
  },
  panelRight: {},
  notebookContainer: {},
  notebookAndSettings: {},
  notebook: {
    width: 'inherit',
  },
});

interface NotebookViewProps {
  notebook: Notebook;
  renderFromNote: Note;
  page?: string; // page notebook is rendered on
  linkId?: string;
  mode?: NotebookViewMode;
  maxLevel?: number;
  embedded?: boolean; // notebook is used in another page, for example on shared link page
  editable?: boolean;
  authenticated: boolean;
  saveNotebook?: () => void;
}

interface NotebookViewState {
  page: string;
  mode: NotebookViewMode;
  focusedNote: Note;
  offset: number;
  editedNote?: Note;
}

const NotebookView = (props: NotebookViewProps) => {
  const { renderFromNote, page, mode, embedded, saveNotebook } = props;

  const notebookEditable = !isUndefined(props.editable) ? props.editable : !embedded || false;

  // eslint-disable-next-line
  const [state, setState] = useState<NotebookViewState>({
    page: page,
    mode: mode,
    focusedNote: renderFromNote,
    offset: null,
  });

  const { eventBus } = useContext(NotebookContext);

  const handleModeChange = (event) => {
    const modeToSet: NotebookViewMode = event.target.value;
    setState({ ...state, mode: modeToSet });
  };

  const [showNoteSettings, setShowNoteSettings] = useState(false);

  const history = useHistory();
  const zoomNote = (note: Note) => {
    const page = state.page === 'link' ? '/link' : state.page === 'notebook' ? '/notebook' : `/${state.page}`;
    const sub = state.page === 'link' ? `/${props.linkId}` : state.page === 'notebook' ? `/${props.notebook.id}` : '';
    history.push(page + sub + `/${note.id}`);
  };

  const focusNote = ({ note, offset }) => {
    setState((prevState) => ({ ...prevState, focusedNote: note, offset: offset }));
  };

  const collapseNote = (note: Note) => {
    if (note) {
      note.isChildrenCollapsed = !note.isChildrenCollapsed;
      if (note.isChildrenCollapsed) {
        setState((prevState) => ({ ...prevState, focusedNote: note }));
      } else {
        setState((prevState) => ({ ...prevState }));
      }
    }
  };

  const toggleNoteSettings = useCallback(() => {
    setShowNoteSettings(!showNoteSettings);
  }, [showNoteSettings]);

  const noteViewOutlineRef = useRef<NoteViewOutlineRef>();

  function onCommand(params: CommandParams) {
    const { note, offset } = notebookViewOnCommand(params, eventBus);
    if (note) {
      setState((prevState) => ({ ...prevState, focusedNote: note, offset: offset }));
      eventBus.update(note);
    } else {
      setState((prevState) => ({ ...prevState }));
    }
  }

  useEffect(() => {
    if (state.offset !== null && state.offset !== undefined) {
      if (!state.focusedNote.isEditable()) {
        state.focusedNote.domRef?.click();
      } else {
        noteViewOutlineRef.current?.focus(state.focusedNote, state.offset);
      }
    }
  }, [state.focusedNote, state.focusedNote.parent, state.offset]);

  useEffect(() => {
    const observable = eventBus.observable(renderFromNote.id);
    const subscription = observable.pipe(filter((u) => u !== NeverResolved)).subscribe((unit) => {
      document.title = Utils.sanitize(unit.data.toString(), {
        allowedTags: [],
        allowedAttributes: [],
      });
    });

    return () => subscription.unsubscribe();
  }, [eventBus, renderFromNote]);

  const styles = useStyles();

  const formatterSelected = ({ name, params }) => {
    const formatter = state.focusedNote.formatter;
    formatter.name = isUndefined(name) ? formatter.name : name;
    formatter.params = params || formatter.params;
    formatter.disabled = false;
    setState((prevState) => ({ ...prevState }));
  };

  const layoutRenderSelected = ({ name, params }) => {
    const layoutRender = state.focusedNote.layoutRender;
    layoutRender.name = isUndefined(name) ? layoutRender.name : name;
    layoutRender.params = params || layoutRender.params;
    layoutRender.disabled = false;
    setState((prevState) => ({ ...prevState }));
  };

  return (
    <>
      <HidingPageHeader hideScrollPoint={40} disabled={true}>
        <NotebookActions
          editable={notebookEditable}
          embedded={embedded}
          authenticated={props.authenticated}
          saveChange={saveNotebook}
          onModeChange={handleModeChange}
          defaultMode={state.mode}
          showSidePanel={showNoteSettings}
          setShowSidePanel={toggleNoteSettings}
        />
      </HidingPageHeader>
      <div className={classNames('notebook-and-settings', styles.notebookAndSettings)}>
        <div className={classNames('notebook', styles.notebook)}>
          <div className={styles.panels}>
            <If
              condition={
                state.mode === 'outline' || state.mode === 'outline + read' || state.mode === 'outline + slides'
              }
            >
              <NoteViewOutline
                ref={noteViewOutlineRef}
                notebook={props.notebook}
                note={props.renderFromNote}
                level={0}
                focusedNote={state.focusedNote}
                onSourceUpdate={({ note, source }) => {
                  note.source = source;
                  eventBus.pushValue({ id: note.id, source });
                  eventBus.update(note);
                  setState((prevState) => ({ ...prevState }));
                }}
                onCommand={onCommand}
                focusNote={focusNote}
                zoomNote={zoomNote}
                collapseNote={collapseNote}
                className={classNames('panel', styles.panelOutline)}
              />
            </If>

            <If condition={state.mode === 'outline + read' || state.mode === 'read'}>
              <NoteViewRead2
                collapseNote={collapseNote}
                zoomNote={zoomNote}
                note={props.renderFromNote}
                topNote={props.renderFromNote}
                className={classNames('panel', styles.panelRight)}
              />
            </If>
            <If condition={state.mode === 'outline + slides' || state.mode === 'slides'}>
              <NoteViewSlides2 note={props.renderFromNote} className="panel" />
            </If>
          </div>
        </div>
        {!embedded && showNoteSettings && (
          <NotebookSidePanel
            note={state.focusedNote}
            showSidePanel={showNoteSettings}
            isRoot={props.renderFromNote.id === state.focusedNote.id}
            toggleSidePanel={toggleNoteSettings}
            layoutRenderSelected={layoutRenderSelected}
            formatterSelected={formatterSelected}
            zoomNote={zoomNote}
            updateNote={(focusedNote) => {
              eventBus.update(focusedNote);
              //setState({ ...state, focusedNote });
            }}
            onCommand={onCommand}
          />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveNotebook: () => dispatch(saveNotebookAction()),
});

export default connect(null, mapDispatchToProps)(NotebookView);
