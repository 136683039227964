import React, { useEffect, useState, useContext } from 'react';
import BarchartHorizontalFormatter from './BarchartHorizontalFormatter';
// import TableFormatter from './TableFormatter';
import ImageFormatter from './ImageFormatter';
import BarchartVerticalFormatter from './BarchartVerticalFormatter';
import LinechartFormatter from './LinechartFormatter';
import DefaultFormatter from './DefaultFormatter';
import { keys } from 'lodash';
import { Unit, NeverResolved } from '../transformations2/eventBus';
import Note from '../core/Note';
import { NotebookContext } from '../NotebookContext';
import TableFormatter2 from './TableFormatter2';
import FormatNumber from './FormatNumber';
import LinkTo from './LinkTo';


export type FormaterProps = { note: Note; unit: Unit };
type Formater = (props: FormaterProps) => React.ReactElement;

const formatters: { [key: string]: Formater } = {
  'Default': DefaultFormatter,
  'Barchart Horizontal': BarchartHorizontalFormatter,
  'Barchart Vertical': BarchartVerticalFormatter,
  // 'Barchart Vertical': DefaultFormatter,
  'Table': TableFormatter2,
  'Image': ImageFormatter,
  'Linechart': LinechartFormatter,
  // 'Linechart': DefaultFormatter,
  'Format Number': FormatNumber,  
  'Link To Note': LinkTo,  
};

export const FormatterFactory2 = (props: { note: Note; formatter?: { name; params } }) => {
  const formatter = props.formatter ?? props.note.formatter;
  const Formatter = formatters[formatter?.name] || DefaultFormatter;
  const { eventBus } = useContext(NotebookContext);
  const [unit, setUnit] = useState(eventBus.observable(props.note.id).value);

  useEffect(() => {
    const subscription = eventBus.observable(props.note.id).subscribe((unit) => {
      setUnit(unit);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [eventBus, props.note.id]);

  return unit === NeverResolved ? <div>Calculating...</div> : <Formatter note={props.note} unit={unit} />;
};

export const Formatters = keys(formatters);
