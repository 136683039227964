// import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

const parseNumber = (x: string | number) => (+x === x ? x : Number(x.toString().replace(',', '')));

const parseDate = (d: string) => {
  const m = moment(d);
  const invalidAt = m.invalidAt();
  const issue =
    invalidAt === 0
      ? 'year'
      : invalidAt === 1
      ? 'month'
      : invalidAt === 2
      ? 'day'
      : invalidAt === 3
      ? 'hour'
      : invalidAt === 4
      ? 'minute'
      : invalidAt === 5
      ? 'second'
      : invalidAt === 6
      ? 'milliseconds'
      : 'date';
  return m.isValid() ? m : 'Invalid ' + issue;
};

const formatMoment = (m, format: string) => {
  return m.isValid() ? m.format(format) : 'Invalid ' + m.invalidAt();
};

const parseAndFormatDate = (d: string, format: string = 'MM/DD/YYYY') => formatMoment(parseDate(d), format);

const isDate = m => m && typeof m.isValid === 'function' && m.isValid();

const formatNumber = (x: string | number | (string | number)[], format: string = '0,0') => {
  return Array.isArray(x) ? x.forEach(xx => numeral(xx).format(format)) : numeral(x).format(format);
};

export default {
  parseNumber,
  parseDate,
  formatMoment,
  parseAndFormatDate,
  isDate,
  formatNumber,
};
