import React, { useEffect, useState, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { initializeRawNotebookAction } from '../store/rawNotebook/initializeRawNotebookAction';
import { StoreState } from '../store/storeFactory';
import { saveRawNotebookAction } from '../store/rawNotebook/saveRawNotebookAction';
import { makeStyles } from '@material-ui/styles';
import ControlButton from '../components/shared/controls/ControlButton';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  editor: {
    border: 'none',
    boxSizing: 'border-box',
    padding: '40px 20px 20px',
    width: '100vw',
    height: '100vh',
    resize: 'none',
  },
  editorControls: {
    position: 'absolute',
    top: 10,
    right: 20,
  },
  editorNavigation:{
    position: 'absolute',
    top: 10,
    left: 20,
    display: 'flex',
    '& > a': {
      marginRight: 5,
    }
  },
  saveButton: {
    padding: '5px 30px',
  },
});

interface RawNotebookPageProps {
  notebookId: string;
  data: any;
  loading: boolean;
  load: (notebookId: string) => void;
  save: (data: any) => void;
}

type RawNotebookPageState = {
  json: string;
  disabled: boolean;
};

const RawNotebookPage = (props: RawNotebookPageProps) => {
  const [state, setState] = useState<RawNotebookPageState>({
    json: '',
    disabled: true,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      json: JSON.stringify(props.data, null, 2),
    }));
  }, [props.data]);

  useEffect(() => {
    props.load(props.notebookId);
    // eslint-disable-next-line
  }, [props.notebookId]);

  const isValidJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;

    setState((prevState) => ({
      ...prevState,
      json: value,
      disabled: !isValidJson(value),
    }));
  };

  const styles = useStyles();

  return (
    <div>
      <div className={styles.editorNavigation}>
      <Link to="/notebooks">
          <ControlButton
            label="All notebooks"
            importance='secondary'
          />
        </Link>
      <Link to={"/notebook/" + props.notebookId}>
          <ControlButton
            label="This notebook"
            importance='secondary'
          />
        </Link>
    </div>
      <div className={styles.editorControls}>
        <ControlButton
          label="Save"
          onClick={() => props.save(JSON.parse(state.json))}
          disabled={state.disabled}
          importance="primary"
          className={styles.saveButton}
        />
      </div>

      <textarea className={styles.editor} onChange={onChangeHandler} value={state.json} />
    </div>
  );
};

const mapStateToProps = (state: StoreState, ownProps) => {
  const { rawNotebook } = state;

  return {
    notebookId: ownProps.match.params.notebookId,
    data: rawNotebook.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: (notebookId: string) => dispatch(initializeRawNotebookAction(notebookId)),
  save: (notebookId: string) => dispatch(saveRawNotebookAction(notebookId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RawNotebookPage);
