import { Observable, combineLatest } from 'rxjs';
import { map, filter, defaultIfEmpty, distinctUntilChanged } from 'rxjs/operators';
import _ from "lodash";
import { NeverResolved, NoteStream, Unit } from "./eventBus";

function extractExpressions(source: string) {

    return source.match(/\$\{([^\{\}]+)\}/ig) || []
}

function interpolateExpression(expression: string, dependancy: Dependancy[]) {

    const match = expression.match(/\$\{(.+)\}/i);

    const args = _(dependancy).map(i => i.name).value();
    const vals = _(dependancy).map(i => i.unit.data).value();

    try {
        const func = new Function(...args, `return ${ match[1] }`);
        const value = func(...vals); 
        return { successfully: true, value: value }
    }
    catch {
        return { successfully: false, value: null }
    }
}

type Dependancy = {
    name: string,
    unit: Unit,
};

function findDependency(expression: string, namedStreams: NoteStream[]): Observable<Dependancy>[] {

    return _(namedStreams)
        .filter(i => expression.includes(i.name))
        .map(i => i.output.pipe(
            filter(o => o != NeverResolved),
            map(o => ({ unit: o, name: i.name })),
        ))
        .value();
}

function isSingleAndOnlyExpression(source: string) {

    return !!source.match(/^\s*\$\{([^\{\}]+)\}\s*$/ig)
}

function tryParseIntIfFeasible(source: string) {

    const intValue = parseInt(source, 10);
    return isNaN(intValue) ? source : intValue;
}

export function interpolateSource(source: string, namedStreams: NoteStream[]): Observable<any> {

    const expressions = _([source])
        .flatMap(extractExpressions)
        .map(i => ({
            expression: i,
            dependency: findDependency(i, namedStreams),
        }))
        .map(i => combineLatest(i.dependency).pipe(
            defaultIfEmpty([] as Dependancy[]),
            map(units => interpolateExpression(i.expression, units)),
            map(result => ({
                expression: i.expression,
                interpolated: result,
            })),
        ))
        .value();

    return combineLatest(expressions).pipe(
        map(items => {
            if (isSingleAndOnlyExpression(source)) {
                const interpolated = items[0].interpolated;
                return interpolated.successfully ? interpolated.value : source;
            }
            else {
                return _(items)
                    .filter(i => i.interpolated.successfully)
                    .reduce((acc, i) => acc.replace(i.expression, i.interpolated.value), source)
            }
        }),
        defaultIfEmpty(tryParseIntIfFeasible(source)), 
        distinctUntilChanged(),
    )
}