export enum ActionType {
    UPDATE_NOTEBOOK = 'UPDATE_NOTEBOOK',
}

export type Action<TActionType, TPayload> = { readonly type: TActionType } & Partial<TPayload>;
export type RawNotebookAction = Action<ActionType, RawNotebookState>;

export class RawNotebookState {
    notebookId: string;
    data: any;
    loading = true;
}