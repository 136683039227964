import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import NoteblLogo from './NoteblLogo';
import Navigation from './Navigation';
import UserBubble from './UserBubble';
import ControlButton from './shared/controls/ControlButton';

import classNames from 'classnames';
// import { theme } from '../shared/theme';

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    width: 200,
    flex: '0 0 200px',
  },
  navWrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    height: '100vh',
  },
  appBranding: {
    '& .notebl-logo': {
      margin: '12px 20px',
      '& .logo-word': {
        fill: '#FFFFFF',
      },
      '& .logo-lines': {
        fill: '#da0a0a',
      },
    },
  },
  accountLink: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: '16px 20px',
    //  textDecoration: none,
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 13,
  },
  accountSignOut: {
    margin: '8px auto 16px',
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: 'rgba(255,255,255,0.5)',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
      color: 'rgba(255,255,255,0.9)',
    },
  },
});

export interface AppPanelProps {
  className?: string;
  userName: string;
  singOut: () => void;
}

const AppPanel = ({ className, userName, singOut }: AppPanelProps) => {
  const styles = useStyles();
  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.navWrapper}>
        <div className="nav">
          <div id="branding" className={styles.appBranding}>
            <Link to="/notebooks">
              <NoteblLogo />
            </Link>
          </div>
          <Navigation />
        </div>
        <div className="nav">
          <div>
            <Link className={styles.accountLink} to="/">
              <UserBubble initials="SK" />
              <div className="user-name">{userName}</div>
            </Link>
            <ControlButton onClick={singOut} className={styles.accountSignOut}>
              Sign out
            </ControlButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(AppPanel);
