import React, { useState, forwardRef, useImperativeHandle, createRef, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';
import _, { toStringify } from 'lodash';
import NoteEditable, { NoteEditableRef } from './NoteEditable';
import NoteControls from './NoteControls';
import Note from '../core/Note';
import Notebook from '../core/Notebook';
import { CommandParams } from './NotebookView.onCommand';
import { NotebookContext } from '../NotebookContext';

const useStyles = makeStyles({
  children: {
    position: 'relative',
    paddingLeft: 0,
  },
  note: {
    position: 'relative',
  },
  noteSelfFocused: {
    borderRadius: 4,
    backgroundColor: theme.colors.notePreviewBg,
  },
  level0note: {
    margin: '0 auto',
  },
  level0self: {
    marginTop: theme.basicVerticalSpacing * 4,
    position: 'relative',
    '& .note-value > .value': {
      fontSize: 24,
      fontWeight: 700,
    },
    '& > .note-actions': {
      paddingTop: 6,
    },
    '.full-screen &':{
      marginTop: theme.basicVerticalSpacing * 8,
    }
  },
  level1self: {},
  noteFeatures: {
    width: 'calc(100% - 40px)',
  },
  noteValue: {
    '& > .value .error': {
      background: '#ffeee2',
      padding: '0px 5px',
      borderRadius: 4,
      color: '#c13700',
    },
  },
  root: {
    position: 'relative',
  },
});

export interface NoteViewOutlineProps {
  note: Note;
  notebook: Notebook;
  focusedNote: Note;
  level: number;
  focusNote: (params: { note: Note; offset?: number }) => void;
  onCommand: (params: CommandParams) => void;
  zoomNote: (note: Note) => void;
  collapseNote: (note: Note) => void;
  onSourceUpdate: ({ note: Note, source: string }) => void;
  className?: string;
}

export interface NoteViewOutlineState {
  isEditing: boolean;
}

export interface NoteViewOutlineRef {
  focus: (note: Note, offset: number) => void;
}

const NoteViewOutline = (props: NoteViewOutlineProps, ref) => {
  const { notebook, note, className } = props;

  const [state, setState] = useState<NoteViewOutlineState>({
    isEditing: false,
  });

  const styles = useStyles();

  const editableRef = useRef<NoteEditableRef>();

  const reference = _.isEmpty(ref) ? createRef() : ref;
  useImperativeHandle(reference, () => {
    const apiRef = {
      focus: (note, offset) => {
        if (props.note === note) {
          editableRef.current.focus(offset);
        } else {
          _.each(viewOutlineRefs, ({ ref }) => {
            ref.current?.focus(note, offset);
          });
        }
      },
    };
    return apiRef as NoteViewOutlineRef;
  });

  const viewOutlineRefs = _.map(note.children, (childNote: Note) => {
    const ref = createRef<NoteViewOutlineRef>();
    const control = (
      <ForwardRefNoteViewOutline
        key={childNote.id}
        onCommand={props.onCommand}
        focusNote={props.focusNote}
        collapseNote={props.collapseNote}
        focusedNote={props.focusedNote}
        onSourceUpdate={props.onSourceUpdate}
        ref={ref}
        note={childNote}
        notebook={notebook}
        level={props.level + 1}
        zoomNote={props.zoomNote}
      />
    );
    return { ref, control };
  });

  const { eventBus } = useContext(NotebookContext);

  function insertSuggestionReference(params: {
    startOffset: number;
    currentOffset: number;
    note: Note;
    updateCursorPosition: boolean;
  }) {
    const value = toStringify(props.note.source);
    const s1 = value.substring(0, params.startOffset - 1);
    const s2 = value.substring(params.currentOffset);
    props.note.source = `${s1}\${${params.note.name}}${s2}`;
    eventBus.pushValue(props.note);
    if (params.updateCursorPosition) {
      const offset = params.startOffset + params.note.name.length;
      props.focusNote({ note: props.note, offset });
    }
  }

  return (
    <div
      className={classNames('note', styles[`level${props.level}note`], styles.note, className)}
      data-level={props.level}
    >
      <div
        title={note.id}
        className={classNames(
          'note-self',
          styles[`level${props.level}self`],
          props.focusedNote.id === note.id && styles.noteSelfFocused
        )}
      >
        <NoteControls
          onCollapseClick={() => props.collapseNote(props.note)}
          collapsible={!note.isLeaf()}
          closed={note.isChildrenCollapsed}
          onBulletClick={() => props.zoomNote(props.note)}
          hideBullet={false}
        />

        <div className={classNames('note-features', styles.noteFeatures)}>
          <div className={classNames('note-value', styles.noteValue)}>
            {note.name && <span className="name">{note.name}:</span>}

            <NoteEditable
              isEditing={state.isEditing}
              note={props.note}
              insertSuggestionReference={insertSuggestionReference}
              onBlur={() => {
                note.recompute();
                setState({ isEditing: false });
              }}
              onSourceUpdate={(source) => props.onSourceUpdate({ note, source })}
              ref={editableRef}
              onFocus={() => {
                props.focusNote({ note: props.note });
                setState({ isEditing: true });
              }}
              onCommand={props.onCommand}
              className="value"
              notebook={notebook}
            />
          </div>
        </div>
      </div>
      {!note.isChildrenCollapsed && (
        <div className={classNames(styles.root, 'note-children')}>{viewOutlineRefs.map(({ control }) => control)}</div>
      )}
    </div>
  );
};

const ForwardRefNoteViewOutline = forwardRef(NoteViewOutline);
export default ForwardRefNoteViewOutline;
