import React from 'react';
import Note from '../core/Note';
import { makeStyles } from '@material-ui/styles';
import { LayoutRenderFactory2 } from './LayoutRenderFactory2';
import classNames from 'classnames';

const useStyles = makeStyles({

  noteChildren: {
    paddingLeft: 0,
  },
});


const SkipRenderer = (props: LayoutRenderProps) => {
  const { note } = props;
  const styles = useStyles();

  return (
    <div className='hidden' key={note.id} data-note-id={props.note.id}>
      {!note.isChildrenCollapsed && !note.isLeaf() && (
        <div className={classNames('note-children', styles.noteChildren)}>
          {note.children.map((child: Note, idx) => {
            return (
              <LayoutRenderFactory2
                mode="read"
                key={child.id}
                note={child}
                topNote={props.topNote}
                collapseNote={props.collapseNote}
                zoomNote={props.zoomNote}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SkipRenderer;
