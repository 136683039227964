import { ActionType } from "./actionType";
import { NotebookState } from "./state";
import { NotebookAction, EmptifyNotebookAction } from "./actions";


export function reducer(state = new NotebookState(), action: NotebookAction | EmptifyNotebookAction): NotebookState {

    switch (action.type) {

        case ActionType.UPDATE_NOTEBOOK:

            return {
                ...state,
                ...action,
            };
        case ActionType.EMPTY_STATE:

            return {
                ...state,
                notebookId: null,
                notebook: null,
            };
        case ActionType.COPY_NOTE:
            return {
                ...state,
                noteToCopy: (action as NotebookAction).noteToCopy
            };

        default:
            return state;
    }
}
