import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import shortid from 'shortid';
import _ from 'lodash';

import { theme } from '../shared/theme';
import ControlButton from './shared/controls/ControlButton';
import PlusIcon from './shared/icons/PlusIcon';
import Note from '../core/Note';
import Transformation, { TransformationState } from './Transformation';
import { Collapsible } from './Collapsible';
import Lookup from './Lookup';
import { Transformations } from '../transformations2/Transformations';
import NoteControls from './NoteControls';
import { Formatters } from '../formaters/FormatterFactory2';
import { Renderers } from '../layoutRenderers/LayoutRenderFactory2';
import { CommandActionType, CommandParams } from './NotebookView.onCommand';
import { connect } from 'react-redux';
import { addShareableLinkAction } from '../store/notebook/addShareableLinkAction';
import { removeShareableLinkAction } from '../store/notebook/removeShareableLinkAction';
import NoteSettingsActionButtons from './NoteSettingsActionButtons';
import { ShareableLink } from '../services/ShareableLink';
import ShareableLinkEditor from './NoteSettingsEditor.ShareableLinkEditor';
import { sendInvitationAction } from '../store/notebook/sendInvitationAction';
import { unshareAction } from '../store/notebook/unshareAction';
import { removeInvitationAction } from '../store/notebook/removeInvitationAction';
import { Invitation } from '../services/Invitation';
import { updateInvitationAction } from '../store/notebook/updateInvitationAction';

const useStyles = makeStyles({
  noteEditor: {
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative',
    paddingBottom: 10,
    marginBottom: 5,
    '&:after': {
      content: '""',
      borderBottom: `1px solid ${theme.colors.notePreviewDivider}`,
      right: -10,
      position: 'absolute',
      bottom: 0,
      width: 'calc(100% + 10px)',
    },
    '& .note-actions-spacer': {
      display: 'none',
    },
  },
  previewValue: {
    maxHeight: 200,
    maxWidth: `calc(100% - 20px)`,
    width: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    '& .value': {
      maxHeight: 100,
      display: 'block',
    },
  },
  notePreviewSelf: {
    backgroundColor: theme.colors.notePreviewBg,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 4,
  },
  settingsGroup: {},
  settingsGroupTitle: {
    ...theme.typography.app.interface,
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  settingsGroupTitleLabel: {
    flex: '1 1',
    paddingLeft: 5,
    color: theme.colors.lightBgTextSecondary,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  settingsGroupTitleName: {
    flex: '1 1',
    cursor: 'pointer',
  },
  settingsGroupTitleSummary: {
    paddingLeft: '0.5em',
    fontStyle: 'italic',
    color: theme.colors.lightBgText,
  },
  settingsGroupTitleActions: {
    flex: 0,
  },
  labelText: {
    paddingRight: 6,
  },
  controlInputText: {
    ...theme.controlInputText,
    width: '100%',
    boxSizing: 'border-box',
    'textarea&': {
      height: 100,
    },
  },
  controlInputWithError: {
    borderColor: theme.colors.inputBorderError,
  },
  controlInputSelect: {
    ...theme.controlInputSelect,
    width: '100%',
    boxSizing: 'border-box',
  },
  settingsGroupRow: {
    paddingLeft: 10,
    '& .checkbox': {
      paddingLeft: 0,
    },
  },
  settingsGroupRowDivided: {
    borderBottomWidth: 1,
    borderColor: theme.colors.noteSettingsDivider,
    borderStyle: 'solid',
    paddingBottom: 10,
    '& + &': {
      marginTop: 20,
    },
    '&:last-child': {
      paddingBottom: 0,
      border: 'none',
    },
  },
  settingsGroupRowTitle: {
    marginTop: 5,
    marginBottom: 5,
    display: 'flex',
    '& button': {
      ...theme.controlButtonIcon,
      flex: '0 0',
    },
    '& label': {
      flex: '1 1',
    },
  },
  settingsGroupRowProperty: {
    marginBottom: 10,
    // paddingLeft: 10,
    display: 'flex',
    flexFlow: 'column',
  },
  settingsGroupRowPropertyLine: {
    marginBottom: 10,
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'baseline',
    '&.spread': {
      justifyContent: 'space-between',
    },
  },
  settingsGroupRowPropertyLineValue: {
    flex: '1 1 auto',
  },
  settingsGroupDescription: {
    marginBottom: 5,
    ...theme.typography.app.interface,
    color: theme.colors.lightBgTextSecondary,
    opacity: 0.5,
  },
  settingsGroupPropertyName: {
    marginBottom: 5,
    ...theme.typography.app.interface,
    color: theme.colors.lightBgText,
  },
  spacer: {
    ...theme.spacer,
  },
  transformationsList: {
    width: '100%',
  },
  noteActions: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: 5,
    marginBRight: -5,
    '& button': {
      ...theme.controlButtonActions,
      marginRight: 5,
      marginBottom: 5,
    },
  },
  sharingLinkInput: {
    display: 'flex',
    flex: '1 1',
    marginRight: 5,
    position: 'relative',
    '& > input': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      border: `1px solid ${theme.colors.borderColor}`,
      padding: '4px 10px',
      flex: 1,
      borderRadius: 4,
      position: 'relative',
      zIndex: 2,
    },
    '&:before': {
      content: '"copy"',
      right: 10,
      position: 'absolute',
      top: 4,
      color: theme.colors.linkColor,
      cursor: 'pointer',
      zIndex: 1,
    },
  },
  sharingLink: {
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'row nowrap',
    '& > button': {
      flex: '0 0',
    },
  },
  sharingPermissions: {
    flex: '0 0 80px',
    'input + &': {
      marginLeft: 5,
    },
  },
});

interface NoteSettingsEditorProps {
  note: Note;
  updateNote: (note: Note) => void;
  className?: string;
  zoomNote: (note: Note) => void;
  layoutRenderSelected: ({ name, params }: { name?: string; params?: any }) => void;
  formatterSelected: ({ name, params }: { name?: string; params?: any }) => void;
  onCommand: (command: CommandParams) => void;
  addShareableLink: (note: Note) => void;
  sendInvitation: (note: Note, shareableLink: ShareableLink, invitation: Invitation) => void;
  removeInvitation: (note: Note, shareableLink: ShareableLink, invitation: Invitation) => void;
  updateInvitation: (note: Note, invitation: Invitation, permission: 'view' | 'edit') => void;
  unshare: (note: Note, shareableLink: ShareableLink) => void;
  removeShareableLink: (note: Note, linkId: string) => void;
}

interface paramsValidatorState {
  paramsString: string;
  isValid: boolean;
  userInput: string;
}

const NoteSettingsEditor = (props: NoteSettingsEditorProps) => {
  const { note, className, updateNote, zoomNote, layoutRenderSelected, formatterSelected, onCommand } = props;

  const [transformationOpen, setTransformationOpen] = useState(true);
  const [showNameLabel, setShowNameLabel] = useState(!_.isEmpty(note.name));
  const [collapseTransformations, setCollapseTransformations] = useState(false);
  const [addNewTransformation, setAddNewTransformation] = useState(false);
  const [formatterOpen, setFormatterOpen] = useState(
    note.formatter.name && note.formatter.name !== 'Default' && note.formatter.name !== ''
  );
  const [layoutOpen, setLayoutOpen] = useState(
    note.layoutRender.name && note.layoutRender.name !== 'Default' && note.layoutRender.name !== ''
  );
  const [sharingOpen, setSharingOpen] = useState(props.note.shareableLinks.length > 0);
  const [sharingVisible, setSharingVisible] = useState(props.note.shareableLinks.length > 0);

  const [noteName, setNoteName] = useState(props.note.name);

  const [formatterParams, setFormatterParams] = useState<paramsValidatorState>({
    paramsString: note.formatter.params ? JSON.stringify(note.formatter.params, null, 2) : '',
    isValid: true,
    userInput: note.formatter.params ? JSON.stringify(note.formatter.params, null, 2) : '',
  });

  const [layoutParams, setLayoutParams] = useState<paramsValidatorState>({
    paramsString: note.layoutRender.params ? JSON.stringify(note.layoutRender.params, null, 2) : '',
    isValid: true,
    userInput: note.layoutRender.params ? JSON.stringify(note.layoutRender.params, null, 2) : '',
  });

  const toggleTransformations = useCallback(() => {
    setTransformationOpen(!transformationOpen);
  }, [transformationOpen]);

  const [transformations, setTransformations] = useState<TransformationState[]>(
    _.map(note.transformations, (t) => ({
      id: shortid.generate(),
      name: t.name,
      params: t.params,
      recursive: t.recursive,
      disabled: t.disabled,
    }))
  );

  const TRANSFORMATIONS_LIST_LIMIT = 10;
  const transformationItems = Object.keys(Transformations).map((key) => ({ itemId: key, value: key })); //{

  const handleTransformationChange = useCallback(
    (trans) => {
      setTransformations(trans);
      note.transformations = trans;
      updateNote(note);
    },
    [note, updateNote]
  );

  const handleNewTransformationMode = useCallback(() => {
    if (transformations.length && transformations[transformations.length - 1].open) {
      delete transformations[transformations.length - 1].open;
      setTransformations(transformations);
    }

    setAddNewTransformation(true);
    setCollapseTransformations(true);
    setTransformationOpen(true);
  }, [transformations]);

  const handleTransformationAdd = useCallback(
    (name: string) => {
      const transformation = {
        open: true,
        id: shortid.generate(),
        name,
        recursive: false,
        disabled: false,
        params: { parameter1: '' },
      };
      const trans = [...transformations, transformation];
      setAddNewTransformation(false);
      handleTransformationChange(trans);
    },
    [handleTransformationChange, transformations]
  );

  const updateTransformations = useCallback(
    (i: number, prop: { [name: string]: any }) => {
      const trans = transformations.slice();
      _.extend(trans[i], prop);

      handleTransformationChange(trans);
    },
    [handleTransformationChange, transformations]
  );

  // const handleTransformationRecursiveChange = useCallback(
  //   (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //     updateTransformations(i, { recursive: event.target.checked });
  //   },
  //   [updateTransformations]
  // );

  const handleTransformationDisableChange = useCallback(
    (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      updateTransformations(i, { disabled: event.target.checked });
    },
    [updateTransformations]
  );

  const handleTransformationDelete = useCallback(
    (i: number) => () => {
      const trans = transformations.slice();
      trans.splice(i, 1);

      handleTransformationChange(trans);
    },
    [handleTransformationChange, transformations]
  );

  const handleTransformationParamsChange = useCallback(
    (i: number, params: string) => {
      const trans = transformations.slice();
      if (params.trim() === '') return false;
      const parsedParams: object | Error = _.attempt(JSON.parse.bind(null, params));
      if (!_.isError(parsedParams)) {
        trans[i].params = parsedParams;

        handleTransformationChange(trans);
      }
    },
    [handleTransformationChange, transformations]
  );

  const handleNoteNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onCommand({ note, action: CommandActionType.NAME, offset: null, specifics: { name: event.target.value } });
    },
    [note, onCommand]
  );

  const handleShareAdd = useCallback(() => {
    props.addShareableLink(props.note);
    setSharingOpen(true);
    setSharingVisible(true);
  }, [props]);

  const toggleFormatter = useCallback(() => {
    setFormatterOpen(!formatterOpen);
  }, [formatterOpen]);

  const toggleLayout = useCallback(() => {
    setLayoutOpen(!layoutOpen);
  }, [layoutOpen]);

  const toggleSharing = useCallback(() => {
    setSharingOpen(!sharingOpen);
  }, [sharingOpen]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      (event.target as HTMLInputElement).blur();
    }
  }, []);

  useEffect(() => {
    setTransformations(
      _.map(note.transformations, (t) => ({
        id: shortid.generate(),
        name: t.name,
        params: t.params,
        recursive: t.recursive,
        disabled: t.disabled,
      }))
    );
    setShowNameLabel(!_.isEmpty(note.name));

    setFormatterParams({
      paramsString: note.formatter.params ? JSON.stringify(note.formatter.params, null, 2) : '',
      isValid: true,
      userInput: note.formatter.params ? JSON.stringify(note.formatter.params, null, 2) : '',
    });

    setLayoutParams({
      paramsString: note.layoutRender.params ? JSON.stringify(note.layoutRender.params, null, 2) : '',
      isValid: true,
      userInput: note.layoutRender.params ? JSON.stringify(note.layoutRender.params, null, 2) : '',
    });

    setFormatterOpen(note.formatter.name && note.formatter.name !== 'Default' && note.formatter.name !== '');
    setLayoutOpen(note.layoutRender.name && note.layoutRender.name !== 'Default' && note.layoutRender.name !== '');
    setSharingVisible(note.shareableLinks.length > 0);
    setNoteName(note.name);
  }, [note]);

  const styles = useStyles();
  const disabled = !!note.virtualOf;

  const transformationsSummary = () => {
    return _.chain(transformations)
      .flatMap((t) => t.name)
      .join(', ')
      .truncate({ length: 30 })
      .value();
  };

  return (
    <div className={classNames(styles.noteEditor, className)}>
      <div className={classNames(styles.notePreviewSelf, 'note-self')}>
        <NoteControls collapsible={false} onBulletClick={zoomNote} />
        <div className={classNames(styles.previewValue, 'note-value')}>
          {note.name && <span className="name">{note.name}:</span>}
          <span className="value" dangerouslySetInnerHTML={{ __html: note.source.toString() }} />
        </div>
      </div>
      <div className={styles.noteActions}>
        <button onClick={handleShareAdd}>share</button>
        {!addNewTransformation && (
          <button onClick={() => !disabled && handleNewTransformationMode()} disabled={disabled}>
            transform
          </button>
        )}
        {!showNameLabel && <button onClick={() => setShowNameLabel(true)}>add name</button>}
        {!note.isRoot() && <NoteSettingsActionButtons note={note} onCommand={onCommand} />}
      </div>
      <div className={classNames(styles.settingsGroup, 'note-transformations')}>
        <div className={classNames(styles.settingsGroupTitle, showNameLabel ? 'some-class-for-SK' : '')}>
          <div className={styles.settingsGroupTitleLabel}>
            <span className={styles.spacer} />
            {showNameLabel && (
              <>
                <span className={styles.labelText}>Name</span>
                <input
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) => {
                    handleNoteNameChange(e);
                  }}
                  //onFocus={() => setShowNameLabel(true)}
                  //onBlur={() => setShowNameLabel(!_.isEmpty(note.name))}
                  type="text"
                  className={styles.controlInputText}
                  placeholder="add name..."
                  value={noteName}
                  disabled={disabled}
                />
              </>
            )}
          </div>
        </div>
        {((transformations && transformations.length) || addNewTransformation) && (
          <div className={styles.settingsGroupTitle}>
            <Collapsible
              className={styles.transformationsList}
              onExpandCollapse={toggleTransformations}
              isDisabled={!(transformations && transformations.length) && !addNewTransformation}
              collapsed={!transformationOpen}
              title={
                <div className={styles.settingsGroupTitleLabel}>
                  <span onClick={toggleTransformations} className={styles.settingsGroupTitleName}>
                    Transformations
                    {!transformationOpen && (
                      <span className={styles.settingsGroupTitleSummary}>
                        {transformations && transformations.length && transformationsSummary()}
                      </span>
                    )}
                  </span>
                  <ControlButton icon={<PlusIcon />} onClick={handleNewTransformationMode} />
                </div>
              }
            >
              {transformations.map((transformation, i) => (
                <Transformation
                  key={transformation.id}
                  transformation={transformation}
                  collapseAll={collapseTransformations}
                  resetCollapseAll={() => setCollapseTransformations(false)}
                  sharedStyles={styles}
                  handleDelete={handleTransformationDelete(i)}
                  // handleRecursiveChange={handleTransformationRecursiveChange(i)}
                  handleDisabling={handleTransformationDisableChange(i)}
                  handleParamsChange={(params) => handleTransformationParamsChange(i, params)}
                />
              ))}
              {addNewTransformation && (
                <Lookup
                  isOpen={true}
                  items={transformationItems}
                  limit={TRANSFORMATIONS_LIST_LIMIT}
                  onItemSelected={(item) => handleTransformationAdd(item && item.value)}
                  cssClasses={[styles.controlInputSelect]}
                />
              )}
            </Collapsible>
          </div>
        )}
      </div>
      <div className={classNames(styles.settingsGroup)}>
        <div className={styles.settingsGroupTitle}>
          <Collapsible
            className={styles.transformationsList}
            onExpandCollapse={toggleFormatter}
            isDisabled={false}
            collapsed={!formatterOpen}
            title={
              <div className={styles.settingsGroupTitleLabel}>
                <span onClick={toggleFormatter} className={styles.settingsGroupTitleName}>
                  Format
                  {!formatterOpen && (
                    <span className={styles.settingsGroupTitleSummary}>{note.formatter?.name || 'Default'}</span>
                  )}
                </span>
              </div>
            }
          >
            <div className={styles.settingsGroupRow}>
              <div className={styles.settingsGroupRowProperty}>
                {/* ToFix: expand <Lookup> functionality to make browsing eaiser */}
                {/* <Lookup
                  isOpen={false}
                  * ToAdd: selectedItem: Item *
                  items={Formatters.map((val, index) => ({ itemId: index, value: val }))}
                  onItemSelected={(item) => formatterSelected({ name: item && item.value })}
                /> */}
                <select
                  value={note.formatter.name || ''}
                  onChange={(event) => formatterSelected({ name: event.target.value })}
                  className={styles.controlInputSelect}
                >
                  {Formatters.map((i, index) => (
                    <option value={i} key={index}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.settingsGroupRowProperty}>
                <label>Parameters</label>
                <textarea
                  rows={8}
                  value={formatterParams.userInput}
                  className={classNames(
                    styles.controlInputText,
                    !formatterParams.isValid && styles.controlInputWithError
                  )}
                  onChange={(event) => {
                    const jsonString = event.target.value;
                    let jsonObj;
                    try {
                      jsonObj = JSON.parse(jsonString);
                      setFormatterParams({
                        ...formatterParams,
                        isValid: true,
                        userInput: jsonString,
                      });
                    } catch (e) {
                      setFormatterParams({ ...formatterParams, isValid: false, userInput: jsonString });
                    }
                    return jsonObj ? formatterSelected({ params: jsonObj }) : false;
                  }}
                  onBlur={(event) => {
                    if (formatterParams.isValid) {
                      if (formatterParams.userInput.trim() === '') {
                        // if empty string
                        setFormatterParams({
                          paramsString: '{}',
                          isValid: true,
                          userInput: '{}',
                        });
                      } else {
                        // is input parameters are valid, setting them to a pretty string on field blur
                        const prettifiedString: string = JSON.stringify(JSON.parse(formatterParams.userInput), null, 2);
                        setFormatterParams({
                          paramsString: prettifiedString,
                          isValid: true,
                          userInput: prettifiedString,
                        });
                      }
                    } else {
                      // otherwise, if params are invalid after user input, resetting to the original valid params
                      setFormatterParams({
                        ...formatterParams,
                        isValid: true,
                        userInput: formatterParams.paramsString,
                      });
                    }
                  }}
                />
              </div>
              <div className={styles.settingsGroupDescription}>Format only affects a note itself</div>
            </div>
          </Collapsible>
        </div>
      </div>
      <div className={classNames(styles.settingsGroup)}>
        <div className={styles.settingsGroupTitle}>
          <Collapsible
            className={styles.transformationsList}
            onExpandCollapse={toggleLayout}
            isDisabled={false}
            collapsed={!layoutOpen}
            title={
              <div className={styles.settingsGroupTitleLabel}>
                <span onClick={toggleLayout} className={styles.settingsGroupTitleName}>
                  Layout
                  {!layoutOpen && (
                    <span className={styles.settingsGroupTitleSummary}>{note.layoutRender.name || 'Default'}</span>
                  )}
                </span>
              </div>
            }
          >
            <div className={styles.settingsGroupRow}>
              <div className={styles.settingsGroupRowProperty}>
                {/* ToFix: expand <Lookup> functionality to make browsing eaiser */}
                {/* <Lookup
                  isOpen={false}
                  * ToAdd: selectedItem: Item *
                  items={Formatters.map((val, index) => ({ itemId: index, value: val }))}
                  onItemSelected={(item) => formatterSelected({ name: item && item.value })}
                /> */}
                <select
                  value={note.layoutRender.name || ''}
                  onChange={(event) => layoutRenderSelected({ name: event.target.value })}
                  className={styles.controlInputSelect}
                >
                  {Renderers.map((i, index) => (
                    <option value={i} key={index}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.settingsGroupRowProperty}>
                <label>Parameters</label>
                <textarea
                  rows={8}
                  value={layoutParams.userInput}
                  className={classNames(styles.controlInputText, !layoutParams.isValid && styles.controlInputWithError)}
                  onChange={(event) => {
                    const jsonString = event.target.value;
                    let jsonObj;
                    try {
                      jsonObj = JSON.parse(jsonString);
                      setLayoutParams({
                        ...layoutParams,
                        isValid: true,
                        userInput: jsonString,
                      });
                    } catch (e) {
                      setLayoutParams({
                        ...layoutParams,
                        isValid: false,
                        userInput: jsonString,
                      });
                    }
                    return jsonObj ? layoutRenderSelected({ params: jsonObj }) : false;
                  }}
                  onBlur={(event) => {
                    if (layoutParams.isValid) {
                      // is input parameters are valid, setting them to a pretty string on field blur
                      const prettifiedString: string = JSON.stringify(JSON.parse(layoutParams.userInput), null, 2);
                      setLayoutParams({
                        paramsString: prettifiedString,
                        isValid: true,
                        userInput: prettifiedString,
                      });
                    } else {
                      // otherwise, if params are invalid after user input, resetting to the original valid params
                      setLayoutParams({
                        ...layoutParams,
                        isValid: true,
                        userInput: layoutParams.paramsString,
                      });
                    }
                  }}
                />
              </div>
              <div className={styles.settingsGroupDescription}>Layout affects the note and its descendants</div>
            </div>
          </Collapsible>
        </div>
      </div>
      {sharingVisible && (
        <div className={classNames(styles.settingsGroup)}>
          <div className={styles.settingsGroupTitle}>
            <Collapsible
              className={styles.transformationsList}
              onExpandCollapse={toggleSharing}
              isDisabled={false}
              collapsed={!sharingOpen}
              title={
                <div className={styles.settingsGroupTitleLabel}>
                  <span onClick={toggleSharing} className={styles.settingsGroupTitleName}>
                    Sharing {sharingVisible}
                    {props.note.shareableLinks.length > 0 && (
                      <span className={styles.settingsGroupTitleSummary}>
                        {props.note.shareableLinks.length} link{props.note.shareableLinks.length > 1 && 's'}
                      </span>
                    )}
                  </span>
                </div>
              }
            >
              {props.note.shareableLinks.map(
                (shareableLink) =>
                  shareableLink.id && (
                    <ShareableLinkEditor
                      sendInvitation={(invitation) => props.sendInvitation(props.note, shareableLink, invitation)}
                      togglePrivate={(_private) => (shareableLink.private = _private)}
                      unshare={() => props.unshare(props.note, shareableLink)}
                      updateInvitation={(invitation, permission) =>
                        props.updateInvitation(props.note, invitation, permission)
                      }
                      removeInvitation={(invitation) => props.removeInvitation(props.note, shareableLink, invitation)}
                      key={shareableLink.id}
                      styles={styles}
                      shareableLink={shareableLink}
                      className={styles.settingsGroupRowDivided}
                    />
                  )
              )}
            </Collapsible>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addShareableLink: (note: Note) => dispatch(addShareableLinkAction(note)),
    removeShareableLink: (note: Note, linkId: string) => dispatch(removeShareableLinkAction(note, linkId)),
    sendInvitation: (note: Note, shareableLink: ShareableLink, invitation: Invitation) =>
      dispatch(sendInvitationAction(note, shareableLink, invitation)),
    removeInvitation: (note: Note, shareableLink: ShareableLink, invitation: Invitation) =>
      dispatch(removeInvitationAction(note, shareableLink, invitation)),
    updateInvitation: (note: Note, invitation: Invitation, permission: 'view' | 'edit') =>
      dispatch(updateInvitationAction(note, invitation, permission)),
    unshare: (note: Note, shareableLink: ShareableLink) => dispatch(unshareAction(note, shareableLink)),
  };
};

export default connect(null, mapDispatchToProps)(NoteSettingsEditor);
