import Note from '../core/Note';
import React from 'react';
import NoteControls from '../components/NoteControls';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { theme } from '../shared/theme';
import _ from 'lodash';
import { If } from 'react-if';
import { FormatterFactory2 } from '../formaters/FormatterFactory2';
import { LayoutRenderFactory2 } from './LayoutRenderFactory2';

const useStyles = makeStyles({
  note: {
    display: 'flex',
    flexFlow: 'column',
    position: 'relative',
    // color: '#4449a7',
    // color: theme.colors.lightBgText,
    '&.top-note.note': {
      padding: 10,
      paddingLeft: 60,
      paddingTop: 40,
      paddingBottom: 120,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      '@media (max-width: 600px)': {
        padding: '40px 10px 80px 40px',
      },

      '.full-screen > .notebook-container &': {
        paddingTop: 80,
        maxHeight: 'none',
        width: '100%',
        boxSizing: 'border-box',
      },
      '& > .note-self': {
        position: 'relative',
        maxWidth: 800,
        width: '100%',
      },
      '& > .note-children': {
        position: 'relative',
        maxWidth: 800,
        width: '100%',
      },
      '&:not(.leaf) > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h1,
          marginTop: 0,
          paddingLeft: 0,
        },
        '& > .note-actions': {
          top: 7,
        },
      },
    },
    '& > .note-self': {
      '&:hover > .note-actions': {
        visibility: 'visible',
      },
    },
    '&.level-1:not(.leaf)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h2,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 5,
        },
      },
    },
    '&.level-2:not(.leaf)': {
      marginTop: 20,
      '& > .note-self': {
        position: 'relative',
        '& > .note-value': {
          ...theme.typography.content.h3,
          marginTop: 0,
        },
        '& > .note-actions': {
          top: 3,
        },
      },
    },
  },
  noteSelf: {
    display: 'flex',
    position: 'relative',
  },
  noteValue: {
    padding: '5px 0',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    lineHeight: '140%',
  },
  noteControls: {
    position: 'absolute',
    left: -40,
    visibility: 'hidden',
  },
  noteChildren: {
    paddingLeft: 0,
  },
});

const DefaultReadRenderer = (props: LayoutRenderProps) => {
  const { note, collapseNote, className } = props;

  const index = note.parent ? _.findIndex(note.parent.children, { id: note.id }) : 'top';
  const absoluteLevel = note.absoluteLevel();
  const displayLevel = note.displayLevelFrom(props.topNote);
  const displayLevelClassName = _.isNull(displayLevel) ? '' : 'level-' + displayLevel;

  const styles = useStyles();

  return (
    <div
      className={classNames(
        'note',
        'default-renderer',
        `index-${index}`,
        `absLevel-${absoluteLevel}`,
        note.isLeaf() && 'leaf',
        displayLevelClassName,
        note === props.topNote && 'top-note',
        styles.note,
        className
      )}
      key={note.id}
    >
      <div className={classNames('note-self', styles.noteSelf)}>
        <NoteControls
          onCollapseClick={(event) => collapseNote(note)}
          collapsible={!note.isLeaf()}
          onBulletClick={(event) => props.zoomNote(note)}
          closed={note.isChildrenCollapsed}
          className={classNames('note-actions', styles.noteControls)}
        />
        <div className={classNames('note-value', styles.noteValue)}>
          <FormatterFactory2 note={props.note} />
        </div>
      </div>

      <If condition={!note.isChildrenCollapsed && !note.isLeaf()}>
        <div className={classNames('note-children', styles.noteChildren)}>
          {note.children?.map((child: Note, idx) => {
            return (
              <LayoutRenderFactory2
                mode="read"
                key={child.id}
                note={child}
                topNote={props.topNote}
                collapseNote={props.collapseNote}
                zoomNote={props.zoomNote}
              />
            );
          })}
        </div>
      </If>
    </div>
  );
};

export default DefaultReadRenderer;
