import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import useDocumentScrollThrottled from '../utils/useDocumentScrollThrottled';

const useStyles = makeStyles({
  header: {
    width: '100%',
    '&.fixed': {
      transform: 'translateY(0)',
      transition: 'transform 0.3s ease',
      zIndex: 1,
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '&.hidden': {
      transform: 'translateY(-110%)',
    },
    '&.shadow': {
      boxShadow: '0 9px 9px -9px rgba(0, 0, 0, 0.13)',
    }
  },
});

const MINIMUM_SCROLL = 80;
const TIMEOUT_DELAY = 400;

export interface HidingPageHeaderProps {
  children: React.ReactNode;
  disabled?: boolean;
  hideScrollPoint?: number;
  className?: string,
}

const HidingPageHeader = (props: HidingPageHeaderProps) => {
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);

  const hideScrollPoint = props.hideScrollPoint || MINIMUM_SCROLL;
  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > hideScrollPoint;

    // console.log('HidingPageHeader', previousScrollTop, currentScrollTop);

    if (!props.disabled) {
      setShouldShowShadow(currentScrollTop > 2);

      setTimeout(() => {
        setShouldHideHeader(isScrolledDown && isMinimumScrolled);
      }, TIMEOUT_DELAY);
    }
  });

  const styles = useStyles();
  return (
    <header
      className={classNames(
        styles.header,
        !props.disabled && 'fixed',
        shouldShowShadow && 'shadow',
        shouldHideHeader && 'hidden',
        props.className,
      )}
    >
      {props.children}
    </header>
  );
};

export default HidingPageHeader;
