// import _ from 'lodash';
import interpolate from '../../utils/interpolate';
import helpers from './helpers';

export const isParams = (params) => {
  const series = params.series;
  const series0 = Array.isArray(series) && series.length > 0 ? series[0] : {};

  if (series0 === {} || !series0.dataFields || !series0.dataFields.value || !series0.dataFields.category) {
    return { valid: false, message: 'Spec is incomplete' };
  } else return { valid: true };
};

export const isDataValid = (data) => {
  if (!Array.isArray(data)) {
    console.log('data is not an array', typeof data, data);
    return { valid: false, message: 'data is not an array' };
  } else return { valid: true };
};

export const getRows = (data, category, value) => {
  return data.reduce((accumulator, currentValue) => {
    let newValue = {};
    newValue[category] =
      category === 'date'
        ? helpers.isDate(currentValue[category])
          ? helpers.formatMoment(currentValue[category], 'MMM DD, YYYY')
          : helpers.formatMoment(helpers.parseDate(currentValue[category]), 'MMM DD, YYYY')
        : currentValue[category];
    newValue[value] = currentValue[value];
    return [...accumulator, ...[newValue]];
  }, []);
};

export const doesntExistOrNotEqual = (v, value) => !v || (!!v && v !== value);
export const existAndEqual = (v, value) => !!v && v === value;

/**
 * helper function to get the data
 * function pameters:
 * params: transformation parameters object
 * selfNoteValue: note's computed value
 * resolvedRefs: resolved references of the note
 * dataProp - name of the data property
 */
export const getDataFromParams = (
  params: any,
  resolvedRefs: { [name: string]: ComputedType },
  defaultValue: any = undefined,
  dataProp: string = 'data'
) => {
  return params && params[dataProp] ? interpolate(params && params[dataProp], resolvedRefs) : defaultValue;
};
