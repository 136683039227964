// import React from 'react';
// import _ from 'lodash';
import { FormaterProps } from './FormatterFactory2';
import numeral from 'numeral';

const FormatNumber = (props: FormaterProps) => {

  const data = props.note.formatter.params?.data || props.unit.data;
  const format = props.note.formatter.params?.format || '0,0';

  let error: string;
  let result;

  try{
    result = Array.isArray(data) ? data.forEach(xx => numeral(xx).format(format)) : numeral(data).format(format);
  } catch (e) {
    error = 'Unable to format a number: ' + e;
    console.error(error);
  }
  
  return error ? error  : result ;

};

FormatNumber.description = () => 'Format a number';

export default FormatNumber;
